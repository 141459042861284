<template>
  <CompLightTable
    ref="TableJettyPosition"
    ref-specify="TableJettyPosition"
    :api-endpoint="apiEndpoint"
    sub-title-tbl="KEGIATAN JALUR SUNGAI HULU MIGAS"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
    "
    :force-disable-crud="permissionCrud"
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage ( All & SAL ) only"
    placeholder-search="Kegiatan Name"
    @render-component="renderComponent"
  />
</template>

<script>
import moment from 'moment'
import CompLightTable from '@/views/pages/components/CompLightTable.vue'

export default {
  components: {
    CompLightTable
  },
  props: {
    fhmID: {
      type: Number,
      required: false,
      default: null
    },
    permissionCrud: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  data() {
    return {
      apiEndpoint: `fhm_position/${this.fhmID}`,
      fields: []
    }
  },
  computed: {
    newDataTemplate() {
      return {
        jetty_position_id: null,
        jetty_position: {
          position: null
        },
        waktu_pindah_start: null,
        waktu_pindah_end: null
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    moment,
    init() {
      console.log('init')
      this.initFields()
    },
    renderComponent() {
      console.log('re render')
      this.$emit('render-component', 'TableJettyPosition')
    },
    initFields(dataku = null) {
      this.fields = [
        {
          key: 'posisi_awal',
          label: 'Posisi Awal (*)',
          sortable: true,
          placeholder: 'Pilih Posisi Awal',
          input: {
            type: 'select',
            requestAPI: 'allow',
            optionsApiEndpoint: 'jetty_position',
            autoLoad: true,
            resolveOptionsResponse(response) {
              return response.data.jetty_positions.map((value) => ({
                label: `${value.position}`,
                value: value.position
              }))
            },
            reduce({ value }) {
              return value // as ID DATA
            }
          },
          searchModel: 'posisi_awal',
          search: {
            type: 'text'
          },
          width: '12em',
          minWidth: '12em'
        },
        {
          key: 'posisi_sekarang',
          label: 'Posisi Sekarang (*)',
          sortable: true,
          placeholder: 'Pilih Posisi Sekarang',
          input: {
            type: 'select',
            requestAPI: 'allow',
            optionsApiEndpoint: 'jetty_position',
            autoLoad: true,
            resolveOptionsResponse(response) {
              return response.data.jetty_positions.map((value) => ({
                label: `${value.position}`,
                value: value.position
              }))
            },
            reduce({ value }) {
              return value // as ID DATA
            }
          },
          searchModel: 'posisi_sekarang',
          search: {
            type: 'text'
          },
          width: '12em',
          minWidth: '12em'
        },
        {
          key: 'waktu_pindah',
          label: 'Waktu Pindah',
          placeholder: 'Waktu Pindah',
          sortable: true,
          input: {
            type: 'time',
            format: 'D MMMM - hh:mm A',
            disabled: false
          },
          searchModel: 'waktu_pindah',
          search: {
            type: 'date'
          },
          width: '16em',
          minWidth: '16em'
        }
        // {
        //   key: 'created_at',
        //   label: 'Created AT',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: {
        //     type: 'time',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        //   searchModel: 'created_at',
        //   search: {
        //     type: 'time',
        //   },
        //   width: '7em',
        //   minWidth: '7em',
        // },
        // {
        //   key: 'updated_at',
        //   label: 'Updated AT',
        //   sortable: true,
        //   placeholder: 'Otomatis terisi',
        //   input: {
        //     type: 'time',
        //     format: 'D MMMM YYYY - hh:mm A',
        //     disabled: true,
        //   },
        //   searchModel: 'updated_at',
        //   search: {
        //     type: 'time',
        //   },
        //   width: '7em',
        //   minWidth: '7em',
        // },
      ]
    },
    resolveFetchResponse(response) {
      return response.data.fhm_positions
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
