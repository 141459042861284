<template>
  <div id="form_masuk" ref="form_masuk">
    <b-progress
      v-if="fetchLoading"
      key="info"
      animated
      value="100"
      variant="info"
      :class="'progress-bar-info'"
    />
    <!-- HEADER | TITLE | DROPDOWN | BACK -->
    <b-row :key="formRender" align-h="between" align-v="center" class="mb-1">
      <b-col cols="4">
        <strong>Ubah Status </strong>
        <b-dropdown
          v-if="$router.currentRoute.params.id"
          id="status"
          :variant="formStatusVariant(form.schedule.form_status)"
        >
          <!-- :variant="statusVariant(status)" -->
          <template #button-content>
            <span v-text="form.schedule.form_status || 'Tidak ada status'" />
          </template>
          <b-dropdown-header
            v-if="form.schedule.form_status !== 'KEGIATAN DIBATALKAN'"
            id="dropdown-header-label"
          >
            <small class="text-secondary">{{
              $can('SAL', '') ? 'Ubah status ke' : 'Hanya Admin SAL'
            }}</small>
          </b-dropdown-header>
          <b-dropdown-item
            v-if="
              $can('SAL', '') &&
              form.schedule.form_status !== 'KEGIATAN DIBATALKAN'
            "
            variant="danger"
            @click="onUpdateStatus(true, form.schedule.form_status)"
          >
            <b-badge :variant="'danger'"> BATALKAN KEGIATAN </b-badge>
          </b-dropdown-item>
          <b-dropdown-item v-else-if="$can('SAL', '')" variant="info">
            <b-badge :variant="'info'">
              Tidak Dapat Mengubah Status yang Telah Dibatalkan.
            </b-badge>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col
        v-if="$router.currentRoute.params.id !== undefined"
        class="text-center d-none d-sm-block"
        cols="4"
      >
        <h1>HULU MIGAS</h1>
      </b-col>
      <b-col class="text-right" cols="4">
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-secondary"
            :style="{ cursor: 'pointer' }"
            :to="$router.currentRoute.params.previous_link"
          >
            <feather-icon icon="ArrowLeftIcon" :style="{ cursor: 'pointer' }" />
            <span>Kembali</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- FORM PENGISIAN -->
    <validation-observer ref="createHuluMigasRules" tag="form">
      <!-- :hide-buttons="true" -->
      <div class="mt-1">
        <form-wizard
          key="wizardKey"
          ref="wizardFirst"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          :lazy="true"
          layout="vertical"
          :before-change="() => true"
          finish-button-text="Terbitkan Form Perencanaan Kedaatangan Kapal"
          back-button-text="Previous"
          :class="
            isLastStep()
              ? dataID
                ? 'vertical-steps steps-transparent mb-3 sal-hide'
                : 'vertical-steps steps-transparent mb-3 sal'
              : 'vertical-steps steps-transparent mb-3 sal'
          "
          @on-complete="formSubmitted"
          @on-change="onTabChange"
        >
          <tab-content
            :title="wizard.first.step1.title"
            :icon="wizard.first.step1.icon"
          >
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ wizard.first.step1.content.title }}</h5>
                <small class="text-muted">
                  <strong>{{ wizard.first.step1.content.subtitle }}.</strong>
                </small>
              </b-col>
            </b-row>

            <b-form-group
              label="Jenis Kapal *"
              label-for="vi-schedule-jenis_kapal"
            >
              <!-- regex: /^[0-9]+$/ -->
              <validation-provider
                #default="{ errors }"
                name="Jenis Kapal"
                vid="Jenis Kapal"
                rules="required"
              >
                <v-select
                  id="vi-schedule-jenis_kapal"
                  v-model="form.schedule.jenis_kapal"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="jenis_kapals"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  :disabled="isExistDetailID ? true : null"
                  label="text"
                  placeholder="Pilih Jenis Kapal"
                  item-value="value"
                  item-text="text"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row
              v-if="
                form.schedule.jenis_kapal !== 'Tug Boat' &&
                !!form.schedule.jenis_kapal
              "
            >
              <b-col md="12" cols="12">
                <b-row>
                  <b-col md="12" cols="12">
                    <b-form-group label="Nama Kapal*" label-for="vi-agen-name">
                      <div class="suggest-custom">
                        <validation-provider
                          #default="{ errors }"
                          name="Nama Kapal*"
                          rules="required"
                        >
                          <vue-autosuggest
                            id="vi-boat-name"
                            v-model="form.dm_river_boat_hm.boat_name"
                            v-uppercase
                            :suggestions="[
                              { data: form.dm_river_boat_hm.dm_river_boat_hms }
                            ]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Boat Name',
                              disabled: permissionSuggestionBoatMain()
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'dm_river_boat_hm',
                                'boat_name'
                              )
                            "
                            @input="
                              searchForm({
                                keyword: $event,
                                uri: 'dm_river_boat_hm/',
                                model: 'dm_river_boat_hm',
                                currentSuggest: 'boat_name'
                              })
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :text="
                                    Array.from(suggestion.item.boat_name)[0] +
                                    Array.from(suggestion.item.boat_name)[1]
                                  "
                                  variant="primary"
                                />
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong
                                      ><span
                                        v-text="
                                          `${suggestion.item.boat_name} , ${suggestion.item.no_pkk}`
                                        "
                                    /></strong>
                                    <br />
                                    <small class="text-muted">
                                      <strong
                                        ><span
                                          v-text="
                                            `GT: ${
                                              suggestion.item.gt || ''
                                            }, LOA: ${
                                              suggestion.item.loa || ''
                                            } - ${
                                              suggestion.item.dm_shipping_agent
                                                .shipping_name || ''
                                            }`
                                          "
                                      /></strong>
                                    </small>
                                    <br />
                                    <small class="text-muted">
                                      <strong
                                        ><span
                                          v-text="
                                            `Capt. ${suggestion.item.captain_name}, Telp: ${suggestion.item.captain_telp}`
                                          "
                                      /></strong>
                                    </small>
                                    <br />
                                    <small class="text-muted">
                                      <strong
                                        ><span
                                          v-text="
                                            `Created AT ${formatDateTime(
                                              suggestion.item.created_at
                                            )}`
                                          "
                                      /></strong>
                                    </small>
                                    <br />
                                  </b-card-text>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1 mb-1 mr-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[dm_river_boat_hmState]
                                  "
                                  class="mr-1"
                                >
                                  <span>
                                    {{
                                      statusSuggestionChoose[
                                        dm_river_boat_hmState
                                      ]
                                    }}</span
                                  >
                                </b-badge>
                                <b-badge variant="info">
                                  Total:
                                  {{
                                    form.dm_river_boat_hm.dm_river_boat_hms
                                      .length
                                  }}
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- <b-input-group-append is-text> -->
                        <b-spinner
                          v-if="dm_river_boat_hmStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col
                  v-if="
                    isExistDetailID &&
                    !statePayloadDMK &&
                    !statePayloadDMKMaster
                  "
                  md="4"
                  cols="12"
                  class="mb-2"
                >
                  <b-button
                    block
                    class="mt-2"
                    variant="primary"
                    @click="
                      updateDMKMaster({
                        isNotEdit: false,
                        payloadDMK: mapStatePayloadDMK.CHANGE_ON_EXIST_DATA
                      })
                    "
                  >
                    <feather-icon
                      icon="DatabaseIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <span>Ganti Kapal</span>
                  </b-button>
                </b-col> -->
                  <b-col md="2" cols="12" v-if="statePayloadDMKMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="danger"
                      @click="
                        updateDMKMaster({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span></span>
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="success"
                      @click="jwtGantiKapal()"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span>Simpan</span>
                      <!-- Main Boat | Ganti Kapal -->
                    </b-button>
                  </b-col>
                  <b-col md="6" cols="6">
                    <b-form-group
                      label="GT ( Kapal ) *"
                      label-for="vi-dm_river_boat_hm-gt"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="GT ( Kapal ) *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group append="">
                          <cleave
                            id="vi-dm_river_boat_hm-gt"
                            v-model="form.dm_river_boat_hm.gt"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionSuggestionBoat()"
                            placeholder="1.000 (dot automaticaly)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="6">
                    <b-form-group
                      label="LOA ( Kapal ) *"
                      label-for="vi-dm_river_boat_hm-loa"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="LOA ( Kapal ) *"
                        rules="required"
                      >
                        <b-input-group append="M">
                          <!-- <cleave
                          id="vi-dm_river_boat_hm-loa"
                          v-model="form.dm_river_boat_hm.loa"
                          class="form-control"
                          :raw="true"
                          :options="options.number"
                          :readonly="permissionSuggestionBoat()"
                          placeholder="78,12 (comma manual)"
                          /> -->
                          <b-form-input
                            id="vi-dm_river_boat_hm-loa"
                            v-model="form.dm_river_boat_hm.loa"
                            class="form-control"
                            type="number"
                            placeholder="78,12 (comma manual)"
                            :readonly="permissionSuggestionBoat()"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Name *"
                      label-for="vi-dm_river_boat_hm-captain_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Capt. Name *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-dm_river_boat_hm-captain_name"
                          v-model="form.dm_river_boat_hm.captain_name"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="Full Capt. Name"
                          :readonly="permissionSuggestionBoat()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Telp *"
                      label-for="vi-dm_river_boat_hm-captain_telp"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Capt. Telp *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-dm_river_boat_hm-captain_telp"
                          v-model="form.dm_river_boat_hm.captain_telp"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="081145778613"
                          :readonly="permissionSuggestionBoat()"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="12">
                    <b-form-group label="Nomor PKK *" label-for="vi-no_pkk">
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor PKK *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-no_pkk"
                          v-model="form.schedule.no_pkk"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionTenantAndSAL()"
                          placeholder="Cth: PKK.DN.IDSRI.2208.000123"
                        />
                        <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue === form.schedule.no_pkk
                          "
                          class="text-danger"
                          >{{ errorPath === 'no_pkk' ? errorValue : '' }}</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Perusahaan Shipping Agent *"
                      label-for="vi-dm_shipping_agent-shipping_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Perusahaan Shipping Agent *"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-dm_shipping_agent-shipping_name"
                          v-model="
                            form.dm_river_boat_hm.dm_shipping_agent
                              .shipping_name
                          "
                          v-uppercase
                          :get-suggestion-value="getSuggestionValue"
                          :suggestions="[
                            {
                              data:
                                form.dm_river_boat_hm.dm_shipping_agent
                                  .dm_shipping_agents || []
                            }
                          ]"
                          :state="errors.length > 0 ? false : null"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control form-input',
                            placeholder: 'Cth: PT LINTAS SERAM M',
                            disabled: permissionSuggestionBoat()
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'dm_river_boat_hm',
                              'dm_shipping_agent',
                              'dm_shipping_agent.shipping_name'
                            )
                          "
                          @input="
                            searchForm({
                              keyword: $event,
                              uri: 'dm_shipping_agent/',
                              model: 'dm_river_boat_hm',
                              currentSuggest: 'dm_river_boat_hm',
                              childSuggesst: 'dm_shipping_agent.shipping_name'
                            })
                          "
                        >
                          <!-- !form.dm_river_boat_hm.dm_shipping_agent.isNew -->
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong>
                                    <span class="my-suggestion-item">{{
                                      suggestion.item.shipping_name
                                    }}</span>
                                  </strong>
                                </b-card-text>
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `${dateFormat(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1">
                              <b-badge
                                href="#"
                                pill
                                class="mr-1"
                                :variant="
                                  statusSuggestionColor[dm_shipping_agentState]
                                "
                              >
                                <span>
                                  {{
                                    statusSuggestionChoose[
                                      dm_shipping_agentState
                                    ]
                                  }}</span
                                >
                              </b-badge>
                              <b-badge variant="info">
                                Total:
                                {{
                                  form.dm_river_boat_hm.dm_shipping_agent
                                    .dm_shipping_agents.length
                                }}
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue ===
                              form.dm_river_boat_hm.dm_shipping_agent
                                .shipping_name
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'shipping_name' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                      <b-spinner
                        v-if="dm_shipping_agentStateLoading"
                        class="suggest-loading"
                        small
                        variant="primary"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- show only on create data | action for suggestion value -->
                  <b-col
                    cols="12"
                    v-if="!isExistDetailID && !form.dm_river_boat_hm.isNew"
                    class="text-left"
                  >
                    <div class="float-left">
                      <b-button
                        class="mr-1"
                        variant="outline-danger"
                        @click="breakSuggestionValue()"
                      >
                        <feather-icon
                          icon="PenToolIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <span
                          class="align-middle"
                          v-text="
                            'Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )'
                          "
                        />
                      </b-button>
                      <span
                        ><small
                          ><strong
                            >Abaikan jika data master kapal yang otomatis
                            teriisi sudah benar</strong
                          ></small
                        ></span
                      >
                    </div>
                  </b-col>

                  <!-- show only on details data, its about to revise data or change data -->
                  <!-- duplicate data -->
                  <b-col
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        statePayloadDMK === null &&
                        !statePayloadDMKMaster) ||
                      statePayloadDMK ===
                        mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                    "
                    ><!--
                  1. default enable ( statePayloadDMK === null )
                  2. when clicked enable ( statePayloadDMK is exist )
                  3. when other button clicked disable/gone ( statePayloadDMKMaster )
                 -->
                    <b-button
                      class="d-block"
                      variant="outline-danger"
                      block
                      :disabled="!editAble(user_id)"
                      @click="
                        updateDMK({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle" v-text="'Buat data baru'" />
                    </b-button>
                    <span
                      >Contoh kasus: data kapal belum pernah dilakukan
                      penginputan.</span
                    >
                  </b-col>
                  <!-- revisi data -->
                  <b-col
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        statePayloadDMK === null &&
                        !statePayloadDMKMaster) ||
                      statePayloadDMK ===
                        mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                    "
                  >
                    <b-button
                      class="d-block"
                      variant="outline-warning"
                      block
                      :disabled="!editAble(user_id)"
                      @click="
                        updateDMK({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span
                        class="align-middle"
                        v-text="
                          'Revisi data master kapal, Jika ada data yang tidak sesuai.'
                        "
                      />
                    </b-button>
                    <span>Contoh kasus: kesalahan penulisan</span>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMK">
                    <b-button
                      class="d-block mt-2"
                      variant="danger"
                      block
                      @click="
                        updateDMK({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span class="align-middle" v-text="''" />
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMK">
                    <b-button
                      class="d-block mt-2"
                      block
                      variant="success"
                      :disabled="loadingDupRev"
                      @click="saveToUpdateDMK()"
                    >
                      <feather-icon
                        v-if="!loadingDupRev"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRev" small></b-spinner>

                      <span class="align-middle" v-text="'Simpan'" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="mb-1" />
          </tab-content>
          <!-- TUG BOAT -->
          <tab-content
            :title="wizard.first.step2.title"
            :icon="wizard.first.step2.icon"
            v-if="
              form.schedule.jenis_kapal === 'Tug Boat' ||
              form.schedule.jenis_kapal === 'Barge' ||
              false
            "
          >
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ wizard.first.step2.content.title }}</h5>
                <small class="text-muted">
                  <strong>{{ wizard.first.step2.content.subtitle }}.</strong>
                </small>
              </b-col>
            </b-row>
            <b-alert
              variant="danger"
              show
              class="mb-0"
              v-if="!form.form_tug_boat_id && $router.currentRoute.params.id"
            >
              <div class="alert-body">
                Jettyman terlebih dahulu melakukan ganti atau pilih
                <strong>Tug Boat</strong> untuk keberangkatan.
              </div>
            </b-alert>
            <br v-if="!form.form_tug_boat_id" />
            <!-- START OF TUG BOAT -->
            <b-row>
              <b-col md="12" cols="12">
                <b-row>
                  <b-col md="12" cols="12">
                    <b-form-group
                      label="Name Tug Boat *"
                      label-for="vi-name_tug_boat"
                    >
                      <div class="suggest-custom">
                        <validation-provider
                          #default="{ errors }"
                          name="Name Tug Boat"
                          :rules="{
                            required: true
                          }"
                        >
                          <vue-autosuggest
                            id="vi-tug_boat-name"
                            v-model="form.tug_boat.name_tug_boat"
                            v-uppercase
                            :suggestions="[{ data: handlingTugBoat() }]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Boat Name',
                              disabled: permissionSuggestionTugBoatMain()
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'tug_boat',
                                'name_tug_boat'
                              )
                            "
                            @input="
                              searchForm({
                                keyword: $event,
                                uri: `vw_tug_boat/${
                                  $can('manage', 'jetty')
                                    ? 'mobile_hm'
                                    : 'Hulu Migas'
                                }`,
                                model: 'tug_boat',
                                currentSuggest: 'name_tug_boat'
                              })
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :text="
                                    Array.from(
                                      suggestion.item.name_tug_boat
                                    )[0] +
                                    Array.from(suggestion.item.name_tug_boat)[1]
                                  "
                                  variant="primary"
                                />
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong>
                                      <span
                                        v-text="
                                          `${suggestion.item.name_tug_boat}, ${suggestion.item.no_pkk_tug_boat}`
                                        "
                                      />
                                      <!-- dalam perencanaan -->
                                      <div
                                        v-if="
                                          suggestion.item.tug_status ===
                                            'Dalam Perencanaan' &&
                                          suggestion.item.model_status ===
                                            'Tersedia'
                                        "
                                      >
                                        <b-badge
                                          v-if="
                                            suggestion.item.tug_status ===
                                            'Dalam Perencanaan'
                                          "
                                          :variant="
                                            suggestion.item.model_status ===
                                            'Dalam Perencanaan'
                                              ? 'danger'
                                              : suggestion.item.form_status ===
                                                'KAPAL SANDAR'
                                              ? 'info'
                                              : 'warning'
                                          "
                                        >
                                          <span
                                            v-text="
                                              `${
                                                suggestion.item.form_status ===
                                                'KAPAL SANDAR'
                                                  ? !dataID
                                                    ? 'Opsi ganti kapal'
                                                    : 'Opsi dapat Digunakan'
                                                  : 'Sedang Direncanakan'
                                              }`
                                            "
                                          ></span>
                                        </b-badge>
                                      </div>
                                      <!-- sedang digunakan -->
                                      <div v-else>
                                        <b-badge
                                          v-if="
                                            suggestion.item.model_status ===
                                            'Sedang Digunakan'
                                          "
                                          :variant="
                                            suggestion.item.model_status ===
                                            'Sedang Digunakan'
                                              ? 'danger'
                                              : 'secondary'
                                          "
                                        >
                                          <span
                                            v-text="
                                              suggestion.item.model_status
                                            "
                                          ></span>
                                        </b-badge>
                                        <span
                                          v-text="
                                            `${
                                              !suggestion.item.sedang_digunakan
                                                ? ''
                                                : ` : ${suggestion.item.sedang_digunakan}`
                                            }`
                                          "
                                        ></span>
                                      </div>
                                      <strong
                                        ><span
                                          v-text="
                                            `#${
                                              suggestion.item.form_chain_id ??
                                              '-'
                                            }#${
                                              suggestion.item
                                                .last_used_by_model_id ?? '-'
                                            }#${
                                              suggestion.item
                                                .form_tug_boat_id ?? '-'
                                            }`
                                          "
                                      /></strong>
                                      <br />
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `${
                                                suggestion.item.form_status ??
                                                `No Status ( ${
                                                  !dataID
                                                    ? ''
                                                    : 'Buat Rencana Kedatangan Kapal Terlebih dahulu'
                                                } )`
                                              }`
                                            "
                                        /></strong>
                                      </small>

                                      <br />
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Updated AT ${formatDateTime(
                                                suggestion.item.updated_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                    </strong>
                                  </b-card-text>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1 mb-1 mr-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[tug_boatState]
                                  "
                                  class="mr-1"
                                >
                                  <span>
                                    {{ statusSuggestionChoose[tug_boatState] }}
                                  </span>
                                </b-badge>
                                <b-badge variant="info">
                                  Total:
                                  {{ form.tug_boat.tug_boats.length }}
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>

                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-if="
                              errorPath &&
                              errorMatchValue === form.tug_boat.name_tug_boat
                            "
                            class="text-danger"
                            >{{
                              errorPath === 'name_tug_boat' ? errorValue : ''
                            }}</small
                          >
                        </validation-provider>
                        <b-spinner
                          v-if="tug_boatStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="6">
                    <b-form-group
                      label="GT ( Kapal ) *"
                      label-for="vi-tug_boat-gt_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" GT ( Kapal ) *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group append="">
                          <cleave
                            id="vi-tug_boat-gt_tug_boat"
                            v-model="form.tug_boat.gt_tug_boat"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionSuggestionTugBoat()"
                            placeholder="1.000 (dot automaticaly)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="6">
                    <b-form-group
                      label="LOA ( Kapal ) *"
                      label-for="vi-tug_boat-loa_tug_boat"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" LOA ( Kapal ) *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group append="M">
                          <!-- <cleave
                          id="vi-tug_boat-loa_tug_boat"
                          v-model="form.tug_boat.loa_tug_boat"
                          class="form-control"
                          :raw="true"
                          :options="options.number"
                          :readonly="permissionSuggestionTugBoat()"
                          placeholder="78,12 (comma manual)"
                        /> -->
                          <b-form-input
                            id="vi-tug_boat-loa_tug_boat"
                            v-model="form.tug_boat.loa_tug_boat"
                            class="form-control"
                            type="number"
                            placeholder="78,12 (comma manual)"
                            :readonly="permissionSuggestionTugBoat()"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Name *"
                      label-for="vi-tug_boat-captain_name_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Capt. Name *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-tug_boat-captain_name_tug_boat"
                          v-model="form.tug_boat.captain_name_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="Full Capt. Name"
                          :readonly="permissionSuggestionTugBoat()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Telp *"
                      label-for="vi-tug_boat-captain_telp_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Capt. Telp *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-tug_boat-captain_telp_tug_boat"
                          v-model="form.tug_boat.captain_telp_tug_boat"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="081145778613"
                          :readonly="permissionSuggestionTugBoat()"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="12" v-if="!statePayloadDMKTBMaster">
                    <b-form-group label="Nomor PKK *" label-for="vi-no_pkk_tb">
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" Nomor PKK *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-no_pkk_tb"
                          v-model="form.schedule.no_pkk_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionTenantAndSAL()"
                          placeholder="Cth: PKK.DN.IDSRI.2208.000123"
                        />
                        <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue === form.schedule.no_pkk_tug_boat
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'no_pkk_tug_boat' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col :md="!statePayloadDMKTBMaster ? 6 : 12" cols="12">
                    <b-form-group
                      label="Perusahaan Shipping Agent *"
                      label-for="vi-dm_shipping_agent-shipping_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Perusahaan Shipping Agent *"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-dm_shipping_agent-shipping_name"
                          v-model="
                            form.tug_boat.dm_shipping_agent.shipping_name
                          "
                          v-uppercase
                          :get-suggestion-value="getSuggestionValue"
                          :suggestions="[
                            {
                              data:
                                form.tug_boat.dm_shipping_agent
                                  .dm_shipping_agents || []
                            }
                          ]"
                          :state="errors.length > 0 ? false : null"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control form-input',
                            placeholder: 'Cth: PT LINTAS SERAM M',
                            disabled: permissionSuggestionTugBoat()
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'tug_boat',
                              'dm_shipping_agent',
                              'dm_shipping_agent.shipping_name'
                            )
                          "
                          @input="
                            searchForm({
                              keyword: $event,
                              uri: 'dm_shipping_agent/',
                              model: 'tug_boat',
                              currentSuggest: 'tug_boat',
                              childSuggesst: 'dm_shipping_agent.shipping_name'
                            })
                          "
                        >
                          <!-- !form.tug_boat.dm_shipping_agent.isNew -->
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong>
                                    <span class="my-suggestion-item">{{
                                      suggestion.item.shipping_name
                                    }}</span>
                                  </strong>
                                </b-card-text>
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `${dateFormat(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1">
                              <b-badge
                                href="#"
                                pill
                                class="mr-1"
                                :variant="
                                  statusSuggestionColor[dm_shipping_agentState]
                                "
                              >
                                <span>
                                  {{
                                    statusSuggestionChoose[
                                      dm_shipping_agentState
                                    ]
                                  }}</span
                                >
                              </b-badge>
                              <b-badge variant="info">
                                Total:
                                {{
                                  form.tug_boat.dm_shipping_agent
                                    .dm_shipping_agents.length
                                }}
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue ===
                              form.tug_boat.dm_shipping_agent.shipping_name
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'shipping_name' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                      <b-spinner
                        v-if="dm_shipping_agentStateLoading"
                        class="suggest-loading"
                        small
                        variant="primary"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    v-if="form.schedule.jenis_kapal === 'Tug Boat'"
                  >
                    <div class="divider my-2">
                      <div class="divider-text">
                        <b-badge :variant="'info'">Schedule</b-badge>
                      </div>
                    </div>
                    <b-row>
                      <b-col md="3" cols="6">
                        <b-form-group
                          label="Asal Kapal *"
                          label-for="vi-asal_kapal"
                        >
                          <div class="suggest-custom">
                            <validation-provider
                              #default="{ errors }"
                              name="Asal Kapal"
                              rules="required"
                            >
                              <vue-autosuggest
                                id="vi-asal-kapal"
                                v-model="form.dm_asal_kapal.asal"
                                v-uppercase
                                :suggestions="[
                                  { data: form.dm_asal_kapal.dm_asal_kapals }
                                ]"
                                :get-suggestion-value="getSuggestionValue"
                                :input-props="{
                                  id: 'autosuggest__input',
                                  class: 'form-control',
                                  placeholder: 'Asal Kapal',
                                  disabled: permissionTenantAndSAL()
                                }"
                                @focus="
                                  getSuggestionValueFocus(
                                    $event,
                                    'dm_asal_kapal',
                                    'asal'
                                  )
                                "
                                @input="
                                  searchForm({
                                    keyword: $event,
                                    uri: 'dm_asal_kapal/',
                                    model: 'dm_asal_kapal',
                                    currentSuggest: 'asal'
                                  })
                                "
                              >
                                <template slot-scope="{ suggestion }">
                                  <div class="d-flex align-items-center">
                                    <b-avatar
                                      :text="
                                        Array.from(suggestion.item.asal)[0] +
                                        Array.from(suggestion.item.asal)[1]
                                      "
                                      variant="primary"
                                    />
                                    <div class="detail ml-50">
                                      <b-card-text class="mb-0">
                                        <strong
                                          ><span
                                            v-text="`${suggestion.item.asal}`"
                                        /></strong>
                                        <br />
                                        <small class="text-muted">
                                          <strong
                                            ><span
                                              v-text="
                                                `Created AT ${formatDateTime(
                                                  suggestion.item.created_at
                                                )}`
                                              "
                                          /></strong>
                                        </small>
                                        <br />
                                      </b-card-text>
                                    </div>
                                  </div>
                                </template>
                                <template slot="before-suggestions">
                                  <div class="ml-1 mt-1 mb-1 mr-1">
                                    <b-badge
                                      href="#"
                                      pill
                                      :variant="
                                        statusSuggestionColor[
                                          dm_asal_kapalState
                                        ]
                                      "
                                      class="mr-1"
                                    >
                                      <span>
                                        {{
                                          statusSuggestionChoose[
                                            dm_asal_kapalState
                                          ]
                                        }}</span
                                      >
                                    </b-badge>
                                    <b-badge variant="info">
                                      Total:
                                      {{
                                        form.dm_asal_kapal.dm_asal_kapals.length
                                      }}
                                    </b-badge>
                                  </div>
                                </template>
                              </vue-autosuggest>

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small
                                v-if="
                                  errorPath &&
                                  errorMatchValue === form.dm_asal_kapal.asal
                                "
                                class="text-danger"
                                >{{
                                  errorPath === 'asal' ? errorValue : ''
                                }}</small
                              >
                            </validation-provider>
                            <!-- <b-input-group-append is-text> -->
                            <b-spinner
                              v-if="dm_asal_kapalStateLoading"
                              class="suggest-loading"
                              small
                              variant="primary"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" cols="6">
                        <b-form-group
                          label="Rencana Posisi Jetty *"
                          label-for="vi-schedule-rencana_position_jetty"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Rencana Posisi Jetty"
                            vid="Rencana Posisi Jetty"
                            :rules="{
                              required: true
                            }"
                          >
                            <v-select
                              id="vi-schedule-rencana_position_jetty"
                              v-model="form.tug_boat.rencana_position_jetty"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="jetty_positions"
                              :reduce="(option) => option.value"
                              :selectable="
                                (option) => !option.value.includes('null')
                              "
                              :disabled="permissionTenantAndSAL()"
                              label="text"
                              placeholder="Pilih Rencana Posisi Jetty"
                              item-value="value"
                              item-text="text"
                              @search:focus="onFocusJetty()"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" cols="6">
                        <b-form-group
                          label="Rencana Baris Posisi Jetty *"
                          label-for="vi-schedule-rencana_baris_position_jetty"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Rencana Baris Posisi Jetty"
                            vid="Rencana Baris Posisi Jetty"
                            :rules="{
                              required: true
                            }"
                          >
                            <v-select
                              id="vi-schedule-rencana_baris_position_jetty"
                              v-model="
                                form.tug_boat.rencana_baris_position_jetty
                              "
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="jetty_baris_positions"
                              :reduce="(option) => option.value"
                              :selectable="
                                (option) => !option.value.includes('null')
                              "
                              :disabled="permissionTenantAndSAL()"
                              label="text"
                              placeholder="Pilih Rencana Baris Posisi Jetty"
                              item-value="value"
                              item-text="text"
                              @search:focus="onFocusJetty()"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" cols="12">
                        <!-- rencana sandar tug boat -->
                        <b-form-group
                          label="Rencana Sandar Tug Boat *"
                          label-for="vi-rencana_sandar"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Rencana Sandar Tug Boat *"
                            rules="required"
                          >
                            <div
                              v-if="
                                form.schedule.form_status ===
                                  'KAPAL BERANGKAT' ||
                                form.schedule.form_status ===
                                  'KEGIATAN DIBATALKAN'
                              "
                            >
                              <b-form-input
                                id="vi-rencana_sandar"
                                :value="
                                  formatDateTime(form.schedule.rencana_sandar)
                                "
                                :readonly="!$can('manage', 'all')"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </div>
                            <div v-else>
                              <flat-pickr
                                v-if="!permissionTenantAndSAL()"
                                v-model="form.tug_boat.rencana_sandar"
                                class="form-control"
                                static="true"
                                placeholder="Rencana Sandar Tug Boat *"
                                :state="errors.length > 0 ? false : null"
                                :config="
                                  $router.currentRoute.params.id !== undefined
                                    ? dpconfigRevisi
                                    : dpconfig
                                "
                                @on-change="onStartChange"
                              />
                              <b-form-input
                                v-else
                                id="vi-rencana_sandar"
                                :value="
                                  formatDateTime(form.tug_boat.rencana_sandar)
                                "
                                :readonly="
                                  !$can('manage', 'all') &&
                                  $can('Hulu Migas', '')
                                "
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- show only on create data | action for suggestion value -->
                  <b-col
                    cols="12"
                    v-if="!isExistDetailID && !form.tug_boat.isNew"
                    class="text-left"
                  >
                    <div class="float-left">
                      <b-button
                        class="mr-1"
                        variant="outline-danger"
                        @click="breakSuggestionValueTB()"
                      >
                        <feather-icon
                          icon="PenToolIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <span
                          class="align-middle"
                          v-text="
                            'Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )'
                          "
                        />
                      </b-button>
                      <span
                        ><small
                          ><strong
                            >Abaikan jika data master kapal yang otomatis
                            teriisi sudah benar</strong
                          ></small
                        ></span
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" cols="12">
                <b-row>
                  <b-col
                    class="text-left"
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        statePayloadDMKTB === null &&
                        !statePayloadDMKTBMaster) ||
                      statePayloadDMKTB ===
                        mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                    "
                  >
                    <b-button
                      variant="outline-danger"
                      class="mt-2"
                      block
                      :disabled="
                        !form.schedule.no_pkk_tug_boat || !editAble(user_id)
                      "
                      @click="
                        updateDMKTB({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle">Buat data baru</span>
                    </b-button>
                    <span
                      >Contoh kasus: data kapal belum pernah dilakukan
                      penginputan.</span
                    >
                  </b-col>
                  <b-col
                    class="text-left"
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        isExistDetailID &&
                        statePayloadDMKTB === null &&
                        !statePayloadDMKTBMaster) ||
                      statePayloadDMKTB ===
                        mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                    "
                  >
                    <b-button
                      variant="outline-warning"
                      class="mt-2"
                      block
                      :disabled="
                        !form.schedule.no_pkk_tug_boat || !editAble(user_id)
                      "
                      @click="
                        updateDMKTB({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle"
                        >Revisi data tug boat, Jika ada data yang tidak
                        sesuai.</span
                      >
                    </b-button>
                    <span>Contoh kasus: kesalahan penulisan</span>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTB">
                    <b-button
                      class="d-block mt-2"
                      variant="danger"
                      block
                      @click="
                        updateDMKTB({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span class="align-middle" v-text="''" />
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTB">
                    <b-button
                      class="d-block mt-2"
                      variant="success"
                      block
                      :disabled="loadingDupRevTB"
                      @click="saveToUpdateDMKTB()"
                    >
                      <feather-icon
                        v-if="!loadingDupRevTB"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRevTB" small></b-spinner>
                      <span class="align-middle" v-text="'SIMPAN'" />
                    </b-button>
                  </b-col>
                  <b-col class="text-right" md="12" cols="12">
                    <b-button
                      v-if="
                        $store.state.app.roleName !== 'viewer' &&
                        ($can('manage', 'jetty') || $can('SAL', '')) &&
                        isExistDetailID &&
                        !statePayloadDMKTB &&
                        !statePayloadDMKTBMaster
                      "
                      variant="primary"
                      class="mt-2"
                      block
                      @click="
                        updateDMKTBMaster({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.CHANGE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="DatabaseIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle">Ganti/Pilih Kapal</span>
                    </b-button>
                  </b-col>
                  <!-- for ganti kapal tug boat cancel or save -->
                  <b-col md="2" cols="12" v-if="statePayloadDMKTBMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="danger"
                      @click="
                        updateDMKTBMaster({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span></span>
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTBMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="success"
                      :disabled="loadingDupRevTB"
                      @click="jwtGantiKapalTB()"
                    >
                      <feather-icon
                        v-if="!loadingDupRevTB"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRevTB" small></b-spinner>
                      <span>Simpan</span>
                    </b-button>
                  </b-col>
                  <b-col v-if="isExistDetailID">
                    <b-alert
                      :variant="'primary'"
                      show
                      class="mt-1 cursor-pointer"
                    >
                      <div class="alert-body">
                        <strong>
                          <feather-icon icon="InfoIcon" class="mr-25" />
                          <span
                            v-text="
                              `Hanya jettyman yang dapat mengganti TugBoat.`
                            "
                          />
                        </strong>
                      </div>
                    </b-alert>
                  </b-col>
                  <!-- end of for ganti kapal tug boat cancel or save -->
                </b-row>
              </b-col>
            </b-row>
            <!-- END OF TUG BOAT -->
            <div class="mb-1" />
          </tab-content>

          <!-- TAB FORM VISITOR | Jumlah Crew Kapal -->
          <tab-content title="Crew Kapal" icon="feather icon-anchor">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Crew Kapal</h5>
                <small class="text-muted">
                  <strong
                    >Untuk keperluan monitoring jumlah crew kapal yang masuk
                    area PT SAL.</strong
                  >
                </small>
              </b-col>
            </b-row>
            <validation-observer ref="refVisitor" tag="form">
              <span v-if="form.schedule.jenis_kapal === 'Barge'">
                <b-row class="match-height">
                  <!-- BATAS -->
                  <b-col>
                    <!-- left -->
                    <b-card>
                      <div class="divider my-2">
                        <div class="divider-text">
                          <!-- <b-badge :variant="'info'">Main Boat</b-badge> -->
                          <h3>
                            {{ this.form.dm_river_boat_hm.boat_name }}
                          </h3>
                        </div>
                      </div>
                      <b-form-group
                        label="Jumlah Crew Kapal *"
                        label-for="vi-schedule-count_crew_mb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal *"
                          vid="Jumlah Crew Kapal *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_mb"
                            v-model="form.schedule.count_crew_mb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-card>
                  </b-col>
                  <b-col>
                    <!-- left -->
                    <b-card>
                      <div class="divider my-2">
                        <div class="divider-text">
                          <!-- <b-badge :variant="'info'">Tug Boat</b-badge> -->
                          <h3>
                            {{ this.form.tug_boat.name_tug_boat }}
                          </h3>
                        </div>
                      </div>
                      <b-form-group
                        label="Jumlah Crew Kapal *"
                        label-for="vi-schedule-count_crew_tb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal *"
                          vid="Jumlah Crew Kapal *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_tb"
                            v-model="form.schedule.count_crew_tb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-card>
                  </b-col>
                </b-row>
              </span>
              <b-row v-else-if="form.schedule.jenis_kapal === 'Tug Boat'">
              <b-col md="6" cols="12">
                <b-card>
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Jumlah Crew Kapal *"
                        label-for="vi-schedule-count_crew_tb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal *"
                          vid="Jumlah Crew Kapal *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_tb"
                            v-model="form.schedule.count_crew_tb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col md="6" cols="12">
                <b-card>
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Jumlah Crew Kapal *"
                        label-for="vi-schedule-count_crew_mb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal *"
                          vid="Jumlah Crew Kapal *"
                          :rules="{
                            required: true
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_mb"
                            v-model="form.schedule.count_crew_mb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
              <b-button
                v-if="isExistDetailID && !permissionTenantAndSAL()"
                variant="gradient-primary"
                class="mt-2"
                block
                :disabled="loadingRealisasiCrew"
                @click="saveCrewKapal()"
              >
                <feather-icon
                  v-if="!loadingRealisasiCrew"
                  icon="CheckCircleIcon"
                  :style="{ cursor: 'pointer' }"
                  class="mr-50"
                />
                <b-spinner v-if="loadingRealisasiCrew" small></b-spinner>
                <span class="align-middle">Simpan Jumlah Crew Kapal</span>
              </b-button>
            </validation-observer>
            <div class="mb-1" />
          </tab-content>

          <!-- SCHEDULE -->
          <tab-content
            :title="wizard.first.step3.title"
            :icon="wizard.first.step3.icon"
            v-if="form.schedule.jenis_kapal !== 'Tug Boat' || false"
          >
            <validation-observer ref="scheduleHuluMigasRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">{{ wizard.first.step3.content.title }}</h5>
                  <small class="text-muted">
                    <strong>{{ wizard.first.step3.content.subtitle }}.</strong>
                  </small>
                </b-col>
              </b-row>

              <b-row>
                <!-- ROW RIGHT POSITION -->
                <b-col md="12" cols="12">
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Asal Kapal *"
                        label-for="vi-asal_kapal"
                      >
                        <div class="suggest-custom">
                          <validation-provider
                            #default="{ errors }"
                            name="Asal Kapal"
                            rules="required"
                          >
                            <vue-autosuggest
                              id="vi-asal-kapal"
                              v-model="form.dm_asal_kapal.asal"
                              v-uppercase
                              :suggestions="[
                                { data: form.dm_asal_kapal.dm_asal_kapals }
                              ]"
                              :get-suggestion-value="getSuggestionValue"
                              :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: 'Asal Kapal',
                                disabled: permissionTenantAndSAL()
                              }"
                              @focus="
                                getSuggestionValueFocus(
                                  $event,
                                  'dm_asal_kapal',
                                  'asal'
                                )
                              "
                              @input="
                                searchForm({
                                  keyword: $event,
                                  uri: 'dm_asal_kapal/',
                                  model: 'dm_asal_kapal',
                                  currentSuggest: 'asal'
                                })
                              "
                            >
                              <template slot-scope="{ suggestion }">
                                <div class="d-flex align-items-center">
                                  <b-avatar
                                    :text="
                                      Array.from(suggestion.item.asal)[0] +
                                      Array.from(suggestion.item.asal)[1]
                                    "
                                    variant="primary"
                                  />
                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      <strong
                                        ><span
                                          v-text="`${suggestion.item.asal}`"
                                      /></strong>
                                      <br />
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Created AT ${formatDateTime(
                                                suggestion.item.created_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                      <br />
                                    </b-card-text>
                                  </div>
                                </div>
                              </template>
                              <template slot="before-suggestions">
                                <div class="ml-1 mt-1 mb-1 mr-1">
                                  <b-badge
                                    href="#"
                                    pill
                                    :variant="
                                      statusSuggestionColor[dm_asal_kapalState]
                                    "
                                    class="mr-1"
                                  >
                                    <span>
                                      {{
                                        statusSuggestionChoose[
                                          dm_asal_kapalState
                                        ]
                                      }}</span
                                    >
                                  </b-badge>
                                  <b-badge variant="info">
                                    Total:
                                    {{
                                      form.dm_asal_kapal.dm_asal_kapals.length
                                    }}
                                  </b-badge>
                                </div>
                              </template>
                            </vue-autosuggest>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="
                                errorPath &&
                                errorMatchValue === form.dm_asal_kapal.asal
                              "
                              class="text-danger"
                              >{{
                                errorPath === 'asal' ? errorValue : ''
                              }}</small
                            >
                          </validation-provider>
                          <!-- <b-input-group-append is-text> -->
                          <b-spinner
                            v-if="dm_asal_kapalStateLoading"
                            class="suggest-loading"
                            small
                            variant="primary"
                          />
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" cols="6">
                      <b-form-group
                        label="Rencana Posisi Jetty *"
                        label-for="vi-schedule-rencana_position_jetty"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Rencana Posisi Jetty"
                          vid="Rencana Posisi Jetty"
                          :rules="{
                            required: true
                          }"
                        >
                          <v-select
                            id="vi-schedule-rencana_position_jetty"
                            v-model="form.schedule.rencana_position_jetty"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="jetty_positions"
                            :reduce="(option) => option.value"
                            :selectable="
                              (option) => !option.value.includes('null')
                            "
                            :disabled="permissionTenantAndSAL()"
                            label="text"
                            placeholder="Pilih Rencana Posisi Jetty"
                            item-value="value"
                            item-text="text"
                            @search:focus="onFocusJetty()"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" cols="6">
                      <b-form-group
                        label="Rencana Baris Posisi Jetty *"
                        label-for="vi-schedule-rencana_baris_position_jetty"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Rencana Baris Posisi Jetty"
                          vid="Rencana Baris Posisi Jetty"
                          :rules="{
                            required: true
                          }"
                        >
                          <v-select
                            id="vi-schedule-rencana_baris_position_jetty"
                            v-model="form.schedule.rencana_baris_position_jetty"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="jetty_baris_positions"
                            :reduce="(option) => option.value"
                            :selectable="
                              (option) => !option.value.includes('null')
                            "
                            :disabled="permissionTenantAndSAL()"
                            label="text"
                            placeholder="Pilih Rencana Baris Posisi Jetty"
                            item-value="value"
                            item-text="text"
                            @search:focus="onFocusJetty()"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Rencana Sandar *"
                        label-for="vi-rencana_sandar"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Rencana Sandar"
                          rules="required"
                        >
                          <div
                            v-if="
                              form.schedule.form_status === 'KAPAL BERANGKAT' ||
                              form.schedule.form_status ===
                                'KEGIATAN DIBATALKAN'
                            "
                          >
                            <b-form-input
                              id="vi-rencana_sandar"
                              :value="
                                formatDateTime(form.schedule.rencana_sandar)
                              "
                              :readonly="!$can('manage', 'all')"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                          <div v-else>
                            <flat-pickr
                              v-if="!permissionTenantAndSAL()"
                              v-model="form.schedule.rencana_sandar"
                              class="form-control"
                              static="true"
                              placeholder="Rencana Sandar"
                              :state="errors.length > 0 ? false : null"
                              :config="
                                $router.currentRoute.params.id !== undefined
                                  ? dpconfigRevisi
                                  : dpconfig
                              "
                              @on-change="onStartChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-rencana_sandar"
                              :value="
                                formatDateTime(form.schedule.rencana_sandar)
                              "
                              :readonly="
                                !$can('manage', 'all') && $can('Hulu Migas', '')
                              "
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ACTION PERUBAHAAN FORM DATA -->
                    <b-col class="text-right" md="12" cols="12">
                      <b-button
                        v-if="
                          isExistDetailID &&
                          $store.state.app.roleName !== 'viewer' &&
                          $store.state.app.roleName !== 'jetty man'
                        "
                        variant="gradient-primary"
                        block
                        :disabled="loadingChange || !editAble(user_id)"
                        @click="validationModifiedFormData()"
                      >
                        <feather-icon
                          v-if="!loadingChange"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner small v-if="loadingChange"></b-spinner>
                        <span class="align-middle">
                          Simpan Perubahan Form Data
                        </span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="mb-1" />
            </validation-observer>
          </tab-content>
          <!-- UPLOAD FILE -->
          <tab-content
            :title="wizard.first.step4.title"
            :icon="wizard.first.step4.icon"
            v-if="
              ($router.currentRoute.params.id &&
                form.schedule.jenis_kapal !== 'Tug Boat') ||
              false
            "
          >
            <div
              class="divider my-2"
              v-if="form.schedule.jenis_kapal === 'Barge'"
            >
              <div class="divider-text">
                <b-badge :variant="'info'">Barge</b-badge>
                <h3>Document {{ form.dm_river_boat_hm.boat_name }}</h3>
              </div>
            </div>
            <b-card>
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">
                    [1]. {{ wizard.first.step4.content.title }}
                  </h5>
                  <small class="text-muted">
                    <strong>{{ wizard.first.step4.content.subtitle }}.</strong>
                  </small>
                </b-col>
              </b-row>
              <b-spinner v-if="loadingFileStorage" small></b-spinner>
              <DropZoneUploadV1
                v-else
                :key="isHaveFile.length"
                :uri="BACKEND_URL"
                :update-i-d="form.form_chain_hm_id"
                accept-file="application/pdf,image/jpeg,image/png,.jpeg,.jpg,.png,.pdf,.xls,.xlsx"
                :max-files="10"
                re-upload-title="Re Upload Pengajuan RKBM  (Max 10 File Excel)"
                :is-have-file="isHaveFile"
                :modelName="'form_chain_hm'"
                info="Maksimal 10 Berkas"
                :disable-action="!editAbleDocument(user_id)"
                @callBack="dropZoneCallBack"
              />
            </b-card>
            <b-card v-if="form.schedule.jenis_kapal === 'Barge'">
              <span>
                Nama Tugboat ( <strong>keterangan</strong> ini sebagai pengajuan
                RKBM ) *
              </span>

              <b-row>
                <b-col md="12" cols="12">
                  <b-form-group label="" label-for="vi-nama_tb">
                    <!-- regex: /^[0-9]+$/ -->
                    <validation-provider
                      #default="{ errors }"
                      name=" Nama TugBoat"
                    >
                      <b-form-input
                        id="vi-nama_tb"
                        v-model="form.optional.nama_tb"
                        v-uppercase
                        :state="errors.length > 0 ? false : null"
                        :readonly="permissionTenantAndSAL()"
                        placeholder="TUGBOAT"
                      />
                      <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="
                          errorPath && errorMatchValue === form.tug_boat.nama_tb
                        "
                        class="text-danger"
                        >{{ errorPath === 'nama_tb' ? errorValue : '' }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                @click="saveNamaTB(true)"
                :disabled="form.optional.isLoading"
              >
                <feather-icon
                  v-if="!form.optional.isLoading"
                  icon="CheckCircleIcon"
                  :style="{ cursor: 'pointer' }"
                  class="mr-50"
                />
                <b-spinner v-if="form.optional.isLoading" small></b-spinner>
                Simpan TugBoat
              </b-button>
              <b-alert :variant="'primary'" show class="mt-1 cursor-pointer">
                <div class="alert-body">
                  <strong>
                    <feather-icon icon="AlertTriangleIcon" class="mr-25" />
                    <span
                      v-text="
                        `Menyimpan informasi Nama TugBoat akan mengirimkan notifikasi pengajuan RKBM Telah diajukan.`
                      "
                    />
                  </strong>
                </div>
              </b-alert>
            </b-card>
            <b-card v-else>
              <b-button
                @click="saveNamaTB(false)"
                :disabled="form.optional.isLoading"
              >
                <feather-icon
                  v-if="!loadingRealisasiKegiatan"
                  icon="SendIcon"
                  :style="{ cursor: 'pointer' }"
                  class="mr-50"
                />
                <b-spinner v-if="loadingRealisasiKegiatan" small></b-spinner>
                Kirim Notifikasi RKBM Telah Diajukan
              </b-button>
              <b-alert :variant="'primary'" show class="mt-1 cursor-pointer">
                <div class="alert-body">
                  <strong>
                    <feather-icon icon="CheckCircleIcon" class="mr-25" />
                    <span
                      v-text="`Sudah mengirimkan notifikasi pengajuan RKBM.`"
                    />
                  </strong>
                </div>
              </b-alert>
            </b-card>
            <div
              class="divider my-2"
              v-if="form.schedule.jenis_kapal === 'Barge'"
            >
              <div class="divider-text">
                <b-badge :variant="'info'">Tug Boat</b-badge>
                <h3>Document {{ form.tug_boat.name_tug_boat }}</h3>
              </div>
            </div>
            <b-row class="match-height" v-if="form.tug_boat.name_tug_boat">
              <b-col cols="12">
                <b-card>
                  <h5 class="mb-0">[2]. Document File Ijin Bunker</h5>
                  <small class="text-muted">
                    <strong
                      >max size 10 MB ( .xls, .xlsx, pdf , jpeg , jpg , png
                      ).</strong
                    >
                  </small>
                  <b-spinner v-if="loadingFileStorage" small></b-spinner>
                  <validation-observer ref="tbbmRulesFileBunker" tag="form">
                    <!-- name="File/Attachments/Document ( RKBM*, PBMBB, dan BL )" -->

                    <b-row>
                      <b-col md="6" cols="12">
                        <b-form-group
                          label="Nomor Ijin Bunker"
                          label-for="vi-schedule-no_ijin_bunker"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor Ijin Bunker"
                            vid="Nomor Ijin Bunker"
                            :rules="{
                              required: true
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_ijin_bunker"
                              v-model="form.schedule.no_ijin_bunker"
                              :state="errors.length > 0 ? false : null"
                              :readonly="permissionTenant()"
                              :placeholder="
                                $router.currentRoute.params.id
                                  ? 'Nomor Ijin BUnker'
                                  : 'Nomor Ijin BUnker'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" cols="12">
                        <!-- lct -->
                        <b-form-group
                          label="Volume (Total Bunker)"
                          label-for="vi-dm_river_boat-total_bunker"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Volume (Total Bunker) *"
                            rules="required"
                          >
                            <b-input-group append="Liter">
                              <cleave
                                id="vi-dm_river_boat-total_bunker"
                                v-model="form.schedule.total_bunker"
                                class="form-control"
                                :raw="true"
                                :options="options.number"
                                placeholder="1.000 (dot automaticaly)"
                              />
                              <!-- :readonly="permissionTenantChild()" -->
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" cols="12">
                        <b-form-group
                          label=""
                          label-for="vi-schedule-ijin_bunker"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Ijin Bunker"
                            vid="Ijin Bunker"
                            :rules="{
                              required: true
                            }"
                          >
                            <BFormFile
                              v-model="form.schedule.no_ijin_bunker_file"
                              placeholder="Choose a file Ijin Bunker or drop it here..."
                              drop-placeholder="Drop file here..."
                              :state="errors.length > 0 ? false : null"
                              accept=".xls, .xlsx, .pdf, .jpeg, .jpg, .png"
                              no-drop
                            />
                            <small class="text-danger">{{ errors[0] }}</small>

                            <app-timeline-item
                              v-for="(item, index) in no_ijin_bunker_file"
                              :key="index"
                            >
                              <div
                                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                              ></div>
                              <p>
                                <b-img
                                  :src="
                                    require('@/assets/images/icons/xls.png')
                                  "
                                  height="auto"
                                  width="20"
                                  class="mr-1"
                                />
                                <span class="align-bottom">
                                  <a
                                    :href="item.download_link"
                                    target="_blank"
                                    style="color: grey"
                                    rel="noopener noreferrer"
                                    >{{ item.original_name }}</a
                                  >
                                </span>
                              </p>
                              <small class="text-muted"
                                >Size {{ item.size }}
                                <strong
                                  >[ click name file, to preview.]</strong
                                ></small
                              >
                            </app-timeline-item>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        class="text-right"
                        md="12"
                        cols="12"
                        v-if="!permissionTenant() && isExistDetailID"
                      >
                        <br />
                        <!-- <div class="float-right"> -->
                        <b-button
                          variant="gradient-primary"
                          @click="saveIjinBunker()"
                          block
                          :disabled="loadingSaveTenant || !editAble(user_id)"
                          v-if="
                            $store.state.app.roleName !== 'viewer' &&
                            $store.state.app.roleName !== 'jetty man'
                          "
                        >
                          <feather-icon
                            v-if="!loadingSaveTenant"
                            icon="CheckCircleIcon"
                            :style="{ cursor: 'pointer' }"
                            class="mr-50"
                          />
                          <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                          <span
                            class="align-middle"
                            v-text="'Simpan Ijin Bunker'"
                          />
                        </b-button>
                        <!-- </div> -->
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>
          <!-- KEGIATAN -->
          <tab-content
            :title="wizard.first.step5.title"
            :icon="wizard.first.step5.icon"
            v-if="
              ($router.currentRoute.params.id &&
                form.schedule.jenis_kapal !== 'Tug Boat') ||
              false
            "
          >
            <div
              v-if="
                form.schedule.jenis_kapal === 'LCT / KM / MT / SPOB / Sea Truck'
              "
            >
              <b-alert variant="primary" show class="mb-0">
                <div class="alert-body">
                  Keterangan
                  <strong> ( Only Sea Truck )</strong>
                </div>
              </b-alert>
              <br />
              <b-row>
                <b-col md="8" cols="12">
                  <b-form-group
                    label="Keterangan"
                    label-for="vi-keterangan_sea_truck"
                  >
                    <!-- regex: /^[0-9]+$/ -->
                    <validation-provider
                      #default="{ errors }"
                      name="Keterangan"
                    >
                      <b-form-input
                        id="vi-keterangan_sea_truck"
                        v-model="form.schedule.keterangan_sea_truck"
                        v-uppercase
                        :state="errors.length > 0 ? false : null"
                        placeholder="Keterangan ( Only Sea Truck)"
                      />
                      <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="
                          errorPath &&
                          errorMatchValue === form.schedule.keterangan_sea_truck
                        "
                        class="text-danger"
                        >{{
                          errorPath === 'keterangan_sea_truck' ? errorValue : ''
                        }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" cols="12">
                  <b-button
                    block
                    class="mt-2"
                    variant="primary"
                    :disabled="loadingButton"
                    @click="simpanKeterangaSeaTruck()"
                  >
                    <feather-icon
                      v-if="!loadingButton"
                      icon="CheckCircleIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <b-spinner v-if="loadingButton" small></b-spinner>
                    <span>Simpan Keterangan</span>
                    <!-- Main Boat | Ganti Kapal -->
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <!-- content -->
            <b-tabs class="tabs-custom" content-class="" align="center">
              <b-tab v-if="form.form_hulu_migas_id" lazy>
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="25" />
                  <strong>
                    <span style="font-size: 1.5em">Tab Pipeline</span>
                  </strong>
                </template>
                <TablePipeLine
                  style="display: inline-grid"
                  ref="TablePipeLineOutside"
                  :key="form.table_pipe_line"
                  :kegiatan-select-data="`${form.schedule.kegiatan}`"
                  :fhm-i-d="form.form_hulu_migas_id"
                  :form-chain-hm-i-d="form.form_chain_hm_id"
                  :disable-crud="form.schedule.form_status"
                  @render-component="renderComponent"
                  :data-high-light="{
                    kapal: form.dm_river_boat_hm.boat_name,
                    no_pkk: form.schedule.no_pkk,
                    rencana_sandar: form.schedule.rencana_sandar
                  }"
                />
              </b-tab>
              <b-tab v-if="form.form_hulu_migas_id" lazy>
                <template #title>
                  <feather-icon icon="GitMergeIcon" size="25" />
                  <strong>
                    <span style="font-size: 1.5em">Tab Semi Mekanis</span>
                  </strong>
                </template>
                <TableMH
                  ref="TableManualHandlingOutside"
                  style="display: inline-grid"
                  :kegiatan-select-data="`${form.schedule.kegiatan}`"
                  :key="form.table_mh"
                  :fhm-i-d="form.form_hulu_migas_id"
                  :form-chain-hm-i-d="form.form_chain_hm_id"
                  :disable-crud="form.schedule.form_status"
                  :data-high-light="{
                    kapal: form.dm_river_boat_hm.boat_name,
                    no_pkk: form.schedule.no_pkk,
                    rencana_sandar: form.schedule.rencana_sandar
                  }"
                  :is-realisasi="false"
                  @render-component="renderComponent"
                />
              </b-tab>
            </b-tabs>
            <div class="mb-1" />
          </tab-content>
          <!-- JETTY MAN -->
          <tab-content
            v-if="
              permissionSalOnly() || $store.state.app.roleName === 'jetty man'
            "
            :title="wizard.second.step1.title"
            :icon="wizard.second.step1.icon"
          >
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">{{ wizard.second.step1.content.title }}</h5>
                <small class="text-muted">
                  <strong>{{ wizard.second.step1.content.subtitle }}.</strong>
                </small>
              </b-col>
            </b-row>
            <br />
            <b-tabs class="tabs-custom" content-class="" align="center">
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="AnchorIcon" size="20" />
                  <strong>
                    <span style="font-size: 1.5em"> Sandar & Berangkat </span>
                    <br />
                    <small>Tabs Menu</small>
                  </strong>
                </template>

                <!-- <validation-observer ref="refSandarSAL" tag="form"> -->
                <hr />
                <!-- MAIN BOAT RKA1 -->
                <b-tabs class="tabs-custom" content-class="" align="left">
                  <b-tab lazy>
                    <template #title>
                      <feather-icon icon="AnchorIcon" size="20" />
                      <strong>
                        <span
                          style="font-size: 1.5em"
                          v-text="form.dm_river_boat_hm.boat_name"
                        >
                        </span>
                        <br />
                        <small>Main Boat</small>
                      </strong>
                    </template>

                    <b-row>
                      <b-col cols="12" v-if="isExistDetailID">
                        <div class="divider my-2">
                          <div class="divider-text">
                            <!-- <b-badge :variant="'info'">RKA 1</b-badge> -->
                            <b-badge :variant="'info'">Main Boat</b-badge>
                            <h3>
                              Realisasi Kapal
                              {{ this.form.dm_river_boat_hm.boat_name }}
                            </h3>
                            <span>{{ this.form_realisasi.rka1 }}</span>
                            <br />
                            <span>Sandar → Berangkat</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        v-if="isExistDetailID"
                        :md="
                          ($store.state.app.roleName !== 'viewer' &&
                            permissionSalOnly()) ||
                          ($store.state.app.roleName === 'jetty man' &&
                            $store.state.app.roleName !== 'viewer')
                            ? '3'
                            : '3'
                        "
                        cols="6"
                      >
                        <b-form-group
                          label="Waktu Kapal Sandar"
                          label-for="vi-schedule-sandar_start_time"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Waktu Kapal Sandar"
                            vid="Waktu Kapal Sandar"
                            :rules="{
                              required_if: 'Waktu Kapal Berangkat'
                            }"
                          >
                            <flat-pickr
                              v-if="
                                permissionSalOnly() ||
                                ($store.state.app.roleName === 'jetty man' &&
                                  $store.state.app.roleName !== 'viewer')
                              "
                              v-model="form.schedule.sandar_start_time"
                              class="form-control"
                              static="true"
                              placeholder="WAKTU KAPAL SANDAR"
                              :state="errors.length > 0 ? false : null"
                              :config="dpconfig"
                              @on-change="onStartChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-schedule-sandar_start_time"
                              :value="
                                formatDateTime(form.schedule.sandar_start_time)
                              "
                              :state="errors.length > 0 ? false : null"
                              :readonly="permissionSalOnly()"
                              placeholder="Diisi Admin SAL | Jetty Man"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        :md="permissionSalOnly() ? '3' : '3'"
                        cols="12"
                      >
                        <b-form-group
                          label="Tujuan Kapal"
                          label-for="vi-asal_kapal"
                        >
                          <div class="suggest-custom">
                            <validation-provider
                              #default="{ errors }"
                              name="Tujuan Kapal"
                              rules=""
                            >
                              <vue-autosuggest
                                id="vi-tujuan-kapal"
                                v-model="form.schedule.tujuan_kapal"
                                v-uppercase
                                :suggestions="[
                                  { data: form.dm_asal_kapal.dm_asal_kapals }
                                ]"
                                :get-suggestion-value="getSuggestionValueTK"
                                :input-props="{
                                  id: 'autosuggest__input',
                                  class: 'form-control',
                                  placeholder: 'Tujuan Kapal'
                                }"
                                @focus="
                                  getSuggestionValueFocus(
                                    $event,
                                    'schedule',
                                    'tujuan_kapal'
                                  )
                                "
                                @input="
                                  searchForm({
                                    keyword: $event,
                                    uri: 'dm_asal_kapal/',
                                    model: 'dm_asal_kapal',
                                    currentSuggest: 'asal',
                                    flagTujuanOps: 'tujuan_kapal_is_new'
                                  })
                                "
                              >
                                <template slot-scope="{ suggestion }">
                                  <div class="d-flex align-items-center">
                                    <b-avatar
                                      :text="
                                        Array.from(suggestion.item.asal)[0] +
                                        Array.from(suggestion.item.asal)[1]
                                      "
                                      variant="primary"
                                    />
                                    <div class="detail ml-50">
                                      <b-card-text class="mb-0">
                                        <strong
                                          ><span
                                            v-text="`${suggestion.item.asal}`"
                                        /></strong>
                                        <br />
                                        <small class="text-muted">
                                          <strong
                                            ><span
                                              v-text="
                                                `Created AT ${formatDateTime(
                                                  suggestion.item.created_at
                                                )}`
                                              "
                                          /></strong>
                                        </small>
                                        <br />
                                      </b-card-text>
                                    </div>
                                  </div>
                                </template>
                                <template slot="before-suggestions">
                                  <div class="ml-1 mt-1 mb-1 mr-1">
                                    <b-badge
                                      href="#"
                                      pill
                                      :variant="
                                        statusSuggestionColor[
                                          dm_asal_kapalState
                                        ]
                                      "
                                      class="mr-1"
                                    >
                                      <span>
                                        {{
                                          statusSuggestionChoose[
                                            dm_asal_kapalState
                                          ]
                                        }}</span
                                      >
                                    </b-badge>
                                    <b-badge variant="info">
                                      Total:
                                      {{
                                        form.dm_asal_kapal.dm_asal_kapals.length
                                      }}
                                    </b-badge>
                                  </div>
                                </template>
                              </vue-autosuggest>

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small
                                v-if="
                                  errorPath &&
                                  errorMatchValue === form.dm_asal_kapal.asal
                                "
                                class="text-danger"
                                >{{
                                  errorPath === 'asal' ? errorValue : ''
                                }}</small
                              >
                            </validation-provider>
                            <!-- <b-input-group-append is-text> -->
                            <b-spinner
                              v-if="dm_asal_kapalStateLoading"
                              class="suggest-loading"
                              small
                              variant="primary"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="isExistDetailID"
                        :md="
                          ($store.state.app.roleName !== 'viewer' &&
                            permissionSalOnly()) ||
                          $store.state.app.roleName === 'jetty man'
                            ? '4'
                            : '6'
                        "
                        cols="6"
                      >
                        <b-alert
                          variant="danger"
                          show
                          class="mt-1"
                          v-if="
                            !form.form_tug_boat_id &&
                            form.schedule.jenis_kapal === 'Barge'
                          "
                        >
                          <div class="alert-body">
                            Jettyman terlebih dahulu melakukan ganti atau pilih
                            <strong>Tug Boat</strong> untuk keberangkatan.
                          </div>
                        </b-alert>
                        <b-form-group
                          label="Waktu Kapal Berangkat"
                          label-for="vi-schedule-sandar_end_time"
                          v-if="
                            form.schedule.jenis_kapal !== 'Barge' ||
                            form.form_tug_boat_id
                          "
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Waktu Kapal Berangkat"
                            vid="Waktu Kapal Berangkat"
                            :rules="{
                              required: false
                            }"
                          >
                            <flat-pickr
                              v-if="
                                permissionSalOnly() ||
                                $store.state.app.roleName === 'jetty man'
                              "
                              v-model="form.schedule.sandar_end_time"
                              class="form-control"
                              static="true"
                              placeholder="WAKTU KAPAL BERANGKAT"
                              :state="errors.length > 0 ? false : null"
                              :config="configs.end"
                              @on-change="onEndChange"
                            />
                            <b-form-input
                              v-else
                              id="vi-schedule-sandar_end_time"
                              :value="
                                formatDateTime(form.schedule.sandar_end_time)
                              "
                              :state="errors.length > 0 ? false : null"
                              :readonly="permissionSalOnly()"
                              placeholder="Diisi Admin SAL | Jetty Man"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        class="text-right"
                        md="2"
                        cols="12"
                        v-if="$store.state.app.roleName !== 'viewer'"
                      >
                        <b-button
                          v-if="
                            (isExistDetailID && permissionSalOnly()) ||
                            $store.state.app.roleName === 'jetty man'
                          "
                          variant="gradient-primary"
                          class="mt-2"
                          block
                          :disabled="loadingRelRKA1"
                          @click="saveRKA1()"
                        >
                          <feather-icon
                            v-if="!loadingRelRKA1"
                            icon="CheckCircleIcon"
                            :style="{ cursor: 'pointer' }"
                            class="mr-50"
                          />
                          <b-spinner v-if="loadingRelRKA1" small></b-spinner>
                          <span class="align-middle">Simpan Realisasi</span>
                        </b-button>
                      </b-col>
                    </b-row>

                    <div class="divider my-2">
                      <div class="divider-text">
                        <h3>Realisasi Posisi Jetty</h3>
                      </div>
                    </div>

                    <!-- REALISASI POSISI JETTY -->
                    <b-alert variant="primary" show class="mb-0">
                      <div class="alert-body">
                        Histori Kapal Awal Sandar / Pindah Jetty
                        <strong>Diisi Admin SAL / Jetty Man</strong>
                      </div>
                    </b-alert>
                    <br />
                    <NestedIO
                      uri="realisasi_posisi_kapal"
                      :key="form.table_jetty_position"
                      :model-id="form.form_hulu_migas_id"
                      model="form_hulu_migas"
                      :posisi="jetty_positions"
                      :posisi-baris="jetty_baris_positions"
                      :disable-action="!!this.form.schedule.sandar_start_time"
                      :payload="{
                        model_id: null,
                        model: null,
                        is_first: null,
                        posisi_awal: null,
                        posisi_awal_baris: null,
                        posisi_skrg: null,
                        posisi_skrg_baris: null,
                        reason: null,
                        waktu_pindah: null
                      }"
                      :column-repeat="[
                        {
                          fe_id: 1
                        }
                      ]"
                      :d-t-o="[]"
                      :resolve-fetch="
                        (response) => response.data.realisasi_posisi_kapals
                      "
                      buttonOkTitle=" Simpan Realisasi"
                      @sentNotification="sentNotification"
                      @alreadyRealisasi="alreadyRealisasi"
                    />
                  </b-tab>
                  <b-tab lazy v-if="form.schedule.jenis_kapal === 'Barge'">
                    <template #title>
                      <feather-icon icon="AnchorIcon" size="20" />
                      <strong>
                        <span
                          style="font-size: 1.5em"
                          v-text="form.tug_boat.name_tug_boat"
                        >
                        </span>
                        <br />
                        <small>Tug Boat</small>
                      </strong>
                    </template>
                    <!-- here content -->
                    <b-alert
                      variant="danger"
                      show
                      class="mb-0"
                      v-if="!form.form_tug_boat_id"
                    >
                      <div class="alert-body">
                        Jettyman terlebih dahulu melakukan ganti atau pilih
                        <strong>Tug Boat</strong> untuk keberangkatan.
                      </div>
                    </b-alert>
                    <div v-if="form.form_tug_boat_id">
                      <b-row>
                        <b-col cols="12" v-if="isExistDetailID">
                          <div class="divider my-2">
                            <div class="divider-text">
                              <!-- <b-badge :variant="'info'">RKA 2</b-badge> -->
                              <b-badge :variant="'info'">Tug Boat</b-badge>
                              <h3>
                                Realisasi Kapal
                                {{ this.form.tug_boat.name_tug_boat }}
                              </h3>
                              <span>{{ this?.form_realisasi?.rka2 }}</span>
                              <br />
                              <span>Sandar → Berangkat</span>
                            </div>
                          </div>
                        </b-col>
                        <b-col
                          v-if="isExistDetailID"
                          :md="
                            ($store.state.app.roleName !== 'viewer' &&
                              permissionSalOnly()) ||
                            $store.state.app.roleName === 'jetty man'
                              ? '6'
                              : '6'
                          "
                          cols="6"
                        >
                          <b-form-group
                            label="Waktu Kapal Sandar"
                            label-for="vi-tug_boat-tug_sandar_start_time"
                          >
                            <!-- regex: /^[0-9]+$/ -->
                            <validation-provider
                              #default="{ errors }"
                              name="Waktu Kapal Sandar"
                              vid="Waktu Kapal Sandar"
                              :rules="{
                                required_if: 'Waktu Kapal Berangkat'
                              }"
                            >
                              <flat-pickr
                                v-if="
                                  permissionSalOnly() ||
                                  $store.state.app.roleName === 'jetty man'
                                "
                                v-model="form.tug_boat.tug_sandar_start_time"
                                class="form-control"
                                static="true"
                                placeholder="WAKTU KAPAL SANDAR"
                                :state="errors.length > 0 ? false : null"
                                :config="dpconfig"
                                @on-change="onStartChange"
                              />
                              <b-form-input
                                v-else
                                id="vi-tug_boat-tug_sandar_start_time"
                                :value="
                                  formatDateTime(
                                    form.tug_boat.tug_sandar_start_time
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                                :readonly="permissionSalOnly()"
                                placeholder="Diisi Admin SAL | Jetty Man"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="isExistDetailID"
                          :md="
                            ($store.state.app.roleName !== 'viewer' &&
                              permissionSalOnly()) ||
                            $store.state.app.roleName === 'jetty man'
                              ? '4'
                              : '6'
                          "
                          cols="6"
                        >
                          <b-form-group
                            label="Waktu Kapal Berangkat"
                            label-for="vi-tug_boat-tug_sandar_end_time"
                          >
                            <!-- regex: /^[0-9]+$/ -->
                            <validation-provider
                              #default="{ errors }"
                              name="Waktu Kapal Berangkat"
                              vid="Waktu Kapal Berangkat"
                              :rules="{
                                required: false
                              }"
                            >
                              <flat-pickr
                                v-if="
                                  permissionSalOnly() ||
                                  $store.state.app.roleName === 'jetty man'
                                "
                                v-model="form.tug_boat.tug_sandar_end_time"
                                class="form-control"
                                static="true"
                                placeholder="WAKTU KAPAL BERANGKAT"
                                :state="errors.length > 0 ? false : null"
                                :config="configs.end"
                                @on-change="onEndChange"
                              />
                              <b-form-input
                                v-else
                                id="vi-tug_boat-tug_sandar_end_time"
                                :value="
                                  formatDateTime(
                                    form.tug_boat.tug_sandar_end_time
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                                :readonly="permissionSalOnly()"
                                placeholder="Diisi Admin SAL | Jetty Man"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          class="text-right"
                          md="2"
                          cols="12"
                          v-if="$store.state.app.roleName !== 'viewer'"
                        >
                          <b-button
                            v-if="
                              (isExistDetailID && permissionSalOnly()) ||
                              $store.state.app.roleName === 'jetty man'
                            "
                            variant="gradient-primary"
                            class="mt-2"
                            block
                            :disabled="loadingRelRKA2"
                            @click="saveRKA2()"
                          >
                            <feather-icon
                              icon="CheckCircleIcon"
                              :style="{ cursor: 'pointer' }"
                              class="mr-50"
                              v-if="!loadingRelRKA2"
                            />
                            <b-spinner v-if="loadingRelRKA2" small></b-spinner>
                            <span class="align-middle">Simpan Realisasi</span>
                          </b-button>
                        </b-col>
                      </b-row>

                      <div class="divider my-2">
                        <div class="divider-text">
                          <h3>Realisasi Posisi Jetty</h3>
                        </div>
                      </div>

                      <!-- REALISASI POSISI JETTY -->
                      <b-alert variant="primary" show class="mb-0">
                        <div class="alert-body">
                          Histori Kapal Awal Sandar / Pindah Jetty
                          <strong>Diisi Admin SAL / Jetty Man</strong>
                        </div>
                      </b-alert>

                      <br />
                      <NestedIO
                        uri="realisasi_posisi_kapal"
                        :key="form.table_jetty_position"
                        :model-id="form.form_tug_boat_id"
                        model="form_tug_boat"
                        :posisi="jetty_positions"
                        :posisi-baris="jetty_baris_positions"
                        :disable-action="
                          !!this.form.tug_boat.tug_sandar_start_time
                        "
                        :payload="{
                          model_id: null,
                          model: null,
                          is_first: null,
                          posisi_awal: null,
                          posisi_awal_baris: null,
                          posisi_skrg: null,
                          posisi_skrg_baris: null,
                          reason: null,
                          waktu_pindah: null
                        }"
                        :column-repeat="[
                          {
                            fe_id: 1
                          }
                        ]"
                        :d-t-o="[]"
                        :resolve-fetch="
                          (response) => response.data.realisasi_posisi_kapals
                        "
                        buttonOkTitle=" Simpan Realisasi"
                        @sentNotification="sentNotification"
                        @alreadyRealisasi="alreadyRealisasi"
                      />
                    </div>
                    <div class="mb-1" />
                  </b-tab>
                </b-tabs>
                <!-- </validation-observer> -->
              </b-tab>
              <b-tab lazy>
                <template #title>
                  <feather-icon icon="WatchIcon" size="20" />
                  <strong>
                    <span style="font-size: 1.5em"> IDLE TIME </span>
                    <br />
                    <small>Tabs Menu</small>
                  </strong>
                </template>

                <!-- <validation-observer ref="refIddleSAL" tag="form"> -->
                <div class="tableidle">
                  <h2>
                    Total Iddle Time :
                    <span style="color: grey"
                      ><strong>{{ totalIdleTime }}</strong></span
                    >
                  </h2>
                  <YoTable
                    :api-endpoint="idle.apiEndpoint"
                    title-tbl=""
                    sub-title-tbl=""
                    :fields="idle.fields.filter((field) => field.gone === true)"
                    :new-data-template="idleNewDataTemplate"
                    :resolve-fetch-response="idleResolveFetchResponse"
                    :resolve-fetch-url="idleResolveFetchUrl"
                    bottom-label=""
                    bottom-icon="TruckIcon"
                    info-page-ability="Manage (All / JettyMan / Security)"
                    placeholder-search="Idle Time"
                  />
                </div>
                <!-- </validation-observer> -->
              </b-tab>
            </b-tabs>

            <div class="mb-1" />
          </tab-content>
          <!-- ADMIN SAL -->
          <tab-content
            v-if="permissionSalOnly()"
            :title="wizard.second.step2.title"
            :icon="wizard.second.step2.icon"
          >
            <validation-observer ref="adminSalRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h5 class="mb-0">{{ wizard.second.step2.content.title }}</h5>
                  <small class="text-muted">
                    <strong>{{ wizard.second.step2.content.subtitle }}.</strong>
                  </small>
                </b-col>
              </b-row>
              <!--
              1. JIKA BARGE TAMPIL TABS
              2. ELSE TAMPIL NO TABS ( SINGLE )
             -->
              <span v-if="form.schedule.jenis_kapal === 'Barge'">
                <b-tabs class="tabs-custom" content-class="" align="center">
                  <b-tab lazy>
                    <template #title>
                      <feather-icon icon="AnchorIcon" size="20" />
                      <strong>
                        <span
                          style="font-size: 1.5em"
                          v-text="form.dm_river_boat_hm.boat_name"
                        >
                        </span>
                        <br />
                        <small>Main Boat</small>
                      </strong>
                    </template>

                    <b-row>
                      <b-col md="4" cols="12">
                        <b-form-group
                          label="[1] Status Kapal Sesuai INAPORTNET *"
                          label-for="vi-schedule-status_kapal_mb"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Status Kapal Sesuai INAPORTNET Main Boat"
                            vid="Status Kapal Sesuai INAPORTNET Main Boat"
                            rules="required"
                          >
                            <vue-autosuggest
                              id="vi-schedule-status_kapal_mb"
                              v-model="form.schedule.status_kapal"
                              v-uppercase
                              :suggestions="[{ data: status_kapals }]"
                              :get-suggestion-value="getSuggestionValueManual"
                              :state="errors.length > 0 ? false : null"
                              :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: `Pilih / Ketik Manual`,
                                disabled: permissionTenantAndSAL()
                              }"
                              @input="getSuggestionValueManualInput($event)"
                            >
                              <template slot-scope="{ suggestion }">
                                <div class="d-flex align-items-center">
                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      <strong
                                        ><span>
                                          {{
                                            suggestion.item.status_kapals
                                          }}</span
                                        ></strong
                                      >
                                    </b-card-text>
                                  </div>
                                </div>
                              </template>
                            </vue-autosuggest>
                            <small class="text-danger">{{ errors[0] }} </small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="4"
                        cols="12"
                      >
                        <b-form-group
                          label="[2] Nomor Pindah"
                          label-for="vi-schedule-no_Pindah"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor Pindah"
                            vid="Nomor Pindah"
                            :rules="{
                              required_if: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_Pindah"
                              v-model="form.schedule.no_pindah"
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('Hulu Migas', '')) ||
                                !$can('SAL', '')
                              "
                              :placeholder="
                                (
                                  $router.currentRoute.params.id
                                    ? !$can('SAL', '') && !$can('manage', 'all')
                                    : null
                                )
                                  ? 'Diisi Admin SAL'
                                  : 'Nomor Pindah'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="4"
                        cols="12"
                      >
                        <b-form-group
                          label="[3] Nomor RPKRO"
                          label-for="vi-schedule-no_rpkro"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor RPKRO"
                            vid="Nomor RPKRO"
                            :rules="{
                              required_if: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_rpkro"
                              v-model="form.schedule.no_rpkro"
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('Hulu Migas', '')) ||
                                !$can('SAL', '')
                              "
                              :placeholder="
                                (
                                  $router.currentRoute.params.id
                                    ? !$can('SAL', '') && !$can('manage', 'all')
                                    : null
                                )
                                  ? 'Diisi Admin SAL'
                                  : 'Nomor RPKRO'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="4"
                        cols="12"
                      >
                        <b-form-group
                          label="[4] Nomor RKBM"
                          label-for="vi-schedule-no_rkbm"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor RKBM"
                            vid="Nomor RKBM"
                            :rules="{
                              required_if: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_rkbm"
                              v-model="form.schedule.no_rkbm"
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('Hulu Migas', '')) ||
                                !$can('SAL', '')
                              "
                              :placeholder="
                                (
                                  $router.currentRoute.params.id
                                    ? !$can('SAL', '') && !$can('manage', 'all')
                                    : null
                                )
                                  ? 'Diisi Admin SAL'
                                  : 'Nomor RKBM'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id
                        "
                        md="4"
                        cols="12"
                      >
                        <b-form-group
                          label="[5] Nomor PBMBB"
                          label-for="vi-schedule-no_pbmbb"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor pbmbb"
                            vid="Nomor pbmbb"
                            :rules="{
                              required_if: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_pbmbb"
                              v-model="form.schedule.no_pbmbb"
                              :state="errors.length > 0 ? false : null"
                              :readonly="
                                (!$can('manage', 'all') &&
                                  $can('Hulu Migas', '')) ||
                                !$can('SAL', '')
                              "
                              :placeholder="
                                (
                                  $router.currentRoute.params.id
                                    ? !$can('SAL', '') && !$can('manage', 'all')
                                    : null
                                )
                                  ? 'Diisi Admin SAL'
                                  : 'Nomor PBMBB'
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4" cols="12">
                        <b-form-group
                          label="[6] Nomor SPOG ( kedatangan )"
                          label-for="vi-schedule-no_spog"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor SPOG"
                            vid="Nomor SPOG"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_spog"
                              v-model="form.schedule.no_spog"
                              :state="errors.length > 0 ? false : null"
                              :readonly="permissionTenantAndSAL()"
                              placeholder="Cth: SPOG.01"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" cols="12">
                        <b-form-group
                          label="[7] Nomor SPOG ( keberangkatan )"
                          label-for="vi-schedule-no_spog_brk"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor SPOG ( keberangkatan )"
                            vid="Nomor SPOG ( keberangkatan )"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_spog_brk"
                              v-model="form.schedule.no_spog_brk"
                              :state="errors.length > 0 ? false : null"
                              :readonly="permissionTenantAndSAL()"
                              placeholder="Cth: SPOG.01"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        class="text-right"
                        md="12"
                        cols="12"
                        v-if="
                          $store.state.app.roleName !== 'viewer' &&
                          $store.state.app.roleName !== 'jetty man'
                        "
                      >
                        <b-button
                          v-if="isExistDetailID && permissionSalOnly()"
                          variant="gradient-primary"
                          class="mt-2"
                          block
                          :disabled="loadingRelRKA3"
                          @click="validationModifiedData()"
                        >
                          <!-- TODO : november -->
                          <feather-icon
                            v-if="!loadingRelRKA3"
                            icon="CheckCircleIcon"
                            :style="{ cursor: 'pointer' }"
                            class="mr-50"
                          />
                          <b-spinner v-if="loadingRelRKA3" small></b-spinner>
                          <span class="align-middle"
                            >( 1 ) Simpan Realisasi</span
                          >
                          <!-- ADMIN SAL TAB -->
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab lazy>
                    <template #title>
                      <feather-icon icon="AnchorIcon" size="20" />
                      <strong>
                        <span
                          style="font-size: 1.5em"
                          v-text="form.tug_boat.name_tug_boat"
                        ></span>
                        <br />
                        <small>Tug Boat</small>
                      </strong>
                    </template>
                    <b-alert
                      variant="danger"
                      show
                      class="mb-0"
                      v-if="!form.form_tug_boat_id"
                    >
                      <div class="alert-body">
                        Jettyman terlebih dahulu melakukan ganti atau pilih
                        <strong>Tug Boat</strong> untuk keberangkatan.
                      </div>
                    </b-alert>
                    <br v-if="!form.form_tug_boat_id" />
                    <validation-observer
                      ref="validationModifiedDataTB"
                      tag="form"
                    >
                      <b-row v-if="form.form_tug_boat_id">
                        <b-col md="3" cols="12">
                          <b-form-group
                            label="[1] Status Kapal Sesuai INAPORTNET *"
                            label-for="vi-schedule-status_kapal_tb"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="[1] Status Kapal Sesuai INAPORTNET *"
                              vid="[1] Status Kapal Sesuai INAPORTNET *"
                              rules="required"
                            >
                              <vue-autosuggest
                                id="vi-schedule-status_kapal_tb"
                                v-model="form.schedule.status_kapal_tb"
                                v-uppercase
                                :suggestions="[{ data: status_kapals }]"
                                :get-suggestion-value="
                                  getSuggestionValueManualTB
                                "
                                :state="errors.length > 0 ? false : null"
                                :input-props="{
                                  id: 'autosuggest__input',
                                  class: 'form-control',
                                  placeholder: `Pilih / Ketik Manual`,
                                  disabled: permissionTenantAndSAL()
                                }"
                                @input="getSuggestionValueManualInputTB($event)"
                              >
                                <template slot-scope="{ suggestion }">
                                  <div class="d-flex align-items-center">
                                    <div class="detail ml-50">
                                      <b-card-text class="mb-0">
                                        <strong
                                          ><span>
                                            {{
                                              suggestion.item.status_kapals
                                            }}</span
                                          ></strong
                                        >
                                      </b-card-text>
                                    </div>
                                  </div>
                                </template>
                              </vue-autosuggest>
                              <small class="text-danger"
                                >{{ errors[0] }}
                              </small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="
                            $can('manage', 'all') ||
                            $can('SAL', '') ||
                            $router.currentRoute.params.id
                          "
                          md="3"
                          cols="12"
                        >
                          <b-form-group
                            label="[2] Nomor Pindah"
                            label-for="vi-schedule-no_Pindahtb"
                          >
                            <!-- regex: /^[0-9]+$/ -->
                            <validation-provider
                              #default="{ errors }"
                              name="Nomor Pindah"
                              vid="Nomor Pindah"
                              :rules="{
                                required_if: false
                              }"
                            >
                              <b-form-input
                                id="vi-schedule-no_Pindahtb"
                                v-model="form.schedule.no_pindah_tb"
                                :state="errors.length > 0 ? false : null"
                                :readonly="
                                  (!$can('manage', 'all') &&
                                    $can('Hulu Migas', '')) ||
                                  !$can('SAL', '')
                                "
                                :placeholder="
                                  (
                                    $router.currentRoute.params.id
                                      ? !$can('SAL', '') &&
                                        !$can('manage', 'all')
                                      : null
                                  )
                                    ? 'Diisi Admin SAL'
                                    : 'Nomor Pindah'
                                "
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="
                            $can('manage', 'all') ||
                            $can('SAL', '') ||
                            $router.currentRoute.params.id
                          "
                          md="3"
                          cols="12"
                        >
                          <b-form-group
                            label="[3] Nomor RPKRO"
                            label-for="vi-schedule-no_rpkro_tb"
                          >
                            <!-- regex: /^[0-9]+$/ -->
                            <validation-provider
                              #default="{ errors }"
                              name="Nomor RPKRO"
                              vid="Nomor RPKRO"
                              :rules="{
                                required_if: false
                              }"
                            >
                              <b-form-input
                                id="vi-schedule-no_rpkro_tb"
                                v-model="form.schedule.no_rpkro_tb"
                                :state="errors.length > 0 ? false : null"
                                :readonly="
                                  (!$can('manage', 'all') &&
                                    $can('Hulu Migas', '')) ||
                                  !$can('SAL', '')
                                "
                                :placeholder="
                                  (
                                    $router.currentRoute.params.id
                                      ? !$can('SAL', '') &&
                                        !$can('manage', 'all')
                                      : null
                                  )
                                    ? 'Diisi Admin SAL'
                                    : 'Nomor RPKRO'
                                "
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="3" cols="12">
                          <b-form-group
                            label="[4] Nomor SPOG ( kedatangan )"
                            label-for="vi-schedule-no_spog_tb"
                          >
                            <!-- regex: /^[0-9]+$/ -->
                            <validation-provider
                              #default="{ errors }"
                              name="Nomor SPOG"
                              vid="Nomor SPOG"
                              :rules="{
                                required: false
                              }"
                            >
                              <b-form-input
                                id="vi-schedule-no_spog_tb"
                                v-model="form.schedule.no_spog_tb"
                                :state="errors.length > 0 ? false : null"
                                :readonly="permissionTenantAndSAL()"
                                placeholder="Nomor SPOG ( Kedatangan )"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="12" cols="12">
                          <b-form-group
                            label="[5] Nomor SPOG ( keberangkatan )"
                            label-for="vi-schedule-no_spog_tb_brk"
                          >
                            <!-- regex: /^[0-9]+$/ -->
                            <validation-provider
                              #default="{ errors }"
                              name="Nomor SPOG ( keberangkatan )"
                              vid="Nomor SPOG ( keberangkatan )"
                              :rules="{
                                required: false
                              }"
                            >
                              <b-form-input
                                id="vi-schedule-no_spog_tb_brk"
                                v-model="form.schedule.no_spog_tb_brk"
                                :state="errors.length > 0 ? false : null"
                                :readonly="permissionTenantAndSAL()"
                                placeholder="Nomor SPOG ( Keberangkatan )"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col
                          class="text-right"
                          md="12"
                          cols="12"
                          v-if="
                            $store.state.app.roleName !== 'viewer' &&
                            $store.state.app.roleName !== 'jetty man'
                          "
                        >
                          <b-button
                            v-if="isExistDetailID && permissionSalOnly()"
                            variant="gradient-primary"
                            class="mt-2"
                            block
                            :disabled="loadingRelRKA4"
                            @click="validationModifiedDataTB()"
                          >
                            <!-- TODO : november -->
                            <feather-icon
                              v-if="!loadingRelRKA4"
                              icon="CheckCircleIcon"
                              :style="{ cursor: 'pointer' }"
                              class="mr-50"
                            />
                            <b-spinner v-if="loadingRelRKA4" small></b-spinner>
                            <span class="align-middle"
                              >( 2 ) Simpan Realisasi
                            </span>
                            <!-- ADMIN SAL TAB -->
                          </b-button>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <!-- TODO: HIDE UPLOAD SPOG -->
                    <!-- <hr>
                  <b-row>
                    <b-col cols="12">
                      <h5 class="mb-0">Document Realisasi SPOG</h5>
                      <small class="text-muted">
                        <strong>Diisi oleh Admin SAL atau ( Penerbit form ).</strong>
                      </small>
                      <BFormFile
                        id="vi-schedule-no_spog_file"
                        v-model="form.schedule.no_spog_file_tb"
                        placeholder="Choose a file SPOG or drop it here..."
                        drop-placeholder="Drop file here..."
                        no-drop
                        accept=".xls, .xlsx, .pdf, .jpeg, .jpg, .png"
                      />

                      <app-timeline-item
                        v-for="(item, index) in no_spog_file_tb"
                        :key="index"
                      >
                        <div
                          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                        ></div>
                        <p>
                          <b-img
                            :src="require('@/assets/images/icons/xls.png')"
                            height="auto"
                            width="20"
                            class="mr-1"
                          />
                          <span class="align-bottom">
                            <a
                              :href="`${BACKEND_URL}${item.path}/${item.unique_name}`"
                              target="_blank"
                              style="color: grey"
                              rel="noopener noreferrer"
                              >{{ item.original_name }}</a
                            >
                          </span>
                        </p>
                        <small class="text-muted"
                          >Size {{ item.size }}
                          <strong>[ click name file, to preview.]</strong></small
                        >
                      </app-timeline-item>

                      <b-button
                        v-if="!permissionTenant() && isExistDetailID"
                        variant="gradient-primary"
                        class="mt-2"
                        block
                        :disabled="loadingRealisasiKegiatan || !editAble(user_id)"
                        @click="saveRealisasiKegiatan('SPOG')"
                      >
                        <feather-icon
                          v-if="!loadingRealisasiKegiatan"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner v-if="loadingRealisasiKegiatan" small></b-spinner>
                        <span class="align-middle"
                          >( 3 ) Upload Document SPOG</span
                        >
                      </b-button>
                  </b-col>
                  </b-row> -->
                  </b-tab>
                </b-tabs>
              </span>
              <span v-else>
                <b-row>
                  <b-col md="4" cols="12">
                    <b-form-group
                      label="[1] Status Kapal Sesuai INAPORTNET *"
                      label-for="vi-schedule-status_kapal"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Status Kapal Sesuai INAPORTNET"
                        vid="Status Kapal Sesuai INAPORTNET"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-schedule-status_kapal"
                          v-model="form.schedule.status_kapal"
                          v-uppercase
                          :suggestions="[{ data: status_kapals }]"
                          :get-suggestion-value="getSuggestionValueManual"
                          :state="errors.length > 0 ? false : null"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control',
                            placeholder: `Pilih / Ketik Manual`,
                            disabled: permissionTenantAndSAL()
                          }"
                          @input="getSuggestionValueManualInput($event)"
                        >
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong
                                    ><span>
                                      {{ suggestion.item.status_kapals }}</span
                                    ></strong
                                  >
                                </b-card-text>
                              </div>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }} </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="
                      $can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id
                    "
                    md="4"
                    cols="12"
                  >
                    <b-form-group
                      label="[2] Nomor Pindah"
                      label-for="vi-schedule-no_Pindah"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor Pindah"
                        vid="Nomor Pindah"
                        :rules="{
                          required_if: false
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-no_Pindah"
                          v-model="form.schedule.no_pindah"
                          :state="errors.length > 0 ? false : null"
                          :readonly="
                            (!$can('manage', 'all') &&
                              $can('Hulu Migas', '')) ||
                            !$can('SAL', '')
                          "
                          :placeholder="
                            (
                              $router.currentRoute.params.id
                                ? !$can('SAL', '') && !$can('manage', 'all')
                                : null
                            )
                              ? 'Diisi Admin SAL'
                              : 'Nomor Pindah'
                          "
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="
                      $can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id
                    "
                    md="4"
                    cols="12"
                  >
                    <b-form-group
                      label="[3] Nomor RPKRO"
                      label-for="vi-schedule-no_rpkro"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor RPKRO"
                        vid="Nomor RPKRO"
                        :rules="{
                          required_if: false
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-no_rpkro"
                          v-model="form.schedule.no_rpkro"
                          :state="errors.length > 0 ? false : null"
                          :readonly="
                            (!$can('manage', 'all') &&
                              $can('Hulu Migas', '')) ||
                            !$can('SAL', '')
                          "
                          :placeholder="
                            (
                              $router.currentRoute.params.id
                                ? !$can('SAL', '') && !$can('manage', 'all')
                                : null
                            )
                              ? 'Diisi Admin SAL'
                              : 'Nomor RPKRO'
                          "
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="
                      $can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id
                    "
                    md="4"
                    cols="12"
                  >
                    <b-form-group
                      label="[4] Nomor RKBM"
                      label-for="vi-schedule-no_rkbm"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor RKBM"
                        vid="Nomor RKBM"
                        :rules="{
                          required_if: false
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-no_rkbm"
                          v-model="form.schedule.no_rkbm"
                          :state="errors.length > 0 ? false : null"
                          :readonly="
                            (!$can('manage', 'all') &&
                              $can('Hulu Migas', '')) ||
                            !$can('SAL', '')
                          "
                          :placeholder="
                            (
                              $router.currentRoute.params.id
                                ? !$can('SAL', '') && !$can('manage', 'all')
                                : null
                            )
                              ? 'Diisi Admin SAL'
                              : 'Nomor RKBM'
                          "
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="
                      $can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id
                    "
                    md="4"
                    cols="12"
                  >
                    <b-form-group
                      label="[5] Nomor PBMBB"
                      label-for="vi-schedule-no_pbmbb"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor pbmbb"
                        vid="Nomor pbmbb"
                        :rules="{
                          required_if: false
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-no_pbmbb"
                          v-model="form.schedule.no_pbmbb"
                          :state="errors.length > 0 ? false : null"
                          :readonly="
                            (!$can('manage', 'all') &&
                              $can('Hulu Migas', '')) ||
                            !$can('SAL', '')
                          "
                          :placeholder="
                            (
                              $router.currentRoute.params.id
                                ? !$can('SAL', '') && !$can('manage', 'all')
                                : null
                            )
                              ? 'Diisi Admin SAL'
                              : 'Nomor PBMBB'
                          "
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="4" cols="12">
                    <b-form-group
                      label="[6] Nomor SPOG ( kedatangan )"
                      label-for="vi-schedule-no_spog"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor SPOG"
                        vid="Nomor SPOG"
                        :rules="{
                          required: false
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-no_spog"
                          v-model="form.schedule.no_spog"
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionTenantAndSAL()"
                          placeholder="Cth: SPOG.01"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" cols="12">
                    <b-form-group
                      label="[7] Nomor SPOG ( keberangkatan )"
                      label-for="vi-schedule-no_spog_brk"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name="Nomor SPOG ( keberangkatan )"
                        vid="Nomor SPOG ( keberangkatan )"
                        :rules="{
                          required: false
                        }"
                      >
                        <b-form-input
                          id="vi-schedule-no_spog_brk"
                          v-model="form.schedule.no_spog_brk"
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionTenantAndSAL()"
                          placeholder="Cth: SPOG.01"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col class="text-right" md="12" cols="12">
                    <b-button
                      v-if="isExistDetailID && permissionSalOnly()"
                      variant="gradient-primary"
                      class="mt-2"
                      block
                      :disabled="loadingRelRKA3"
                      @click="validationModifiedData()"
                    >
                      <!-- TODO : november -->
                      <feather-icon
                        v-if="!loadingRelRKA3"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingRelRKA3" small></b-spinner>
                      <span class="align-middle">Simpan Realisasi </span>
                      <!-- ADMIN SAL [TAB] -->
                    </b-button>
                  </b-col>
                </b-row>
              </span>
              <div class="mb-1" />
            </validation-observer>
          </tab-content>

          <template slot-scope="props" slot="footer">
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0 && !props.isLastStep"
                @click.native="props.prevTab()"
                :style="props.fillButtonStyle"
                >Previous</wizard-button
              >
            </div>
            <div class="wizard-footer-right">
              <wizard-button
                v-if="!props.isLastStep"
                @click.native="props.nextTab()"
                class="wizard-footer-right"
                :style="props.fillButtonStyle"
                >Next</wizard-button
              >

              <wizard-button
                v-else
                @click.native="formSubmitted"
                class="wizard-footer-right finish-button"
                :style="props.fillButtonStyle"
                :disabled="inProgressTerbitkanForm"
              >
                <b-spinner v-if="inProgressTerbitkanForm" small></b-spinner>
                <feather-icon v-else icon="CalendarIcon" class="mr-50" />
                {{
                  props.isLastStep
                    ? 'Terbitkan Form Perencanaan Kedatangan Kapal'
                    : 'Next'
                }}
              </wizard-button>
            </div>
          </template>
        </form-wizard>
      </div>
    </validation-observer>
    <!-- <div class="text-center" v-if="!$router.currentRoute.params.id">
      <div class="float-right">
        <b-button
          variant="gradient-primary"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="inProgressTerbitkanForm"
          @click="validationCreateData()"
        >
          <b-spinner v-if="inProgressTerbitkanForm" small />
          <feather-icon
            v-if="!inProgressTerbitkanForm"
            icon="DatabaseIcon"
            :style="{ cursor: 'pointer' }"
            class="mr-50"
          />
          <span
            class="align-middle"
            v-text="'Terbitkan Form Perencanaan Kedaatangan Kapal'"
          />
        </b-button>
      </div>
    </div> -->
    <div
      class="text-center"
      v-if="$router.currentRoute.params.id !== undefined"
    >
      <b-button
        variant="success"
        :to="{
          name: 'dashboard-monitoring-rrv-hulu-migas-summary',
          params: {
            id: form.form_chain_hm_id,
            api: 'form_chain_hm',
            previous_link: $router.currentRoute
          }
        }"
      >
        <feather-icon icon="EyeIcon" />
        Summary
      </b-button>
      <hr />
      <b-badge href="#" pill class="mr-1 mb-1 text-center" variant="secondary">
        <strong
          ><span v-text="`Form Perencanaan Created BY ${form.created_by}`"
        /></strong>
        <br />
      </b-badge>
      <br />
      Created AT {{ formatDateTime(form.schedule.created_at) }} - Last Updated
      AT {{ formatDateTime(form.schedule.updated_at) }}
    </div>

    <FormModal
      :key="`ModalVerification${keyModalVerification}`"
      uri="/verification_hm"
      title="Form Verification"
      :is-modal-active.sync="dataModalVerification"
      :payload="dataPayloadModalVerification"
      action="is_add"
      :column="columnModalVerification"
      @closeModal="methodModalVerification"
    />
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VDropzone from 'vue2-dropzone'
import {
  FormWizard,
  TabContent,
  WizardStep,
  WizardButton
} from 'vue-form-wizard'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BCardText,
  BBreadcrumb
} from 'bootstrap-vue'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { v4 as uuidv4 } from 'uuid'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Cleave from 'vue-cleave-component'

import { nextTick } from 'process'
import { forEach } from 'postcss-rtl/lib/affected-props'
import FormModal from '@views/pages/components/modal/FormModal.vue'
import NestedIO from '@views/pages/components/NestedIO.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import TableJetty from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-jetty-position/index.vue'
import TablePipeLine from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-kegiatan/TablePipeLine.vue'
import TableMH from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-kegiatan/TableMH.vue'
import TablePipeLineGood from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-kegiatan/TablePipeLineGood.vue'
import TableManualHandlingGood from '@views/pages/monitoring/jalur-sungai/hulu-migas/wizard/table-kegiatan/TableManualHandlingGood.vue'
import DropZoneUploadV1 from '@views/components/input/upload/DropZoneUploadV1.vue'
import YoTable from '@/views/pages/components/CompTableT1T2.vue'

export default {
  components: {
    YoTable,
    FormWizard,
    TabContent,
    WizardStep,
    WizardButton,
    ToastificationContent,
    NestedIO,
    DropZoneUploadV1,
    FormModal,
    BBreadcrumb,
    VDropzone,
    BCardActions,
    BCardText,
    BTabs,
    BTab,
    AppTimeline,
    AppTimelineItem,
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    VueAutosuggest,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave,
    // TablePipeLine,
    TableJetty,
    TablePipeLine,
    TableMH,
    TablePipeLineGood,
    TableManualHandlingGood
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      /* IDDLE DATA */
      totalIdleTime: '-',
      idle: {
        apiEndpoint:
          'riv_iddle_time' /* TODO created passing the required params */,
        titleTbl: '-',
        fields: [
          {
            key: 'start_time',
            label: 'Waktu Mulai Idle',
            sortable: 'date',
            placeholder: '',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - HH:mm',
              disabled: false
            },
            searchModel: 'start_time',
            search: {
              type: 'date'
            },
            gone: true
          },
          {
            key: 'end_time',
            label: 'Waktu Selesai Idle',
            sortable: 'date',
            placeholder: '',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - HH:mm',
              disabled: false
            },
            searchModel: 'end_time',
            search: {
              type: 'date'
            },
            gone: true
          },
          {
            key: 'user.full_name',
            label: 'Created BY',
            sortable: true,
            placeholder: 'Otomatis terisi',
            input: { type: 'input-noarea', disabled: true },
            searchModel: 'user.full_name',
            search: {
              type: 'text'
            },
            gone: true
          },
          {
            key: 'created_at',
            label: 'Created AT',
            sortable: true,
            placeholder: 'Otomatis terisi',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - hh:mm A',
              disabled: true
            },
            searchModel: 'created_at',
            search: {
              type: 'date'
            },
            gone: true
          },
          {
            key: 'updated_at',
            label: 'Updated AT',
            sortable: true,
            placeholder: 'Otomatis terisi',
            input: {
              type: 'date',
              format: 'D MMMM YYYY - hh:mm A',
              disabled: true
            },
            searchModel: 'updated_at',
            search: {
              type: 'date'
            },
            gone: true
          }
        ]
      },
      loadingButton: false,
      temp: null,
      temp1: null,
      loadingSaveTenant: false,
      user_id: null,
      wizardKey: 1,
      no_ijin_bunker_file: [],
      no_spog_file_tb: [],
      loadingRealisasiKegiatan: false /* done */,
      wizard: {
        first: {
          step1: {
            title: 'Main Boat',
            icon: 'feather icon-anchor',
            content: {
              title: 'Main Boat',
              subtitle: 'Data Master Kapal'
            }
          },
          step2: {
            title: 'Tug Boat',
            icon: 'feather icon-anchor',
            content: {
              title: 'Tug Boat',
              subtitle: 'Menarik Main Boat'
            }
          },
          step3: {
            title: 'Schedule',
            icon: 'feather icon-calendar',
            content: {
              title: 'Schedule',
              subtitle: 'Schedule Kapal Rencana Sandar'
            }
          },
          step4: {
            title: 'Upload File',
            icon: 'feather icon-hard-drive',
            content: {
              title:
                'Upload File (Manifiest / DO / Material Request / Request Order / BL / dll)',
              subtitle: 'max size 10 MB ( xls, xlsx, pdf , jpeg , jpg , png )'
            }
          },
          step5: {
            title: 'Kegiatan',
            icon: 'feather icon-clock',
            content: {
              title: 'Kegiatan',
              subtitle:
                'Kegiatan & Realisasi, jika tidak mengisi akan di anggap tambat, ketika data terisi dianggap sesuai rencana berkegiatan'
            }
          }
          // step6: {
          //   title: 'Summary',
          //   icon: 'feather icon-airplay',
          //   content: {
          //     title: 'Summary',
          //     subtitle: 'Overview keseluruhan data'
          //   }
          // }
        },
        second: {
          step1: {
            title: 'Jetty Man_',
            icon: 'feather icon-map-pin',
            content: {
              title: 'Jetty Man',
              subtitle:
                'Data yang diisi oleh jetty man melalui mobile apps ( android ) '
            }
          },
          step2: {
            title: 'Admin SAL',
            icon: 'feather icon-unlock',
            content: {
              title: 'Admin SAL',
              subtitle: 'Document Realisasi SAL'
            }
          }
        }
      },

      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}`,
      isHaveFile: [],
      /* loading button on click */
      loadingDupRev: false,
      loadingDupRevTB: false,
      loadingChange: false,
      loadingRelRKA1: false,
      loadingRelRKA2: false,
      loadingRelRKA3: false,
      loadingRelRKA4: false,
      loadingCompletedBySAL: false,
      loadingFileStorage: false,
      loadingTotalKegiatan: false,
      loadingRealisasiCrew: false /* done */,

      inProgressTerbitkanForm: false,
      /* Modal Verification */
      keyModalVerification: 1,
      dataModalVerification: false,
      dataPayloadModalVerification: {
        manual_message: '',
        /* default payload */
        form_chain_hm_id: null,
        flag_verified_ok: 'Completed By SAL',
        flag_verified_warn: 'Warning'
      },
      columnModalVerification: [
        {
          label: 'Message Verification Warning',
          vModel: 'manual_message',
          config: {
            md: 12
          }
        }
      ],
      /* End Of Modal Verification */
      isVerification: false /* sal verification */,
      isExistDetailID: !!this.$router.currentRoute.params.id /* Boolean */,
      dataID:
        this.$router.currentRoute.params
          .id /* Integer */ /* this a form hulu migas id */,
      detail_form_tug_boat_id:
        this.$router.currentRoute.params
          .detail_form_tug_boat_id /* Integer */ /* this a form tug boat id */,

      isLoading: {
        terbitkanForm: false /* create form */,
        saveDMK: false /* data master kapal */,
        simpanFormData: false /* perubahan pada form data */,
        simpanRKA1: false /* realisasi kapal main kapal */,
        simpanRKA2: false /* realisasi kapal tug boat */,
        saveDMT: false /* data master kapal tug boat */,
        verification: false
      },
      mapStatePayloadDMK: {
        UPDATE_WITH_NEW_DATA: 'UPDATE_WITH_NEW_DATA' /* duplicate */,
        UPDATE_ON_EXIST_DATA: 'UPDATE_ON_EXIST_DATA' /* revisi */,
        CHANGE_ON_EXIST_DATA: 'CHANGE_ON_EXIST_DATA' /* change */
      },
      mapMsgPayloadDMK: {
        UPDATE_WITH_NEW_DATA:
          'Duplicate data and Create as a new data' /* duplicate */,
        UPDATE_ON_EXIST_DATA: 'Revisi data kapal' /* revisi */,
        CHANGE_ON_EXIST_DATA: 'Berhasil mengubah/mengganti kapal'
      },

      statePayloadDMKMaster: null /* the value from this.mapStatePayloadDMK */,
      statePayloadDMK: null /* the value from this.mapStatePayloadDMK */,
      statePayloadDMKTBMaster: null /* TUG BOAT */,
      statePayloadDMKTB: null /* TUG BOAT */,

      stateInputDMKMaster: true,
      stateInputDMK: true /* main boat disable auto fill value , enable/true when this.statePayloadDMK is not null */,
      stateInputDMKTBMaster: true /* TUG BOAT */,
      stateInputDMKTB: true /* TUG BOAT */,

      formRender: 1,
      successTitles: null,
      successMessages: null,
      successMode: null,

      errorPath: null,
      errorCatch: null,
      errorMatchValue: null,
      errorValue: null,

      temp_kegiatan: null,
      status_kapals: [
        {
          status_kapals: 'PT. SAL'
        },
        {
          status_kapals: 'ZONA 3A'
        },
        {
          status_kapals: 'ZONA 2'
        }
      ],

      total_realisasi_kegiatan: '-',
      kegiatan: [],
      pemilik: [],
      jetty_positions: [],
      jetty_baris_positions: [
        {
          value: 'null',
          text: 'Posisi Baris'
        },
        {
          value: 'Baris 1',
          text: 'Baris 1'
        },
        {
          value: 'Baris 2',
          text: 'Baris 2'
        },
        {
          value: 'Baris 3',
          text: 'Baris 3'
        }
      ],
      jenis_kapals: [
        {
          value: 'null',
          text: 'Pilih Jenis Kapal'
        },
        {
          value: 'LCT / KM / MT / SPOB / Sea Truck',
          text: 'LCT / KM / MT / SPOB / Sea Truck'
        },
        {
          value: 'Barge',
          text: 'Barge'
        },
        {
          value: 'Tug Boat',
          text: 'Tug Boat'
        }
      ],
      formTitle: '',
      domain: process.env.VUE_APP_URL,
      userData: JSON.parse(localStorage.getItem('userData')),
      groupName: JSON.parse(localStorage.getItem('userData')).group?.name ?? '',
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altInput: true,
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      options: {
        creditCard: {
          creditCard: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US'
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's']
        },
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        },
        no_pol: {
          numeral: true,
          blocks: [4, 3, 3],
          uppercase: true
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          phone: true,
          phoneRegionCode: 'ID'
        }
      },
      payload: [],
      form_realisasi: {
        rka1: null,
        rka2: null
      },
      form: {
        optional: {
          nama_tb: null,
          isLoading: false
        },
        verification_hm: [],
        flag_verified: null,
        /* for form chain hm */
        form_chain_hm_id: null,
        form_tug_boat_id: null,
        form_hulu_migas_id: null,

        uri_chain_master: 'form_chain_hm',
        uri_chain_main:
          '' /* already override by form hulu migas in schedule payload */,
        uri_chain_second: 'form_tug_boat',
        /* for form chain hm */

        isSuccessTugBoat: false,
        isTugBoatOnly: false,
        table_mh: 0,
        table_pipe_line: 0,
        table_jetty_position: 0,

        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,
        currentFieldHaveChildren: null,

        // isAgenAndTrans: false,
        // showIsAgenAndTrans: false,
        // temp_transportir_id: '',

        // group: '',
        // pemilik: '',
        created_by: '',
        form_hulu_miga: [],
        dm_river_boat_hm: {
          /* data master kapal */ stateRevisi: null,
          updateID: null,
          isEverRun: false,
          uri: 'dm_river_boat_hm/',
          isNew: true,
          boat_name: '',
          gt: null,
          loa: null,
          captain_name: '',
          captain_telp: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_river_boat_hms: [],
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        dm_asal_kapal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_asal_kapal/',
          isNew: true,
          asal: '',
          dm_asal_kapals: []
        },
        tug_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'tug_boat/',
          isNew: true,
          no_pkk_tug_boat: '',
          name_tug_boat: '',
          tug_sandar_start_time: null,
          tug_sandar_end_time: null,
          tug_boats: [],
          gt_tug_boat: null,
          loa_tug_boat: null,
          captain_name_tug_boat: '',
          captain_telp_tug_boat: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        schedule: {
          count_crew_mb: null, // form tbbm
          count_crew_tb: null, // form tug boat
          no_ijin_bunker: null,
          total_bunker: null,
          no_pindah: null,
          no_pindah_tb: null,
          // api
          uri: 'form_hulu_migas/',
          uriStatus: 'form_hulu_migas/update/status/',
          uriOptional: 'form_chain_hm/optional/',
          status_kapal: '',
          rencana_position_jetty: '',
          position_jetty: '',
          rencana_baris_position_jetty: '',
          baris_position_jetty: '',

          jenis_kapal: null,
          tug_boat_id: null,

          // payload
          // kegiatan: '',
          // kegiatan_key: 100,
          rencana_sandar: null,
          keterangan: '',

          uuid: uuidv4(),
          no_pkk: null,
          no_pkk_tug_boat: null,
          keterangan_sea_truck: null
        }
      },
      dataOverview: [],
      agenOverview: [],
      transportirOverview: [],
      landVehicleOverview: [],
      // deliveryRoutesOverview: [],
      fetchLoading: false,
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      items: [
        {
          id: 1,
          selected: 'male',
          selected1: 'designer',
          prevHeight: 0
        }
      ],
      nextTodoId: 2,
      payloadNotification: {},

      // state suggestion
      dm_river_boat_hmState: 0,
      tug_boatState: 0,
      dm_shipping_agentState: 0,
      dm_asal_kapalState: 0,

      statusSuggestion: {
        0: 'Membuat Bank Data Baru',
        1: 'Menggunakan Bank Data',
        2: 'Menggunakan Bank Data & Membuat Bank Data Baru'
      },
      statusSuggestionColor: {
        0: 'primary',
        1: 'warning',
        2: 'success'
      },
      statusSuggestionChoose: {
        0: 'Pilih Data yang pernah dibuat [ reuse-data ]',
        1: 'cancel [ reuse-data ] by click "space" then "backspace"',
        2: 'Pilih Data yang pernah dibuat [ reuse-data ]'
      },
      // state loading
      dm_river_boat_hmStateLoading: false,
      tug_boatStateLoading: false,
      dm_shipping_agentStateLoading: false,
      dm_asal_kapalStateLoading: false,
      lastStep: false
    }
  },
  computed: {
    isLastStep() {
      return () => {
        return this.lastStep
      }
    },
    idleNewDataTemplate() {
      return {
        start_time: '',
        end_time: '',
        chain_model_id: this.form.form_chain_hm_id,
        chain_model: 'form_chain_hm'
      }
    },
    permissionTenant() {
      /* admin tenant => no  */
      /* tenant       => yes */
      /* sal          => yes */
      const showInput =
        !this.$can('manage', 'all') &&
        (this.$can('manage', 'special') ||
          this.$can('manage', 'qrcode') ||
          this.$can('manage', 'jetty'))
          ? true
          : this.isExistDetailID !== undefined
          ? false
          : null

      return () => showInput
    },
    currentlyKegiatanKapal() {
      return (props) => {
        let kegiatan = []

        let fhm_keg_pipelines = props.fhm_keg_pipelines
        let fhm_keg_manhs = props.fhm_keg_manhs

        if (fhm_keg_pipelines) {
          fhm_keg_pipelines.forEach((itm) => {
            const { kegiatan_hm } = itm
            kegiatan.push(kegiatan_hm.keg_name)
          })
        }
        if (fhm_keg_manhs) {
          fhm_keg_manhs.forEach((itm) => {
            const { kegiatan_hm } = itm
            kegiatan.push(kegiatan_hm.keg_name)
          })
        }
        if (fhm_keg_pipelines || fhm_keg_manhs) {
          /* eliminasi duplicate here */
          kegiatan = kegiatan.filter(function (value, index, array) {
            return array.indexOf(value) === index
          })
        }
        if (kegiatan.length === 0) {
          kegiatan = 'TAMBAT'
        }
        return kegiatan.toString().replace(/,/g, ', ') || kegiatan
      }
    },
    showFormAlert() {
      /* return boolean */
      // console.log('!!verification_hm', !!this.form.verification_hm)
      // console.log('!!this.form.schedule.no_spog', !!this.form.schedule.no_spog)
      if (!!this.form.verification_hm) {
        return () => true
      } else {
        return () => false
      }
    },
    formStatusVariant() {
      return (any) => this.$variantDDFormStatus[any]
    },
    editAble() {
      return (any) => this.$can('SAL', '') || any === this.userData.id
    },
    editAbleDocument() {
      return (any) => this.$can('Hulu Migas', '') || this.$can('SAL', '')
    },
    verifiedVariant() {
      return (any) => this.$variantVerified[any]
    },
    handlingTugBoat() {
      return () => this.form.tug_boat.tug_boats
    },
    stateModifiedKapal() {
      /*
      1. revisi data | ada data yang keliru ? , ubah data -->  permissionSuggestionBoat
      2. ubah data | ada pembaharuan data (Contoh case Nomor PKK expired ) ? , duplicate and create as a new data
       */
    },
    permissionVerified() {
      /* only read data */
    },
    permissionSuggestionTugBoatMain() {
      return () =>
        this.isExistDetailID
          ? this.stateInputDMKTBMaster
          : this.permissionTenantAndSAL()
    },
    permissionSuggestionTugBoat() {
      /* on create disable when use suggestion */
      /* on details enable only for sal  */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')
      const tenantCanModified = this.$can('Hulu Migas', '')

      const isExistRiverBoat = !this.form.tug_boat.isNew

      const onCreate = isExistRiverBoat /* disable when use suggestion value */

      const condition = this.isExistDetailID
        ? /* on details data */ salCanModified ||
          tenantCanModified ||
          hostCanModified
        : /* on creating data */ onCreate
      return () => (this.isExistDetailID ? this.stateInputDMKTB : condition)
    },
    permissionSuggestionBoatMain() {
      return () =>
        this.isExistDetailID
          ? this.stateInputDMKMaster
          : this.permissionTenantAndSAL()
    },
    permissionSuggestionBoat() {
      /* on create disable when use suggestion */
      /* on details enable only for sal  */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')
      const tenantCanModified = this.$can('Hulu Migas', '')

      const isExistRiverBoat = !this.form.dm_river_boat_hm.isNew

      const onCreate = isExistRiverBoat /* disable when use suggestion value */

      const condition = this.isExistDetailID
        ? /* on details data */ salCanModified ||
          tenantCanModified ||
          hostCanModified
        : /* on creating data */ onCreate
      return () => (this.isExistDetailID ? this.stateInputDMK : condition)
    },
    permissionTenantAndSAL() {
      /* disable is for I/O else VIEW */
      const condition = this.isExistDetailID
        ? /* on details data */
          this.$can('manage', 'all') ||
          this.$can('Hulu Migas', '') ||
          this.$can('SAL', '')
        : /* on create | skip */ null
      return () => (this.isExistDetailID ? !condition : condition)
    },
    permissionTenantOnly() {
      /* hanya admin sal */
      const hostCanModified = this.$can('manage', 'all')
      const tenantCanModified = this.$can('Hulu Migas', '')

      const condition = this.isExistDetailID
        ? /* on details data */ tenantCanModified || hostCanModified
        : /* on create | skip */ false
      return () => (this.isExistDetailID ? !condition : condition)
    },
    permissionSalOnly() {
      /* hanya admin sal */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')

      const condition = this.isExistDetailID
        ? /* on details data */ hostCanModified || salCanModified
        : /* on create | skip */ false
      console.log('this.isExistDetailID::', this.isExistDetailID)
      console.log('this.isExistDetailID::', condition)
      return () => condition
    }
  },
  watch: {
    // 'form.schedule.jenis_kapal': function (params) {
    //   console.log('form.schedule.jenis_kapal::', params)
    //   // this.wizardKey += 1
    //   this.configWizardFirst()
    // },
    '$route.params': function (params) {
      this.dataID = params.id
      this.isExistDetailID = !!params.id
      this.fetchData(true)
    },
    errorCatch() {
      try {
        const [firstKey] = Object.keys(this.errorCatch.response.data.message) // by keys
        const [firstValue] = Object.values(
          this.errorCatch.response.data.message
        )
        this.errorMatchValue =
          this.errorCatch.response.data.message.value || null // by values
        this.errorPath = firstKey
        this.errorValue = firstValue
      } catch (error) {
        console.log('watch error catch error : ', error)
        console.log('watch error catch error : ', this.errorCatch)
      }
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
    this.configWizardFirst()

    // this.configWizardSecond()
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  async created() {
    if (
      this.$store.state.app.tokenNotification === '' ||
      this.$store.state.app.tokenNotification === null
    ) {
      this.$swal({
        title: 'Info, Notifikasi Bermasalah!',
        text: ' Kami telah secara otomatis mendeteksi pemberitahuan token sudah kedaluwarsa atau belum mengaktifkan izin notifikasi browser, Fix Notifikasi untuk memperbarui token dan coba kembali ke halaman ini.\njika Anda berada di browser Safari, coba lagi dengan browser Chrome/Firefox dengan perangkat Android atau Laptop/Komputer (OS windows/macOS)\n\n',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Fix (kedaluwarsa)',
        cancelButtonText: 'Abaikan Fitur Ini (iOS)',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.$forceUpdate()
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
        } else {
          console.log('mengabaikan fitur notifikasi')
        }
      })
    }
    await this.fetchData()
    await this.jettyPositionTab()
    this.configWizardFirst()
  },
  destroyed() {},
  methods: {
    moment,
    async saveCrewKapal() {
      const count_crew_mb = this.form.schedule.count_crew_mb
      const count_crew_tb = this.form.schedule.count_crew_tb

      if (count_crew_mb) {
        const _model_id = this.form.form_hulu_migas_id
        const _uri = `visitor_planning_river/${_model_id}/form_hulu_migas`
        await useJwt.http
          .put(`${_uri}`, { count_crew: count_crew_mb })
          .then(async () => {
            this.$alert({
              title: 'Berhasil menyimpan data crew kapal',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((error) => {
            this.$alert({
              title: 'Tidak Berhasil menyimpan data crew kapal',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${error}`)
          })
      }
      if (count_crew_tb) {
        const _model_id = this.form.form_tug_boat_id
        const _uri = `visitor_planning_river/${_model_id}/form_tug_boat`
        await useJwt.http
          .put(`${_uri}`, { count_crew: count_crew_tb })
          .then(async () => {
            this.$alert({
              title: 'Berhasil menyimpan data crew kapal',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((error) => {
            this.$alert({
              title: 'Tidak Berhasil menyimpan data crew kapal',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${error}`)
          })
      }
    },
    idleResolveFetchResponse(response) {
      const data = response.data.riv_iddle_times
      const totalTimeInMilliseconds = data.reduce((total, item) => {
        const startTime = new Date(item.start_time)
        const endTime = new Date(item.end_time)

        // Calculate the time difference in milliseconds
        const timeDifference = endTime - startTime

        // Add the time difference to the total
        return total + timeDifference
      }, 0)

      // Convert total time to hours and minutes
      const totalHours = Math.floor(totalTimeInMilliseconds / 3600000)
      const totalMinutes = Math.floor(
        (totalTimeInMilliseconds % 3600000) / 60000
      )

      this.totalIdleTime = `${totalHours} hours and ${totalMinutes} minutes`
      return data
    },
    idleResolveFetchUrl({ pagination }) {
      return {
        url: this.idle.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC',
          chain_model_id: this.form.form_chain_hm_id,
          chain_model: 'form_chain_hm'
        }
      }
    },
    async simpanKeterangaSeaTruck() {
      const URI = `form_hulu_migas/${this.form.form_hulu_migas_id}/others/keterangan/seatruck`
      this.loadingButton = !this.loadingButton
      await useJwt.http
        .put(`${URI}`, {
          keterangan_sea_truck: this.form.schedule.keterangan_sea_truck
        })
        .then(async () => {
          this.loadingButton = !this.loadingButton
          this.$alert({
            title: `Berhasil`,
            text: `Berhasil menyimpan keterangan ( Only SeaTruck )`,
            variant: 'success',
            icon: 'CheckCircleIcon'
          })
        })
        .catch((error) => {
          this.loadingButton = !this.loadingButton
          this.$swal({
            title: 'Error!',
            text: `${error.response.data.message ?? 'something wrong.'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {})
        })
      // await this.sentNotification({
      //   action: this.$notificationMsg['rkbm'],
      //   timeIfPindah: null,
      //   positionIfPindah: null
      // })
    },
    async saveNamaTB(isHaveTugBoat) {
      if (isHaveTugBoat) {
        if (!this.form.optional.nama_tb) {
          this.$alert({
            title: ``,
            text: `Keterangan nama tugboat dibutuhkan`,
            variant: 'warning',
            icon: 'XCircleIcon'
          })
          return
        }
      }
      this.form.optional.isLoading = !this.form.optional.isLoading
      await useJwt.http
        .put(`${this.form.schedule.uriOptional}${this.dataID}`, {
          ket_tb: this.form.optional.nama_tb
        })
        .then(async () => {
          this.form.optional.isLoading = !this.form.optional.isLoading
          this.$alert({
            title: `Berhasil`,
            text: `Terimakasih telah mengajukan RKBM`,
            variant: 'success',
            icon: 'CheckCircleIcon'
          })
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: `${error.response.data.message ?? 'something wrong.'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {})
        })
      await this.sentNotification({
        action: this.$notificationMsg['rkbm'],
        timeIfPindah: null,
        positionIfPindah: null
      })
    },
    jettyPositionTab() {
      try {
        if (this.$can('manage', 'jetty')) {
          this.$refs.wizardFirst.tabs[0].active = false
          this.$refs.wizardFirst.tabs.forEach((tab, index) => {
            if (tab.tabId.includes('Jetty')) {
              this.$refs.wizardFirst.tabs[index].active = true
            }
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    testnotb() {
      // if (this.form.form_tug_boat_id && this.temp == null) {
      //   this.temp = this.form.form_tug_boat
      //   this.temp1 = this.form.tug_boat
      //   this.form.tug_boat = null
      //   this.form.form_tug_boat = null
      //   this.form.form_tug_boat_id = null
      // } else {
      //   this.form.form_tug_boat = this.temp
      //   this.form.form_tug_boat_id = this.temp.id
      //   this.form.tug_boat = this.temp1
      //   this.temp = null
      //   this.temp1 = null
      // }
    },
    async saveRealisasiKegiatan(tabName) {
      this.loadingRealisasiKegiatan = true
      console.log('tabname::', tabName)
      if (tabName === 'SPOG' && this.form.schedule.no_spog_file_tb) {
        try {
          await this.uploadSPOG({
            model: 'form_tug_boat',
            model_id: this.form.form_tug_boat_id,
            singleAppendFile: this.form.schedule.no_spog_file_tb
          })
            .then((response) => {})
            .catch(() => {
              this.loadingRealisasiKegiatan = false
            })
          // todo notification spog
          // await this.sentNotificationDocLengkap()
        } catch (error) {
          console.log('upload spog error::', error)
        }
      }
    },
    async saveIjinBunker() {
      try {
        await this.uploadSPOG({
          model: 'form_tug_boat',
          model_id: this.form.form_tug_boat_id,
          singleAppendFile: this.form.schedule.no_ijin_bunker_file,
          uri: 'file_storage_bunker',
          no_ijin_bunker: this.form.schedule.no_ijin_bunker,
          total_bunker: this.form.schedule.total_bunker
        })
          .then((success) => {
            console.log('success upload file ijin bunker')
            this.$swal({
              title: 'Good job!',
              html: 'Berhasil upload file ijin bunker<br>Tap atau Klik dimana saja untuk kembali',
              icon: 'success',
              confirmButtonText: 'Lanjutkan Ubah Data',
              cancelButtonText: 'Kembali',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-warning ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              if (result.value) {
                // this.loadingRealisasiKegiatan = false
                this.fetchData()
              } else {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => this.$router.go(-1),
                  300
                )
              }
            })
          })
          .catch((error) => {
            console.log('error upload file ijin bunker', error)
            // this.loadingRealisasiKegiatan = false
          })
      } catch (error) {
        // this.loadingRealisasiKegiatan = false
        console.log('upload spog error::', error)
      }
    },
    async uploadSPOG({
      model = null,
      model_id = null,
      singleAppendFile = null,
      uri = 'file_storage',
      no_ijin_bunker = null,
      total_bunker = null
    }) {
      const formData = new FormData()
      let uriAction = `${uri}/${model_id}/${model}`
      if (no_ijin_bunker) {
        uriAction = `${uri}/${model_id}/${model}/${no_ijin_bunker}/${total_bunker}`
      }

      console.log('uriAction :: ', uriAction)
      console.log('upload :: ', singleAppendFile)

      formData.append('file', singleAppendFile)
      useJwt.http
        .put(uriAction, formData)
        .then((response) => {
          const { file_storages } = response.data
          console.log('file_storages:: ', file_storages)
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
          if ((uri = 'file_storage')) {
            this.fetchData()
            this.loadingRealisasiKegiatan = false
          }
        })
        .catch((err) => {
          this.loadingRealisasiKegiatan = false
          console.log(`error ${err}`)
        })
    },
    configWizardFirst() {
      /* OLD TRICKS */
      const { tabs } = this.$refs.wizardFirst
      this.$refs.wizardFirst.tabs = tabs.sort(
        (a, b) => a.tabId.slice(-1) - b.tabId.slice(-1)
      ) // b - a for reverse sort
      this.$refs.wizardFirst.activateAll()
      // tabs.forEach((tab) => (tab.checked = true))
      // console.log('much tabs::', tabs.length)
      // this.$refs.wizardFirst.maxStep = tabs.length

      /* Document Tricks */
      // this.$refs.wizardFirst.activateAll()
      // this.$refs.wizardFirst.hideButtons = true
    },
    // configWizardFirst() {
    //   this.$nextTick(() => {
    //     const { tabs } = this.$refs.wizardFirst
    //     // tabs.forEach((tab) => (tab.checked = true))
    //     this.$refs.wizardFirst.tabs = tabs.sort(
    //       (a, b) => a.tabId.slice(-1) - b.tabId.slice(-1)
    //     ) // b - a for reverse sort
    //     // console.log(
    //     //   'this.$refs.wizardFirst.tabs::',
    //     //   this.$refs.wizardFirst.tabs
    //     // )
    //     // this.$refs.wizardFirst.maxStep = tabs.length

    //     this.$refs.wizardFirst.activateAll()
    //     // this.$refs.wizardFirst.displayPrevButton = false
    //     // this.$refs.wizardFirst.hideButtons = true
    //   })
    // },
    onTabChange(prevTab, currentTab) {
      this.$nextTick(() => {
        if (this.$refs.wizardFirst.isLastStep === true) {
          this.$nextTick(() => {
            this.lastStep = true
          })
        } else {
          this.$nextTick(() => {
            this.lastStep = false
          })
        }
      })
    },
    formSubmitted() {
      this.$refs.refVisitor.validate().then(async (success) => {
        if (success) {
          this.validationCreateData()
          resolve(true)
        } else {
          this.submitMandatory(success, 'Crew Kapal')
        }
      })
    },
    submitMandatory(success, location) {
      console.log('required', success)
      this.$swal({
        position: 'top-end',
        icon: 'error',
        title: `Please check field required * at ${location}`,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
      this.formSubmittedLoading()
    },
    async alreadyRealisasi(event) {
      console.log('alreadyRealisasi::trigger')
      this.fetchData()
    },
    async setPayloadNotification(
      action,
      timeIfPindah = null,
      positionIfPindah = null
    ) {
      const path = '/#/dashboard/monitoring/rrv/hulu-migas/view'
      const route = process.env.VUE_APP_URL + path

      const boat = this.form.dm_river_boat_hm
      const tugBoat = this.form.tug_boat
      const form = this.form.schedule

      const posisi = ` Jetty Ditentukan SAL `
      let pkkFinal
      let boatNameFinal
      let form_type = 'Hulu Migas'
      if (this.form.schedule.jenis_kapal === 'Tug Boat') {
        pkkFinal = `${form.no_pkk_tug_boat}`
        boatNameFinal = `${tugBoat.name_tug_boat}`
        form_type = 'FORM_TUG_BOAT'
      } else if (
        this.form.schedule.jenis_kapal !== 'Barge' &&
        this.form.schedule.jenis_kapal !== 'Tug Boat'
      ) {
        pkkFinal = `${form.no_pkk}`
        boatNameFinal = `${boat.boat_name}`
      } else {
        pkkFinal = `${form.no_pkk} dan ${form.no_pkk_tug_boat}`
        boatNameFinal = `${boat.boat_name} dan ${tugBoat.name_tug_boat}`
      }
      this.payloadNotification = {
        /* other db | outside */
        // registrationToken: this.$store.state.app.tokenNotification, // no need to subscribe
        url: route,
        /* self db */
        form_type,
        type: 'light-info', // override only on hulu migas
        action,
        time: timeIfPindah || form.rencana_sandar,
        no_pkk: pkkFinal,
        posisi: positionIfPindah || posisi,

        // router.currentRoute.params.kegiatanDetail || 'Tambat',
        boat_name: boatNameFinal,
        is_read: false,
        form_id: this.form.form_chain_hm_id,
        company: '',
        pemilik: 'Hulu Migas',
        tag: 'river_route',
        screen_re_fetch: 'form_chain_hm'
      }
    },
    async sentNotification({
      action,
      timeIfPindah = null,
      positionIfPindah = null
    }) {
      if (action === 'Telah Sandar') {
        timeIfPindah =
          this.form.schedule.sandar_start_time ||
          this.form.tug_boat.tug_sandar_start_time
      }
      await this.setPayloadNotification(action, timeIfPindah, positionIfPindah)
      useJwt.http
        .post('notification_river', this.payloadNotification)
        .then(() => {})
        .catch((error) => {
          console.log('an error sent firebase notification ', error)
        })
    },
    dropZoneCallBack(val) {
      console.log('dropZoneCallBack::', val)
      if (val === 'success') {
        this.fetchData()
      }
    },
    async verificationHmCompleted() {
      this.loadingCompletedBySAL = true
      const jwt = await useJwt.http.put(
        `form_chain_hm/${this.form.form_chain_hm_id}`,
        { flag_verified: 'Completed By SAL' }
      )
      if (jwt) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(
          () => (this.loadingCompletedBySAL = false),
          300
        )
        this.fetchData()
      }
    },
    async verificationHmUpdate(id, state_ok, msg) {
      const msgRegistered = {
        spog: 'Belum mengisi Nomor SPOG',
        pos_jet: 'Status Kapal (Sesuai INAPORTNET)<br/>Belum di PT.SAL',
        rkbm: 'Belum mengajukan RKBM',
        no_rkbm: 'Belum mengisi Nomor RKBM',
        no_rpkro: 'Belum mengisi Nomor RPKRO',
        no_pbmbb: 'Belum mengisi Nomor PBMBB'
      }
      const checkMsg = Object.values(msgRegistered).includes(msg)
      if (!this.permissionSalOnly()) {
        const jwt = await useJwt.http.put(`verification_hm/${id}`, {
          state_ok
        })
        if (jwt) {
          this.fetchData()
        }
      } else {
        if (checkMsg) {
          console.log('match')
          this.$alert({
            title: `Form Verification: ${msg}`,
            text: `its automatically checked by system.`,
            variant: 'warning',
            icon: 'XCircleIcon'
          })
        } else {
          console.log('!match')
          const jwt = await useJwt.http.put(`verification_hm/${id}`, {
            state_ok
          })
          if (jwt) {
            this.fetchData()
          }
        }
      }
    },
    methodModalVerification(val = false, callBack = null) {
      this.dataModalVerification = val
      if (callBack) {
        console.log('callBack: ', callBack)
        if (callBack.includes('berhasil')) {
          this.$alert({
            title: `Form Verification`,
            text: `${callBack}`,
            variant: 'success',
            icon: 'CheckCircleIcon'
          })
          this.fetchData()
        } else {
          this.$alert({
            title: `Form Verification`,
            text: `${callBack}`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      }
    },
    renderComponent(event) {
      console.log('render for: ', event)
      if (event === 'TablePipeLineInner') {
        this.form.table_pipe_line += 1
        this.form.table_pipe_line_1 += 1
      } else if (event === 'TableMhInner') {
        this.form.table_mh += 1
      } else if (event === 'TableJettyPosition') {
        this.form.table_jetty_position += 1
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
      } else if (event === 'calculation_total_kegiatans') {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(
          () => this.fetchTotalBerkegiatans(),
          300
        )
      }
    },
    async onUpdateStatus(isCancel, statusChanged) {
      const dataID = this.form.form_hulu_migas_id
      const { schedule } = this.form
      const payload = {
        is_cancel: isCancel,
        form_status: statusChanged,
        kegiatan_batal_time: moment(),
        set_kegiatan_batal_time: true,
        form_tug_boat_id: this.form.form_tug_boat_id,
        tug_boat_id: this.form.tug_boat.id
      }

      await useJwt.http
        .put(`${schedule.uriStatus}${dataID}`, payload)
        .then(async () => {
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil membatalkan kegiatan',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: `${error.response.data.message ?? 'something wrong.'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {})
        })
    },
    formatDateTime(value, format = 'D MMMM YYYY - hh:mm A') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    onStartChange(selectedDates, dateStr, instance, model) {
      console.log('dateStr', dateStr)
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    },

    dateFormat(data) {
      return moment(data).format('D MMMM YYYY - hh:mm A')
    },
    breakSuggestionValue() {
      this.form.dm_river_boat_hm.isNew = true
    },
    breakSuggestionValueTB() {
      this.form.tug_boat.isNew = true
    },
    getSuggestionValueTK(suggestion) {
      console.log('getSuggestionValueTK.item:: ', suggestion.item.asal)
      this.form.schedule.tujuan_kapal = suggestion.item.asal
      this.form.schedule.tujuan_kapal_is_new = false
      return suggestion.item.asal
    },
    getSuggestionValueFocus(
      event,
      model,
      currentSuggest,
      childSuggesst = null,
      HaveChildren = null
    ) {
      console.log(
        `model: ${model}, currentSuggest: ${currentSuggest}, childSuggesst: ${childSuggesst}`
      )
      this.form.currentModel = model
      this.form.currentFieldSuggest = currentSuggest
      this.form.currentFieldSuggestChild = childSuggesst
      this.form.currentFieldHaveChildren = HaveChildren
    },
    getSuggestionValueManual(suggestion) {
      this.form.schedule.status_kapal = suggestion.item.status_kapals
      this.$refs.createHuluMigasRules.validate()
      return suggestion.item.status_kapals
    },
    getSuggestionValueManualInput(keyword) {
      this.form.schedule.status_kapal = keyword
    },
    getSuggestionValueManualTB(suggestion) {
      this.form.schedule.status_kapal_tb = suggestion.item.status_kapals
      this.$refs.createHuluMigasRules.validate()
      return suggestion.item.status_kapals
    },
    getSuggestionValueManualInputTB(keyword) {
      this.form.schedule.status_kapal_tb = keyword
    },
    getSuggestionValue(suggestion) {
      if (!!suggestion.item.sedang_digunakan) {
        this.$alert({
          title: `Data ${suggestion.item.model_status}`,
          text: `Oleh ${suggestion.item.sedang_digunakan} 📚 #${
            suggestion.item.form_chain_id ?? '-'
          }#${suggestion.item.last_used_by_model_id ?? '-'}#${
            suggestion.item.form_tug_boat_id ?? '-'
          }`,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        return
      }
      // console.log('this.dataID', this.dataID); // undefined
      // console.log('this.dataID', !this.dataID); // true
      // console.log('this.dataID', !!this.dataID); // false
      console.log('!suggestion.item.form_status', !suggestion.item.form_status) // false

      // console.log('suggestion', suggestion);
      // name_tug_boat
      // console.log('this.form.currentFieldSuggest:::', this.form.currentFieldSuggest);
      if (this.form.currentFieldSuggest === 'name_tug_boat') {
        /* hanya di pilih tugboat */
        if (suggestion.item.form_status === 'KAPAL SANDAR') {
        } else {
          if (!this.dataID && !suggestion.item.form_status) {
            // jika buat form dan status undefined / no status bisa buat
          } else {
            // jika buat dan edit form dan status ada
            this.$alert({
              title: `Data ini ${suggestion.item.model_status}`,
              text: `Pastikan data status kapal telah sandar di Jetty 📚 #${
                suggestion.item.form_chain_id ?? '-'
              }#${suggestion.item.last_used_by_model_id ?? '-'}#${
                suggestion.item.form_tug_boat_id ?? '-'
              }`,
              variant: 'warning',
              icon: 'XCircleIcon'
            })
            return
          }
        }
      }
      if (
        suggestion.item.tug_status === 'Dalam Perencanaan' &&
        !this.isExistDetailID
      ) {
        this.$alert({
          title: `Data ${suggestion.item.tug_status}, Hanya dapat memilih data ini ketika mengganti kapal tug boat pada form yang telah diterbitkan.`,
          text: ``,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        return
      }
      const model = this.form.currentModel
      const field = this.form.currentFieldSuggest
      const fieldChild = this.form.currentFieldSuggestChild
      // const haveChildren = this.form.currentFieldHaveChildren

      console.log('clicked', model)
      let result = ''

      if (fieldChild === '' || fieldChild === null || fieldChild === 'null') {
        // !is three data child
        const currentLoadData = this.form[`${model}`][`${model}s`]
        if ('dm_shipping_agent' in suggestion.item) {
          console.log('aw:', suggestion.item)
          const item = {
            ...suggestion.item,
            no_pkk: null, // changelog 15.3.0
            dm_shipping_agent: {
              isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
              isNew: false, // POST or PUT
              uri: this.form.dm_river_boat_hm.dm_shipping_agent.uri, // URI endPoint
              ...suggestion.item.dm_shipping_agent,
              dm_shipping_agents: []
            }
          }
          this.form[`${model}`] = {
            isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
            isNew: false, // POST or PUT
            uri: this.form[`${model}`].uri, // URI endPoint
            [`${model}s`]: currentLoadData, // use new data or from already exist data on Database
            ...item // replace item from Database
          }
        } else {
          this.form[`${model}`] = {
            isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
            isNew: false, // POST or PUT
            uri: this.form[`${model}`].uri, // URI endPoint
            [`${model}s`]: currentLoadData, // use new data or from already exist data on Database
            no_pkk: null, // changelog 15.3.0
            ...suggestion.item // replace item from Database
          }
        }

        // state message after choose
        this[`${model}State`] = 1
        result = suggestion.item[`${field}`]
      } else {
        const split = fieldChild.split('.')
        const currentLoadData =
          this.form[`${model}`][`${split[0]}`][`${split[0]}s`]

        this.form[`${model}`][`${split[0]}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`][`${split[0]}`].uri,
          [`${split[0]}s`]: currentLoadData,

          ...suggestion.item
        }
        this.form[`${model}`] = {
          ...this.form[`${model}`],
          no_pkk: null, // changelog 15.3.0
          [`${split[0]}_id`]: suggestion.item.id
        }
        this[`${split[0]}State`] = 1
        result = suggestion.item[`${split[1]}`]
      }
      if (result === this.errorMatchValue) {
        this.errorMatchValue = null
      }
      console.log('result::', suggestion)
      return result
    },
    searchForm({ keyword, uri, model, currentSuggest, childSuggesst = null }) {
      if (keyword) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          let splitSuggest
          if (childSuggesst !== null) {
            splitSuggest = childSuggesst.split('.')
          }
          // loading|state
          if (childSuggesst === null) {
            // state message
            if (this[`${model}State`] === 1) {
              this[`${model}State`] = 2
            }
            this[`${model}StateLoading`] = true
          } else {
            // state message
            if (this[`${splitSuggest[0]}State`] === 1) {
              this[`${splitSuggest[0]}State`] = 2
            }
            this[`${splitSuggest[0]}StateLoading`] = true
          }
          useJwt.http
            .get(`${uri}`, { params: { keyword, single_search: true } })
            .then((response) => {
              if (response.data.total_items === 0) {
                if (childSuggesst === null) {
                  // no data
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`].isNew = true
                  this.form[`${model}`][`${model}s`] = []

                  // auto filled
                  this.form[`${model}`].user.full_name = null
                  this.form[`${model}`].created_at = moment()
                  this.form[`${model}`].user.phone = null
                  this[`${currentSuggest}StateLoading`] = false
                } else {
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`][`${splitSuggest[0]}`].isEverRun = false // new sytnx
                  this.form[`${model}`][`${splitSuggest[0]}`].isNew = true
                  this.form[`${model}`][`${splitSuggest[0]}`][
                    `${splitSuggest[0]}s`
                  ] = ''
                  this[`${splitSuggest[0]}StateLoading`] = false
                }
              } else if (childSuggesst === null) {
                this.form[`${model}`].isNew = true
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${model}s`] = response.data[`${model}s`]
                this[`${currentSuggest}StateLoading`] = false
                // this[`${splitSuggest[0]}StateLoading`] = false
              } else {
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${splitSuggest[0]}`].isEverRun = false
                this.form[`${model}`][`${splitSuggest[0]}`].isNew = true
                this.form[`${model}`][`${splitSuggest[0]}`][
                  `${splitSuggest[0]}s`
                ] = response.data[`${splitSuggest[0]}s`]
                // loading|state
                this[`${model}StateLoading`] = false
                // this[`${currentSuggest}StateLoading`] = false
                this[`${splitSuggest[0]}StateLoading`] = false
              }
              // loading|state
              this[`${model}StateLoading`] = false
              this[`${currentSuggest}StateLoading`] = false
              // this[`${splitSuggest[0]}StateLoading`] = false
            })
            .catch((e) => {
              // this.form.isAgenAndTrans = false
              this.form[`${model}`].isNew = true
              // this.form[`${model}`].user.full_name = null
              this.form[`${model}`][`${model}s`] = []
              // loading|state
              this[`${model}StateLoading`] = false
              if (childSuggesst !== null) {
                this[`${splitSuggest[0]}StateLoading`] = false
              }
            })
        }, 300)
      }
    },
    onFocusJetty() {
      if (this.jetty_positions.length === 0) {
        this.fetchJetty()
      }
    },
    async getIDCreated(jwt, model) {
      let resultID
      await Promise.resolve(jwt).then(async (response) => {
        resultID = response.data[`${model}`].id
      })
      return resultID
    },
    async handlingDistributionData(model, strModel, updateID, parent = null) {
      console.log(
        'action for ',
        model,
        ' and -> ',
        strModel,
        'updateID: ',
        updateID
      )
      if (updateID && model.isNew === false) {
        console.log('doing update:', updateID)
        const jwt = await useJwt.http.put(`${model.uri}${updateID}`, model)
        console.log('is new = false: ', strModel)
        this.form.schedule[`${strModel}_id`] = model.id

        if (parent) {
          console.log(
            'have parent set id : ',
            model.id,
            ' for ',
            `${this.form[`${parent}`][`${strModel}_id`]}`
          )
          this.form[`${parent}`][`${strModel}_id`] = model.id
        } else {
          console.log('not have parent')
        }
      } else if (model.isNew === true) {
        console.log('doing create')
        const jwt = await useJwt.http.post(model.uri, model)
        if (jwt) {
          const id = await this.getIDCreated(jwt, strModel)
          this.form.schedule[`${strModel}_id`] = id
          if (parent) {
            console.log(
              'have parent set id : ',
              id,
              ' for ',
              `${this.form[`${parent}`][`${strModel}_id`]}`
            )
            this.form[`${parent}`][`${strModel}_id`] = id
          } else {
            console.log('not have parent')
          }
        }
      } else {
        console.log(`doing use on exist data: ${strModel}_id`, model.id)
        this.form.schedule[`${strModel}_id`] = model.id
        if (parent) {
          console.log(
            'have parent set id : ',
            model.id,
            ' for ',
            `${this.form[`${parent}`][`${strModel}_id`]}`
          )
          this.form[`${parent}`][`${strModel}_id`] = model.id
        } else {
          console.log('not have parent')
        }
      }
    },
    /* JWT PROCESSING | FETCH DATA */
    async fetchJetty() {
      this.fetchLoading = true
      await useJwt.http.get('jetty_position').then((response) => {
        const data = response.data.jetty_positions.map((list) => ({
          ...list,
          text: list.position,
          value: list.position
        }))
        this.jetty_positions = data
        this.jetty_positions.unshift({
          value: 'null',
          text: 'Pilih Posisi Jetty'
        })
        this.fetchLoading = false
      })
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    msToTime(duration) {
      var x = duration
      var tempTime = moment.duration(x)
      return `${tempTime.days()} day ${tempTime.hours()} hours ${tempTime.minutes()} minutes ${tempTime.seconds()} seconds | ${x} milliseconds`
    },
    async fetchTotalBerkegiatans() {
      this.loadingTotalKegiatan = true
      await useJwt.http
        .get(`vw_realisasi_kegiatan/${this.form.form_chain_hm_id}`)
        .then((response) => {
          const { vw_realisasi_kegiatans } = response.data
          const result = this.msToTime(vw_realisasi_kegiatans)
          this.total_realisasi_kegiatan = result
          this.loadingTotalKegiatan = false
        })
        .catch((error) => {
          this.loadingTotalKegiatan = false
        })
    },
    async fetchData(reRender = null) {
      const uriAPI = router.currentRoute.params.api
      const dataID = this.dataID

      // const otherPage = router.currentRoute.params.form_hulu_migas_id
      // const otherPage = router.currentRoute.params.form_tug_boat_id

      if (dataID !== undefined) {
        this.fetchLoading = true

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then(async (response) => {
            this.fetchLoading = false
            this.form.tug_boat.tug_boats = []

            console.log('fetchData:', response.data[`${uriAPI}s`])
            console.log(`uri api : ${uriAPI}s`)

            let {
              verification_hms,
              form_hulu_miga,
              form_tug_boat,
              flag_verified,
              status,
              id,
              ket_tb,
              user,
              crew_mb,
              crew_tb
            } = response.data[`${uriAPI}s`]

            if(crew_mb){
              this.form.schedule.count_crew_mb = crew_mb.count_crew
            }
            if(crew_tb){
              this.form.schedule.count_crew_tb = crew_tb.count_crew
            }

            this.user_id = user.id
            this.form.optional.nama_tb = ket_tb

            this.form.flag_verified = flag_verified

            this.form.form_chain_hm_id = id
            this.dataPayloadModalVerification.form_chain_hm_id = id
            this.keyModalVerification += 1
            const origin = form_hulu_miga

            if (!!verification_hms) {
              // if (!!this.form.schedule.no_spog === false) {
              //   this.form.verification_hm.push({
              //     manual_message: 'Nomor SPOG Belum diisi (checked by system)',
              //     state_ok: false
              //   })
              // }
              this.form.verification_hm = []
              verification_hms.forEach((element) => {
                this.form.verification_hm.push({
                  id: element.id,
                  manual_message: element.manual_message,
                  state_ok: element.state_ok
                })
              })
            }

            if (!!form_hulu_miga) {
              const currentKegiatan = form_hulu_miga.kegiatan
              const updateKegiatan = this.currentlyKegiatanKapal(form_hulu_miga)
              this.form.schedule.tujuan_kapal = form_hulu_miga.tujuan_kapal
              this.form.schedule.keterangan_sea_truck =
                form_hulu_miga.keterangan_sea_truck

              const {
                dm_river_boat_hm,
                dm_asal_kapal,
                realisasi_posisi_kapals
              } = form_hulu_miga
              // eslint-disable-next-line camelcase
              const { dm_shipping_agent } = dm_river_boat_hm
              // eslint-disable-next-line camelcase
              this.form.created_by = `${user?.full_name} ( ${
                user?.perusahaan || '-'
              } ${user?.phone} - ${user.email} )`

              // // payload association
              this.form.schedule.dm_river_boat_hm_id = dm_river_boat_hm.id

              // // payload
              this.form.schedule.id = origin.id
              this.form.form_hulu_migas_id = origin.id
              console.log(
                'this.form.form_hulu_migas_id:::',
                this.form.form_hulu_migas_id
              )
              this.form.table_jetty_position += 1

              this.form.schedule.jenis_kapal = origin.jenis_kapal

              this.form.schedule.rencana_sandar = origin.rencana_sandar

              this.form.schedule.rencana_position_jetty =
                origin.rencana_position_jetty
              this.form.schedule.rencana_baris_position_jetty =
                origin.rencana_baris_position_jetty
              this.form.schedule.baris_position_jetty =
                origin.baris_position_jetty

              this.form.schedule.created_at = origin.created_at

              this.form.schedule.status_kapal = origin.status_kapal
              this.form.schedule.no_rpkro = origin.no_rpkro
              this.form.schedule.no_rkbm = origin.no_rkbm
              this.form.schedule.no_pbmbb = origin.no_pbmbb
              this.form.schedule.no_spog = origin.no_spog
              this.form.schedule.no_spog_brk = origin.no_spog_brk

              this.form.schedule.status_kapal_tb = origin.status_kapal_tb
              this.form.schedule.no_rpkro_tb = origin.no_rpkro_tb
              this.form.schedule.no_spog_tb = origin.no_spog_tb // need to check first

              this.form.schedule.no_pindah = origin.no_pindah
              this.form.schedule.no_pindah_tb = origin.no_pindah_tb
              this.form.schedule.ijin_lengkap_time = origin.ijin_lengkap_time

              this.form.schedule.disetujui_time = origin.disetujui_time

              this.form.schedule.position_jetty = origin.position_jetty

              this.form.schedule.sandar_start_time = origin.sandar_start_time
              this.onStartChange('', origin.sandar_start_time)
              this.form.schedule.sandar_end_time = origin.sandar_end_time

              let nowRKA1 = moment(origin.sandar_start_time)
              let endRKA1 = moment(origin.sandar_end_time) // another date
              this.form_realisasi.rka1 = this.durationAsString(nowRKA1, endRKA1)

              this.form.schedule.kegiatan_start_time =
                origin.kegiatan_start_time
              this.form.schedule.kegiatan_end_time = origin.kegiatan_end_time
              this.form.schedule.kegiatan_batal_time =
                origin.kegiatan_batal_time

              this.form.schedule.form_status = origin.form_status
              this.form.schedule.is_cancel = origin.is_cancel
              this.form.schedule.updated_at = origin.updated_at
              this.form.schedule.no_pkk = origin.no_pkk

              // helper to not update kegiatan_batal_time
              this.form.schedule.set_kegiatan_batal_time = false

              this.form.form_hulu_miga = form_hulu_miga

              this.form.dm_river_boat_hm = {
                // eslint-disable-next-line camelcase
                ...dm_river_boat_hm,
                updateID: dm_river_boat_hm.id,
                isEverRun: true,
                uri: 'dm_river_boat_hm/',
                isNew: false,
                dm_river_boat_hms: []
              }
              // if (tug_boat !== null) {
              //   this.form.tug_boat = {
              //     // eslint-disable-next-line camelcase
              //     ...tug_boat,
              //     updateID: tug_boat.id,
              //     isEverRun: true,
              //     uri: 'tug_boat/',
              //     isNew: false,
              //     tug_boats: []
              //   }
              // } else {
              //   this.tug_boat = {
              //     ...this.tug_boat
              //   }
              // }

              /* TODO REMOVE */
              if (dm_asal_kapal !== null) {
                console.log('dm_asal_kapal: exist', dm_asal_kapal)
                this.form.schedule.dm_asal_kapal_id = dm_asal_kapal.id
                this.form.dm_asal_kapal = {
                  // eslint-disable-next-line camelcase
                  ...dm_asal_kapal,
                  updateID: dm_asal_kapal.id,
                  isEverRun: true,
                  uri: 'dm_asal_kapal/',
                  isNew: false,
                  dm_asal_kapals: []
                }
              } else {
                console.log('dm_asal_kapal: not exist', dm_asal_kapal)
                /* CREATE BY SAL */
                this.form.dm_asal_kapal = {
                  // eslint-disable-next-line camelcase
                  ...this.form.dm_asal_kapal
                }
              }

              this.form.dm_river_boat_hm.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }

              // update kegiatan
              console.log('updateKegiatan::', updateKegiatan)
              console.log('currentKegiatan::', currentKegiatan)
              if (currentKegiatan !== updateKegiatan) {
                await useJwt.http
                  .put(
                    `form_hulu_migas/update/kegiatan/${this.form.form_hulu_migas_id}`,
                    {
                      kegiatan: updateKegiatan
                    }
                  )
                  .then((result) => {
                    console.log('success update kegiatan')
                  })
                  .catch((error) => {
                    console.log('failed update kegiatan')
                  })
              }
            }
            // re render for crud kegiatan
            this.form.table_pipe_line += 1
            this.form.table_mh += 1

            if (!!form_tug_boat) {
              const { tug_boat } = form_tug_boat
              const { dm_shipping_agent } = tug_boat
              this.form.form_tug_boat_id = form_tug_boat.id
              this.form.schedule.no_spog_tb_brk = form_tug_boat.no_spog_tb_brk

              let nowRKA2 = moment(form_tug_boat.tug_sandar_start_time)
              let endRKA2 = moment(form_tug_boat.tug_sandar_end_time) // another date
              this.form_realisasi.rka2 = this.durationAsString(nowRKA2, endRKA2)

              this.form.tug_boat = {
                ...this.form.tug_boat,
                ...tug_boat,
                tug_sandar_start_time: form_tug_boat.tug_sandar_start_time,
                tug_sandar_end_time: form_tug_boat.tug_sandar_end_time
              }
              this.form.tug_boat.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }
              // this.form.schedule.no_spog_tb = form_tug_boat.no_spog_tb
              // this.form.schedule.no_spog_tb = form_tug_boat.file_storages
              this.no_spog_file_tb = form_tug_boat.file_storages
              this.no_ijin_bunker_file = []
              form_tug_boat.file_storage_bunkers.forEach((item) => {
                this.form.schedule.no_ijin_bunker = item.no_ijin_bunker
                this.form.schedule.total_bunker = item.total_bunker
                this.no_ijin_bunker_file.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
              this.form.schedule.no_pkk_tug_boat = form_tug_boat.no_pkk_tug_boat
            }

            /* load rkbm document */
            const model_id = this.form.form_chain_hm_id
            const model = 'form_chain_hm'
            this.loadingFileStorage = true
            await useJwt.http
              .get(`file_storage/${model_id}/${model}`)
              .then((response) => {
                const { file_storages } = response.data
                this.isHaveFile = []
                file_storages.forEach((item) => {
                  this.isHaveFile.push({
                    id: `${item.id}`,
                    original_name: `${item.original_name}`,
                    size: `${item.size}`,
                    download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                  })
                })
                this.loadingFileStorage = false
              })
              .catch((error) => {
                this.loadingFileStorage = false
              })

            /* load total berkegiatan */
            // await this.fetchTotalBerkegiatans()

            if (reRender) {
              console.log('init payload: reRender:', this.form)
              this.formRender += 1
            } else {
              console.log('init payload:', this.form)
            }
          })
          .catch((err) => {
            this.fetchLoading = false
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.configWizardFirst()
            this.fetchLoading = false
          })
      } else {
        console.log('[debug]: fetch data id : ', dataID)
      }
    },
    /* JWT PROCESSING | CREATE DATA */
    validationCreateData() {
      this.inProgressTerbitkanForm = true
      return new Promise((resolve, reject) => {
        this.$refs.createHuluMigasRules
          .validate()
          .then(async (success) => {
            if (success) {
              try {
                let flow = this.form.schedule.jenis_kapal
                if (flow === 'Tug Boat') {
                  this.form.isTugBoatOnly = true
                  this.createTugBoat()
                } else if (flow === 'Barge') {
                  this.form.isTugBoatOnly = false
                  // copy rencana sandar main boat to tug boat
                  this.form.tug_boat.rencana_sandar =
                    this.form.schedule.rencana_sandar
                  this.form.tug_boat.rencana_position_jetty =
                    this.form.schedule.rencana_position_jetty
                  this.form.tug_boat.rencana_baris_position_jetty =
                    this.form.schedule.rencana_baris_position_jetty
                  if (this.form.isSuccessTugBoat === false) {
                    await this.createTugBoat() /* callback : this.form.schedule.tug_boat_id = tug_boat_id */
                  } else {
                    await this.createHuluMigasBoat()
                  }
                } else {
                  this.form.isTugBoatOnly = false
                  this.createHuluMigasBoat()
                }
              } catch (error) {
                console.log(
                  'something wrong on validationCreateData error',
                  error
                )
              }
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(
                () => (this.inProgressTerbitkanForm = false),
                300
              )

              resolve(true)
            } else {
              console.log(
                'something wrong on validationCreateData :::',
                success
              )
              this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Ada field mandatory yang wajib diisi',
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(
                () => (this.inProgressTerbitkanForm = false),
                300
              )

              reject()
            }
          })
          .catch((error) => {
            console.log('something wrong on validationCreateData eeee', error)
          })
      })
    },
    async createFormTugBoat({
      tug_boat_id,
      rencana_sandar,
      rencana_position_jetty,
      rencana_baris_position_jetty
    }) {
      console.log('createFormTugBoat')
      const { dm_asal_kapal } = this.form

      console.log('dm_asal_kapal check:', dm_asal_kapal)
      // let dm_asal_kapal_id
      if (dm_asal_kapal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_asal_kapal,
          'dm_asal_kapal',
          dm_asal_kapal.updateID
        )
          .then(() => {
            dm_asal_kapal.isEverRun = true
            // dm_asal_kapal_id = this.form.schedule.dm_asal_kapal_id
          })
          .catch((error) => {
            console.log('an error :', error)
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.errorCatch = error
              dm_asal_kapal.isEverRun = false
              breakSwalError = true
            }
          })
      }

      let payload = {
        tug_boat_id,
        rencana_sandar,
        rencana_position_jetty,
        rencana_baris_position_jetty,
        dm_asal_kapal_id: this.form.schedule.dm_asal_kapal_id,
        pemilik: 'Hulu Migas',
        no_pkk_tug_boat: this.form.schedule.no_pkk_tug_boat,
        count_crew_tb: this.form.schedule.count_crew_tb
      }

      /* cek rencana sandar kalau kosong pakai main boat */
      console.log('rencana_sandar : ', rencana_sandar)
      if (rencana_sandar === undefined) {
        payload = {
          ...payload,
          rencana_sandar: this.form.schedule.rencana_sandar
        }
      }

      await useJwt.http
        .post(this.form.uri_chain_second, payload)
        .then(async (response) => {
          const dataID = response.data.form_tug_boat.id
          console.log('[debug] form_tug_boat data created : ', dataID)
          this.form.form_tug_boat_id = dataID
          if (this.form.isTugBoatOnly === true) {
            const rencana_kedatangan_tug_boat =
              this.form.tug_boat.rencana_sandar

            await this.sentNotification({
              action: this.$notificationMsg['rencana_sandar'],
              timeIfPindah: rencana_kedatangan_tug_boat
            })
            this.$swal({
              title: 'Good job!',
              html: 'Berhasil membuat Form Hulu Migas (Tug Boat Only)!<br>Tap atau Klik dimana saja untuk kembali',
              icon: 'success',
              confirmButtonText: 'Lihat Data Tug Boat',
              cancelButtonText: 'Kembali',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-warning ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              if (result.value) {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () =>
                    this.$router.push({
                      name: 'dashboard-monitoring-rrv-tug-boat'
                    }),
                  300
                )
              } else {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => this.$router.go(-1),
                  300
                )
              }
            })
            return
          }
        })
        .catch((e) => {
          this.fetchLoading = false
          this.errorCatch = e
          this.form.isSuccessTugBoat = false
          this.form.schedule.tug_boat_id = null
        })
    },
    async createTugBoat() {
      console.log('createTugBoat', this.form)
      const dataID = this.dataID
      const { tug_boat, schedule } = this.form
      const { dm_shipping_agent } = tug_boat
      let payload = tug_boat

      if (!schedule.no_pkk_tug_boat) {
        console.log('no pkk tugboat')
        return
      }
      console.log(
        `form.schedule.no_pkk_tug_boat:: ${this.form.schedule.no_pkk_tug_boat}`
      )
      /* put pkk into tug_boat */
      payload = {
        ...payload,
        no_pkk_tug_boat: this.form.schedule.no_pkk_tug_boat
      }

      if (tug_boat.isNew === false) {
        this.form.schedule.tug_boat_id = tug_boat.id
        if (this.form.tug_boat.form_tug_boat_id) {
          /* form tug doesnt exist */
          this.form.form_tug_boat_id = this.form.tug_boat.id
        } else {
          await this.createFormTugBoat({
            tug_boat_id: this.form.schedule.tug_boat_id,
            rencana_sandar: this.form.tug_boat.rencana_sandar,
            rencana_position_jetty: this.form.tug_boat.rencana_position_jetty,
            rencana_baris_position_jetty:
              this.form.tug_boat.rencana_baris_position_jetty
          })
          if (this.form.isTugBoatOnly === false) {
            await this.createHuluMigasBoat()
          }
        }
        return
      }

      let breakSwalError = false
      console.log('dm_shipping_agent check:', dm_shipping_agent)
      if (dm_shipping_agent.isEverRun === false) {
        await this.handlingDistributionData(
          dm_shipping_agent,
          'dm_shipping_agent',
          dm_shipping_agent.updateID
        )
          .then(() => {
            dm_shipping_agent.isEverRun = true
            payload = {
              ...payload,
              dm_shipping_agent_id: this.form.schedule.dm_shipping_agent_id
            }
          })
          .catch((error) => {
            this.fetchLoading = false
            this.errorCatch = error
            dm_shipping_agent.isEverRun = false
            breakSwalError = true
            console.log('an error :', error)
          })
      } else {
        payload = {
          ...payload,
          dm_shipping_agent_id: this.form.schedule.dm_shipping_agent_id
        }
      }

      if (breakSwalError) {
        return
      }

      if (dataID) {
      } else {
        if (this.form.tug_boat.form_tug_boat_id) {
          /* form tug doesnt exist */
          this.form.form_tug_boat_id = this.form.tug_boat.id
        } else {
          await useJwt.http
            .post(tug_boat.uri, {
              ...payload,
              source: 'Hulu Migas'
            })
            .then(async (response) => {
              this.form.isSuccessTugBoat = true
              this.fetchLoading = false
              const tug_boat_id = response.data.tug_boat.id
              this.form.schedule.tug_boat_id = tug_boat_id
              await this.createFormTugBoat({
                tug_boat_id: this.form.schedule.tug_boat_id,
                rencana_sandar: this.form.tug_boat.rencana_sandar,
                rencana_position_jetty:
                  this.form.tug_boat.rencana_position_jetty,
                rencana_baris_position_jetty:
                  this.form.tug_boat.rencana_baris_position_jetty
              })
              if (this.form.isTugBoatOnly === false) {
                await this.createHuluMigasBoat()
              }
            })
            .catch((e) => {
              if (breakSwalError === false) {
                this.fetchLoading = false
                this.errorCatch = e
                this.form.isSuccessTugBoat = false
                this.form.schedule.tug_boat_id = null
              }
            })
        }
      }
    },
    async createHuluMigasBoat() {
      /* createFormHuluMigas */
      const dataID = this.dataID
      let { dm_river_boat_hm, schedule, dm_asal_kapal } = this.form

      // eslint-disable-next-line camelcase
      const { dm_shipping_agent } = dm_river_boat_hm

      let breakSwalError = false

      console.log('dm_shipping_agent check:', dm_shipping_agent)
      if (dm_shipping_agent.isEverRun === false) {
        await this.handlingDistributionData(
          dm_shipping_agent,
          'dm_shipping_agent',
          dm_shipping_agent.updateID,
          'dm_river_boat_hm'
        )
          .then(() => {
            dm_shipping_agent.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.errorCatch = error
              dm_shipping_agent.isEverRun = false
              breakSwalError = true
            }
          })
      }

      if (breakSwalError) {
        return
      }

      console.log('dm_river_boat_hm check:', dm_river_boat_hm)
      if (dm_river_boat_hm.isEverRun === false) {
        dm_river_boat_hm = {
          ...dm_river_boat_hm,
          no_pkk: this.form.schedule.no_pkk
        }
        await this.handlingDistributionData(
          dm_river_boat_hm,
          'dm_river_boat_hm',
          dm_river_boat_hm.updateID
        )
          .then(() => {
            dm_river_boat_hm.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.errorCatch = error
              dm_river_boat_hm.isEverRun = false
              breakSwalError = true
            }
          })
      }
      if (breakSwalError) {
        return
      }
      console.log('dm_asal_kapal check:', dm_asal_kapal)
      if (dm_asal_kapal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_asal_kapal,
          'dm_asal_kapal',
          dm_asal_kapal.updateID
        )
          .then(() => {
            dm_asal_kapal.isEverRun = true
          })
          .catch((error) => {
            console.log('an error :', error)
            this.fetchLoading = false
            if (breakSwalError === false) {
              this.errorCatch = error
              dm_asal_kapal.isEverRun = false
              breakSwalError = true
            }
          })
      }

      if (breakSwalError) {
        return
      }
      if (dataID) {
      } else {
        await useJwt.http
          .post(schedule.uri, schedule)
          .then(async (response) => {
            this.fetchLoading = false
            const dataID = response.data.form_hulu_migas.id
            console.log('[debug] form_hulu_migas data created : ', dataID)
            console.log(
              '[debug] form_tug_boat_id data chain : ',
              this.form.form_tug_boat_id
            )
            this.form.form_hulu_migas = dataID
            await this.createFormChain({
              form_tug_boat_id: this.form.form_tug_boat_id,
              form_hulu_migas_id: this.form.form_hulu_migas
            })
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.errorCatch = e
            }
          })
      }
    },
    async createFormChain({
      form_tug_boat_id = null,
      form_hulu_migas_id = null
    }) {
      console.log('createFormChain')
      await useJwt.http
        .post(this.form.uri_chain_master, {
          form_tug_boat_id,
          form_hulu_migas_id
        })
        .then(async (response) => {
          this.fetchLoading = false
          const dataID = response.data.form_chain_hm.id
          const form_hulu_migas_id =
            response.data.form_chain_hm.form_hulu_migas_id
          console.log('[debug] chain data created : ', dataID)
          console.log('[debug] chain fhm data created : ', form_hulu_migas_id)
          console.log('[debug] chain ftb data created : ', form_tug_boat_id)
          await this.sentNotification({
            action: this.$notificationMsg['rencana_sandar'],
            timeIfPindah: null,
            positionIfPindah: `#POSISI_JETTY_${this.form.schedule.rencana_position_jetty} #${this.form.schedule.rencana_baris_position_jetty}`
          }) /* TODO!: FIREBASE NOTIFICATION */

          this.$swal({
            title: 'Good job!',
            html: 'Berhasil membuat Form Hulu Migas!<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Input Kegiatan',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(
                () =>
                  this.$nextTick(() => {
                    this.$router.push({
                      name: 'dashboard-monitoring-rrv-hulu-migas-view',
                      params: {
                        id: dataID,
                        api: this.form.uri_chain_master
                      }
                    })
                  }),
                300
              )
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((e) => {
          // if (breakSwalError === false) {
          this.fetchLoading = false
          this.errorCatch = e
          // }
        })
    },

    /* JWT PROCESSING | UPDATE DATA */
    jwtPUT({ uri, payload, customMsg = null, callBack }) {},
    async jwtGantiKapalTB() {
      this.loadingDupRevTB = true
      const payload = {
        tug_boat: this.form.tug_boat,
        id: this.form.form_tug_boat_id,
        form_chain_hm_id: this.form.form_chain_hm_id
      }
      const uri = `form_hulu_migas/${this.form.form_tug_boat_id}/tenant/dmktb/switch/boat`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRevTB = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil mengganti kapal`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKTBMaster({
              isNotEdit: true,
              payloadDMK: null
            })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRevTB = false
          this.errorCatch = error
        })
    },
    updateDMKTBMaster({ isNotEdit, payloadDMK }) {
      this.statePayloadDMKTBMaster = payloadDMK
      this.stateInputDMKTBMaster = isNotEdit
    },
    updateDMKTB({ isNotEdit, payloadDMK }) {
      this.statePayloadDMKTB = payloadDMK
      this.stateInputDMKTB = isNotEdit
      this.stateInputDMKTBMaster = isNotEdit
    },
    async saveToUpdateDMKTB() {
      this.loadingDupRevTB = true
      const payload = {
        map_state_payload_dmktb: this.statePayloadDMKTB,
        tug_boat: this.form.tug_boat,
        old_tug_boat_id: this.form.tug_boat.id,
        id: this.dataID,
        ftb: {
          id: this.form.form_tug_boat_id,
          no_pkk_tug_boat: this.form.schedule.no_pkk_tug_boat
        }
      }
      const uri = `form_hulu_migas/${this.dataID}/tenant/dmktb`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRevTB = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil ${this.mapMsgPayloadDMK[this.statePayloadDMKTB]}`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKTB({ isNotEdit: true, payloadDMK: null })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRevTB = false
          this.errorCatch = error
        })
    },
    async jwtGantiKapal() {
      const payload = {
        dm_river_boat_hm: this.form.dm_river_boat_hm,
        id: this.dataID
      }
      const uri = `form_hulu_migas/${this.dataID}/tenant/dmk/switch/boat`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.$swal({
            title: 'Good job!',
            html: `Berhasil mengganti kapal`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKMaster({
              isNotEdit: true,
              payloadDMK: null
            })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.errorCatch = error
        })
    },
    updateDMKMaster({ isNotEdit, payloadDMK }) {
      this.statePayloadDMKMaster = payloadDMK
      this.stateInputDMKMaster = isNotEdit
    },
    updateDMK({ isNotEdit, payloadDMK }) {
      this.statePayloadDMK = payloadDMK
      this.stateInputDMK = isNotEdit
      this.stateInputDMKMaster = isNotEdit
    },
    async saveToUpdateDMK() {
      this.loadingDupRev = true
      const payload = {
        map_state_payload_dmk: this.statePayloadDMK,
        dm_river_boat_hm: this.form.dm_river_boat_hm,
        id: this.dataID,
        fhm: {
          id: this.form.form_hulu_migas_id,
          no_pkk: this.form.schedule.no_pkk
        }
      }
      const uri = `form_hulu_migas/${this.dataID}/tenant/dmk`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRev = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil ${this.mapMsgPayloadDMK[this.statePayloadDMK]}`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMK({ isNotEdit: true, payloadDMK: null })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRev = false
          this.errorCatch = error
        })
    },
    validationModifiedFormData() {
      return new Promise((resolve, reject) => {
        this.$refs.scheduleHuluMigasRules.validate().then((success) => {
          if (success) {
            resolve(true)
            this.modifiedFormData()
          } else {
            reject()
          }
        })
      })
    },
    async modifiedFormData() {
      /* form hulu migas id */
      this.loadingChange = true
      const dataID = this.form.form_hulu_migas_id

      const { schedule, dm_asal_kapal } = this.form
      // eslint-disable-next-line camelcase

      let breakSwalError = false

      const payload = {
        ...schedule,
        dm_asal_kapal
      }

      await useJwt.http
        .put(`${schedule.uri}${dataID}/tenant`, payload)
        .then(async () => {
          this.loadingChange = false
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil menyimpan data<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              console.log('[debug] : user click confirm')
              this.fetchData() // re render
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          this.loadingChange = false
          if (breakSwalError === false) {
            this.fetchLoading = false
            this.errorCatch = error
          }
        })
    },

    /* REALISASI */
    validationModifiedData() {
      return new Promise((resolve, reject) => {
        this.$refs.adminSalRules.validate().then((success) => {
          if (success) {
            resolve(true)
            if (this.$can('SAL', '') || this.$can('manage', 'all')) {
              this.modifiedDataBySAL()
            }
          } else {
            console.log('error', success)
            // this.$alert({
            //   title: `Tidak dapat menyimpan`,
            //   text: `Jettyman harus mengisi kapal sandar terlebih dahulu`,
            //   variant: 'danger',
            //   icon: 'XCircleIcon'
            // })
            reject()
          }
        })
      })
    },
    async modifiedDataBySAL() {
      this.loadingRelRKA3 = true
      const dataID = this.form.form_hulu_migas_id

      const { schedule } = this.form

      let breakSwalError = false

      const payload = {
        ...schedule
      }

      await useJwt.http
        .put(`${schedule.uri}${dataID}`, payload)
        .then(async () => {
          this.loadingRelRKA3 = false
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil menyimpan data<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          this.loadingRelRKA3 = false
          if (breakSwalError === false) {
            this.fetchLoading = false
            this.errorCatch = error
          }
        })
    },
    validationModifiedDataTB() {
      return new Promise((resolve, reject) => {
        this.$refs.validationModifiedDataTB
          .validate()
          .then((success) => {
            if (success) {
              resolve(true)
              if (this.$can('SAL', '') || this.$can('manage', 'all')) {
                this.modifiedDataBySALTB()
              }
            } else {
              this.$alert({
                title: `Error`,
                text: `Check field mandatory`,
                variant: 'danger',
                icon: 'XCircleIcon'
              })
              reject()
            }
          })
          .catch((error) => {
            this.$alert({
              title: `Error`,
              text: error,
              variant: 'danger',
              icon: 'XCircleIcon'
            })
          })
      })
    },
    async modifiedDataBySALTB() {
      this.loadingRelRKA4 = true
      const dataID = this.form.form_hulu_migas_id
      const ftb_id = this.form.form_tug_boat_id

      const { schedule } = this.form

      let breakSwalError = false

      const payload = {
        ...schedule,
        ftb_id
      }

      await useJwt.http
        .put(`${schedule.uri}${dataID}/tug_boat`, payload)
        .then(async () => {
          this.loadingRelRKA4 = false
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil menyimpan data<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          this.loadingRelRKA4 = false
          if (breakSwalError === false) {
            this.fetchLoading = false
            this.errorCatch = error
          }
        })
    },
    async saveRKA1() {
      this.loadingRelRKA1 = true
      const URI = `form_hulu_migas/${this.form.form_hulu_migas_id}/jettyman/boat`
      const sandar = this.form.schedule.sandar_start_time
      const berangkat = this.form.schedule.sandar_end_time
      const payload = {
        sandar_start_time: sandar,
        tujuan_kapal: this.form.schedule.tujuan_kapal,
        sandar_end_time: berangkat
      }

      await useJwt.http
        .put(`${URI}`, payload)
        .then(async () => {
          this.loadingRelRKA1 = false
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
          if (
            this.form.schedule.jenis_kapal !== 'Tug Boat' ||
            this.form.schedule.jenis_kapal !== 'Barge'
          ) {
            let state
            if (sandar && berangkat) {
              state = 'Berangkat'
              const pos = this.form.form_hulu_miga.realisasi_posisi_kapals
              const len =
                this.form.form_hulu_miga.realisasi_posisi_kapals.length - 1

              await this.sentNotification({
                action: state,
                timeIfPindah: berangkat,
                positionIfPindah: `#POSISI_JETTY_${pos[len].posisi_skrg} #${pos[len].posisi_skrg_baris}`
              })
            }
          }
        })
        .catch((error) => {
          this.loadingRelRKA1 = false
          this.fetchLoading = false
          this.errorCatch = error
        })
    },
    async saveRKA2() {
      this.loadingRelRKA2 = true
      const URI = `form_hulu_migas/${this.form.form_tug_boat_id}/jettyman/tugboat`
      const sandar = this.form.tug_boat.tug_sandar_start_time
      const berangkat = this.form.tug_boat.tug_sandar_end_time
      const payload = {
        sandar_start_time: sandar,
        sandar_end_time: berangkat
      }
      await useJwt.http
        .put(`${URI}`, payload)
        .then(async () => {
          this.loadingRelRKA2 = false
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (sandar && berangkat) {
                this.fetchData()
              } else {
                this.$router.push({
                  name: 'dashboard-monitoring-rrv-tug-boat-view',
                  params: {
                    id: this.form.form_tug_boat_id,
                    api: 'form_tug_boat',
                    previous_link: {
                      name: 'realtime-monitoring-river-route-table-sandar'
                    }
                  }
                })
              }
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
          let state
          if (sandar && berangkat) {
            state = 'Berangkat'
            const pos = this.form.form_hulu_miga.realisasi_posisi_kapals
            const len =
              this.form.form_hulu_miga.realisasi_posisi_kapals.length - 1

            await this.sentNotification({
              action: state,
              timeIfPindah: berangkat,
              positionIfPindah: `#POSISI_JETTY_${pos[len].posisi_skrg} #${pos[len].posisi_skrg_baris}`
            })
          }
        })
        .catch((error) => {
          this.loadingRelRKA2 = false
          this.fetchLoading = false
          this.errorCatch = error
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        #form_masuk {
          .toast-loading {
            position: fixed;
            margin: auto;
            z-index: 99;
            bottom: 1em;
            left: 1em;
            right: 1em;
            width: 20%;
            @media (min-width: 390px) and (max-width: 768px) {
              width: 100%;
            }
            .progress {
              margin: auto;
              height: 0.6em;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
          .custom-section {
            .b-overlay-wrap {
              .row {
                .suggest-loading {
                  // all of suggestion value
                  z-index: 99;
                  right: 2em;
                  top: 2.5em;
                  position: absolute; // inside form-input
                }
              }
            }
          }

          .row {
            .suggest-loading {
              // all of suggestion value
              z-index: 99;
              right: 2em;
              top: 2.5em;
              position: absolute; // inside form-input
            }
          }
          .mt-1 {
            .sal::v-deep .wizard-card-footer {
              margin-left: 15.6rem !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
            }
            .sal::v-deep .wizard-navigation {
              .wizard-nav {
                li {
                  margin-right: 0 !important;
                }
                width: 18.1rem !important;
                @media (min-width: 400px) and (max-width: 900px) {
                  width: 0 !important;
                }
              }
            }
            .sal-hide::v-deep .wizard-card-footer {
              margin-left: 15.6rem !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
              .wizard-footer-right {
                display: none;
              }
            }
            .sal-hide::v-deep .wizard-navigation {
              .wizard-nav {
                li {
                  margin-right: 0 !important;
                }
                width: 18.1rem !important;
                @media (min-width: 400px) and (max-width: 900px) {
                  width: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
