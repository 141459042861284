<!-- modal MHS -->
<template>
  <!-- modal login-->
  <b-modal
    id="modal-login"
    cancel-variant="outline-secondary"
    v-model="modalShow"
    cancel-title="Close"
    centered
    size="xl"
    :hide-footer="true"
    :title="title"
  >
    <!-- <span> headline {{ uri }} --- child of headling {{ uriSecond }} </span> -->
    <!-- {{ detailData }} -->
    <div class="divider my-2">
      <div class="divider-text">
        <small>Segment {{ detailData?.mh_pemilik_brg }}</small>
        <h3>
          {{ detailData?.kegiatan_hm.keg_name }}
          {{ detailData?.mh_name_barang }}
          <!-- {{ detailData?.mh_volume }}
          {{ detailData?.mh_satuan }} -->
        </h3>
        <div>
          Rencana Kegiatan:
          {{ dateHumanReadAble(detailData?.mh_rencana_keg) ?? '' }}
          <br />
          <strong>Signatured: {{ detailData?.user?.full_name }}</strong>
        </div>
        <!-- 1 March 2023 - 12:00 PM -->
      </div>
    </div>
    <YoTable
      :api-endpoint="uriSecond"
      sub-title-tbl="KEGIATAN JALUR SUNGAI HULU MIGAS"
      :fields="fields"
      :new-data-template="newDataTemplate"
      :resolve-fetch-response="resolveFetchResponse"
      :resolve-fetch-url="resolveFetchUrl"
      :bottom-label="
        this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
      "
      :forceDisableCrud="forceDisableCrud"
      bottom-icon="GitMergeIcon"
      info-page-ability="Manage ( All & SAL ) only"
      placeholder-search="Kegiatan Name"
      @render-component="renderComponent"
    />
  </b-modal>
</template>

<script>
import moment from 'moment'
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
// import YoTable from '@/views/pages/components/CompLightTable.vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    YoTable: () => import('@/views/pages/components/CompLightTable.vue')
    // YoTable
  },
  props: {
    forceDisableCrud: {
      type: Boolean,
      required: false,
      default: null
    },
    formChainHmID: {
      type: Number,
      required: false,
      default: null
    },
    uri: {
      type: String,
      required: true
    },
    uriSecond: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    }
    // payload: {
    //   type: [Object, Array],
    //   required: true
    // },
    // column: {
    //   type: [Object, Array],
    //   required: true
    // },
    // action: {
    //   type: String,
    //   required: false
    // },
    // dataID: {
    //   type: String,
    //   required: false
    // },
    // resolveFetchDetail: {
    //   type: Function,
    //   required: false
    // }
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  // mounted() {
  //   this.init()
  // },
  computed: {
    modalShow: {
      get() {
        return this.isModalActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeModal')
        }
      }
    },
    newDataTemplate() {
      return {
        mh_volume_rl: null,
        mh_satuan_rl: null,
        mh_equiptment_rl: null,
        mh_time_start_rl: null,
        mh_krj: 'splitChunk',
        mh_time_end_rl: null,
        form_chain_hm_id: this.formChainHmID,
        supervisi: 0,
        rigger: 0,
        tallyman: 0,
        hse: 0,
        jettyman: 0,
        security: 0
      }
    }
  },
  watch: {
    isModalActive: function () {
      if (this.isModalActive) {
        this.fetchDetail()
      }
      // if (this.action === 'is_edit') {
      //   /* fetch data */
      // this.fetchDetail()
      // } else {
      //   this.resetForm()
      // }
    }
  },
  data() {
    return {
      loadingFetch: false,
      detailData: null,
      // vol satuan
      // jml eq
      // start end
      fields: [
        {
          key: 'mh_type_rl',
          label: 'Proses (*)',
          sortable: true,
          placeholder: 'Proses',
          input: {
            type: 'select',
            options: [
              {
                label: 'STEVEDORING',
                value: 'STEVEDORING'
              },
              {
                label: 'CARGODORING',
                value: 'CARGODORING'
              }
            ],
            reduce({ value }) {
              return value
            }
          },
          searchModel: 'mh_type_rl',
          search: {
            type: 'text'
          },
          width: '12em',
          minWidth: '12em'
        },
        {
          key: 'mh_volume_rl',
          label: 'Volume (*)',
          placeholder: 'Volume (Hanya Angka)',
          sortable: true,
          input: { type: 'number-cleave', append: '' },
          searchModel: 'mh_volume',
          search: {
            type: 'number'
          },
          width: '9em',
          minWidth: '9em'
        },
        {
          key: 'mh_satuan_rl',
          label: 'Satuan (*)',
          sortable: true,
          placeholder: 'Satuan',
          input: {
            type: 'select',
            options: [
              {
                label: 'M3',
                value: 'M3'
              },
              {
                label: 'TON',
                value: 'TON'
              }
              // {
              //   label: 'KL',
              //   value: 'KL'
              // }
            ],
            reduce({ value }) {
              return value
            }
          },
          searchModel: 'mh_satuan_rl',
          search: {
            type: 'text'
          },
          width: '9em',
          minWidth: '9em'
        },
        // {
        //   key: 'mh_jml_tng_krj_rl',
        //   label: 'Jumlah Tenaga Kerja (*)',
        //   placeholder: 'Jumlah Tenaga Kerja (Hanya Angka)',
        //   sortable: true,
        //   input: { type: 'custom-hulu', append: '' },
        //   searchModel: 'mh_jml_tng_krj_rl',
        //   search: {
        //     type: 'number'
        //   },
        //   width: '10em',
        //   minWidth: '10em'
        // },
        {
          key: 'mh_krj',
          label: 'Jumlah Tenaga Kerja (*)',
          placeholder: 'Jumlah Tenaga Kerja (Hanya Angka)',
          sortable: true,
          input: { type: 'custom-hulu', append: '' },
          searchModel: 'mh_krj',
          search: {
            type: 'number'
          },
          width: '10em',
          minWidth: '10em',
          splitModel: {
            one: 'supervisi',
            two: 'rigger',
            three: 'tallyman',
            four: 'hse',
            five: 'jettyman',
            six: 'security'
          }
        },
        {
          key: 'mh_equiptment_rl',
          label: 'Equiptment (default)',
          sortable: true,
          placeholder: 'Equiptment',
          input: {
            type: 'select-multiple',
            options: [
              {
                label: 'CRANE',
                value: 'CRANE'
              },
              {
                label: 'FORKLIFT 3 TON',
                value: 'FORKLIFT 3 TON'
              },
              {
                label: 'FORKLIFT 5 TON',
                value: 'FORKLIFT 5 TON'
              },
              {
                label: 'FORKLIFT 7 TON',
                value: 'FORKLIFT 7 TON'
              },
              {
                label: 'FORKLIFT 10 TON',
                value: 'FORKLIFT 10 TON'
              },
              {
                label: 'TRONTON',
                value: 'TRONTON'
              }
            ],
            reduce({ value }) {
              return value
            }
          },
          searchModel: 'mh_equiptment_rl',
          search: {
            type: 'text'
          },
          width: '15em',
          minWidth: '15em'
        },
        {
          key: 'mh_time_start_rl',
          label: 'Waktu Mulai Berkegiatan *',
          sortable: true,
          placeholder: 'Waktu Mulai Berkegiatan *',
          searchModel: 'mh_time_start_rl',
          input: {
            type: 'time',
            format: 'D MMMM YYYY - HH:mm'
          },
          search: {
            type: 'date'
          },
          width: '16em',
          minWidth: '16em'
        },
        {
          key: 'mh_time_end_rl',
          label: 'Waktu Selesai Berkegiatan *',
          sortable: true,
          placeholder: 'Waktu Selesai Berkegiatan *',
          searchModel: 'mh_time_end_rl',
          input: {
            type: 'time',
            format: 'D MMMM YYYY - HH:mm'
          },
          search: {
            type: 'date'
          },
          width: '16em',
          minWidth: '16em'
        }
      ]
    }
  },
  // created() {
  //   this.fetchHeadline()
  // },
  methods: {
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - hh:mm A')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    renderComponent() {
      console.log('re render')
      this.$emit('render-component', 'TableMHInner')
    },
    resolveFetchResponse(response) {
      return response.data.rel_keg_mhs
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.uriSecond,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    },
    fetchDetail() {
      this.loadingFetch = true
      useJwt.http
        .get(`${this.uri}`)
        .then((res) => {
          this.detailData = res.data.fhm_keg_manh
          this.loadingFetch = false
        })
        .catch((e) => {
          console.log('error', e)
          this.$alert({
            title: `Error`,
            text: `Something wrong ${e}`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
          this.loadingFetch = false
        })
    }
  }
}
</script>
<style lang="scss">
#modal-login {
  .card {
    .custom-card {
      .custom-table {
        .custom-field {
          .custom-yo-select {
            .vs__dropdown-menu {
              max-height: 13em !important;
              position: relative !important;
              font-weight: 700 !important;
            }
          }
        }
      }
    }
  }
}
</style>
