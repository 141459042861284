<template>
  <!-- TODO : Change Name File to JettyPosition instead of NestedIO -->
  <div style="width: 100%">
    <b-card-text v-if="!loadingFetch" style="width: 100%">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row
            v-for="(item, index) in compColumnRepeat"
            :id="item.fe_id"
            :key="item.fe_id"
            ref="rowColumnRepeat"
            class="repeater-form ml-0 mr-0 mb-2"
          >
            <b-col cols="1">
              <feather-icon
                icon="FlagIcon"
                size="40"
                class="text-info"
                :badge="` ${index + 1}`"
                :style="{ cursor: 'pointer' }"
                badge-classes="badge-info badge-glow"
              />
            </b-col>
            <b-col cols="10">
              <div class="divider my-2" v-if="index === 0">
                <div class="divider-text">
                  <h4>Awal Sandar</h4>
                </div>
              </div>
              <div class="divider my-2" v-else-if="index === 1">
                <div class="divider-text">
                  <h4>Kapal Pindah Jetty</h4>
                </div>
              </div>
              <div class="divider my-2" v-else>
                <div class="divider-text">
                  <h4>Kapal Pindah Jetty</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="1" class="">
              <feather-icon
                icon="XCircleIcon"
                size="40"
                class="text-danger"
                badge=""
                badge-classes="badge-danger badge-glow"
                @click="removeItemColumnRepeat(index)"
                v-if="!modeSummary"
                :style="{ cursor: 'pointer' }"
              />
              <feather-icon
                v-else
                icon="FlagIcon"
                size="40"
                class="text-info d-none d-sm-block"
                :badge="index + 1"
                :style="{ cursor: 'pointer' }"
                badge-classes="badge-info badge-glow d-none d-sm-block"
              />
            </b-col>

            <b-col md="3" v-if="index !== 0 && !modeSummary">
              <validation-provider
                #default="validationContext"
                name="Posisi Awal *"
                vid="Posisi Awal *"
                rules="required"
              >
                <b-form-group label="Posisi Awal *" label-for="Posisi Awal *">
                  <v-select
                    id="Posisi Awal *"
                    ref="select"
                    v-model="item.posisi_awal"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jetty_positions"
                    :reduce="(option) => option.value"
                    :selectable="(option) => !option.value.includes('null')"
                    :state="getValidationState(validationContext)"
                    placeholder="Posisi Awal *"
                    label="text"
                    item-value="value"
                    item-text="text"
                    @search:focus="onFocusJetty()"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!item.posisi_awal"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <span slot="no-options">{{ $t('no_data') }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3" v-if="index !== 0 && !modeSummary">
              <validation-provider
                #default="validationContext"
                name="Posisi Awal Baris *"
                vid="Posisi Awal Baris *"
                rules="required"
              >
                <b-form-group
                  label="Posisi Awal Baris *"
                  label-for="Posisi Awal Baris *"
                >
                  <v-select
                    id="Posisi Awal Baris *"
                    ref="select"
                    v-model="item.posisi_awal_baris"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jetty_baris_positions"
                    placeholder="Posisi Awal Baris *"
                    :reduce="(option) => option.value"
                    :selectable="(option) => !option.value.includes('null')"
                    :state="getValidationState(validationContext)"
                    label="text"
                    item-value="value"
                    item-text="text"
                    @search="
                      (keyword, loading) => fetchOptions(keyword, loading)
                    "
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!item.posisi_awal_baris"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <span slot="no-options">{{ $t('no_data') }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col :md="index === 0 ? '6' : '3'" v-if="!modeSummary">
              <validation-provider
                #default="validationContext"
                name="Posisi Sekarang *"
                vid="Posisi Sekarang *"
                rules="required"
              >
                <b-form-group
                  label="Posisi Sekarang *"
                  label-for="Posisi Sekarang *"
                >
                  <v-select
                    id="Posisi Sekarang *"
                    ref="select"
                    v-model="item.posisi_skrg"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="jetty_positions"
                    :reduce="(option) => option.value"
                    :selectable="(option) => !option.value.includes('null')"
                    :state="getValidationState(validationContext)"
                    placeholder="Posisi Sekarang *"
                    label="text"
                    item-value="value"
                    item-text="text"
                    @search:focus="onFocusJetty()"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!item.posisi_skrg"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <span slot="no-options">{{ $t('no_data') }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col :md="index === 0 ? '6' : '3'" v-if="!modeSummary">
              <validation-provider
                #default="validationContext"
                name="Posisi Sekarang Baris *"
                vid="Posisi Sekarang Baris *"
                rules="required"
              >
                <b-form-group
                  label="Posisi Sekarang Baris *"
                  label-for="Posisi Sekarang Baris *"
                >
                  <v-select
                    id="Posisi Sekarang Baris *"
                    ref="select"
                    v-model="item.posisi_skrg_baris"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="[
                      {
                        value: 'null',
                        text: 'Posisi Baris'
                      },
                      {
                        value: 'Baris 1',
                        text: 'Baris 1'
                      },
                      {
                        value: 'Baris 2',
                        text: 'Baris 2'
                      },
                      {
                        value: 'Baris 3',
                        text: 'Baris 3'
                      }
                    ]"
                    placeholder="Posisi Sekarang Baris *"
                    :reduce="(option) => option.value"
                    :selectable="(option) => !option.value.includes('null')"
                    :state="getValidationState(validationContext)"
                    label="text"
                    item-value="value"
                    item-text="text"
                    @search="
                      (keyword, loading) => fetchOptions(keyword, loading)
                    "
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!item.posisi_skrg_baris"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <span slot="no-options">{{ $t('no_data') }}</span>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" v-if="index !== 0 && !modeSummary">
              <validation-provider
                #default="validationContext"
                name="Alasan *"
                vid="Alasan *"
                rules="required"
              >
                <b-form-group label="Alasan Pindah *" label-for="Alasan *">
                  <b-form-input
                    class="form-control"
                    id="Alasan *"
                    v-model="item.reason"
                    placeholder="Alasan *"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" v-if="index !== 0 && !modeSummary">
              <validation-provider
                #default="validationContext"
                name="Waktu Pindah *"
                vid="Waktu Pindah *"
                :rules="{
                  required: true
                }"
              >
                <b-form-group label="Waktu Pindah *" label-for="Waktu Pindah *">
                  <flat-pickr
                    id="Waktu Pindah *"
                    v-model="item.waktu_pindah"
                    placeholder="Waktu Pindah *"
                    class="form-control"
                    static="true"
                    :config="dpconfig"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- summary mode here -->
            <div
              class="p-0 col col-md-4 col-sm-12 col-xl-3 col-xs-12 d-none d-sm-block d-sm-none d-md-block"
            >
              <span v-if="index !== 0 && modeSummary">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="GitPullRequestIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Posisi Awal') }}
                      </span>
                    </th>
                    <td class="pb-50">: {{ item.posisi_awal }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="GitPullRequestIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Posisi Awal Baris') }}
                      </span>
                    </th>
                    <td class="pb-50">: {{ item.posisi_awal_baris }}</td>
                  </tr>
                </table>
              </span>
            </div>
            <div
              class="p-0 col col-md-4 d-none d-sm-block d-sm-none d-md-block"
            >
              <span v-if="modeSummary" style="width: 100%">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="GitCommitIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Posisi Sekarang') }}
                      </span>
                    </th>
                    <td class="pb-50">: {{ item.posisi_skrg }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="GitCommitIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Posisi Sekarang Baris') }}
                      </span>
                    </th>
                    <td class="pb-50">: {{ item.posisi_skrg_baris }}</td>
                  </tr>
                </table>
              </span>
            </div>
            <div
              class="p-0 col col-md-4 d-none d-sm-block d-sm-none d-md-block"
            >
              <span v-if="index !== 0 && modeSummary">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="TwitterIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Alasan Pindah') }}
                      </span>
                    </th>
                    <td class="pb-50">: {{ item.reason }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="ClockIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Waktu Pindah') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      : {{ formatDateTime(item.waktu_pindah) }}
                    </td>
                  </tr>
                </table>
              </span>
            </div>
            <div
              class="col-md-4 d-block d-sm-none .d-none d-sm-block d-md-none"
            >
              <!-- mobile view -->
              <span v-if="index !== 0 && modeSummary">
                <strong>Posisi Awal</strong>
                :
                {{
                  ['jetty'].includes(item.posisi_awal)
                    ? item.posisi_awal
                    : `Jetty ${item.posisi_awal}`
                }}
                {{ item.posisi_awal_baris }}
              </span>
              <span v-if="modeSummary">
                <hr />
                <feather-icon icon="GitCommitIcon" class="mr-75" />
                <strong>Posisi Sekarang</strong>
                :
                {{
                  ['jetty'].includes(item.posisi_skrg)
                    ? item.posisi_skrg
                    : `Jetty ${item.posisi_skrg}`
                }}
                {{ item.posisi_skrg_baris }}
              </span>
            </div>

            <!-- <div class="col col-md-6 col-sm-12 mx-auto"></div> -->
            <!-- summary mode here -->
          </b-row>

          <!-- END OF LOOPING FIELD -->
          <b-row>
            <b-col lg="12" md="12" cols="12" v-if="!modeSummary">
              <b-button
                v-if="$store.state.app.roleName !== 'viewer'"
                size="md"
                variant="outline-primary"
                block
                :disabled="$store.state.app.roleName === 'viewer'"
                class="mb-2"
                @click="repeatAgainColumn"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Tambah Realisasi Pindah Kapal</span>
              </b-button>
            </b-col>
          </b-row>
          <!-- Save User -->
          <div class="text-right" v-if="$store.state.app.roleName !== 'viewer'">
            <!-- type="reset" -->

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              v-if="!modeSummary"
              variant="primary"
              :disabled="
                !disableAction && !$store.state.app.roleName === 'jetty man'
              "
              @click="onSubmitClick"
            >
              <feather-icon v-if="!loadingOK" icon="SaveIcon" />

              <b-spinner v-if="loadingOK" small />
              <span v-text="buttonOkTitle"></span>
            </b-button>
            <p></p>
            <span v-if="!modeSummary">
              <b-alert
                v-if="!disableAction"
                variant="warning"
                show
                class="mb-0"
              >
                <div class="alert-body">
                  {{ disableAction }}Belum mengisi waktu sandar
                </div>
              </b-alert>
              <b-alert v-if="disableAction" variant="primary" show class="mb-0">
                <div class="alert-body" v-if="compColumnRepeat.length <= 1">
                  <strong>Meyimpan</strong>, Akan memberikan informasi
                  <strong>Kapal sandar</strong>
                </div>
                <div class="alert-body" v-else>
                  <strong>Meyimpan</strong>, Akan memberikan informasi
                  <strong>Kapal pindah jetty</strong>
                </div>
              </b-alert>
            </span>
          </div>
        </b-form>
      </validation-observer>
    </b-card-text>
    <b-card-text v-else> <b-spinner small /> Loading. . . </b-card-text>
  </div>
</template>
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
  BDropdown,
  BDropdownItem,
  BLink,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { ref, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import { VueAutosuggest } from 'vue-autosuggest'
import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { t } from '@/@core/libs/i18n/utils'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    VueAutosuggest,
    Indonesian,
    flatPickr,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BDropdown,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    OverlayLoading,
    VDropzone
  },
  props: {
    modeSummary: {
      type: Boolean,
      required: false,
      default: false
    },
    uri: {
      // API LOAD
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: false,
      default: null
    },
    model: {
      type: String,
      required: true
    },
    sandarTime: {
      type: String,
      required: false
    },
    posisi: {
      type: [Object, Array],
      required: true
    },
    posisiBaris: {
      type: [Object, Array],
      required: true
    },
    payload: {
      type: [Object, Array],
      required: true
    },
    columnRepeat: {
      type: [Object, Array],
      required: true
    },
    buttonOkTitle: {
      type: String,
      required: false
    },
    resolveFetch: {
      type: Function,
      required: false
    },
    disableAction: {
      type: Boolean,
      required: false
    }
  },
  directives: {
    Ripple
  },
  data() {
    return {
      required,
      dataResetDebounce: null,
      dataColumnRepeat: this.columnRepeat,
      repeatFlagNumber: 1,
      loadingOK: false,
      loadingFetch: false,
      dpconfig: {
        wrap: true,
        enableTime: true,
        time_24hr: false,
        altInput: true,
        altFormat: 'j F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null,
        allowInput: false // prevent "readonly" prop
      },
      jetty_positions: this.posisi,
      jetty_baris_positions: this.posisiBaris
    }
  },
  watch: {},
  setup(props, { emit, attrs, slots, expose }) {
    const blankPayload = props.payload /* ARRAY */
    const strBlankPayload = JSON.stringify(blankPayload)

    const payloadModel = ref(JSON.parse(strBlankPayload))
    const resetPayload = () => {
      payloadModel.value = JSON.parse(strBlankPayload)
    }
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPayload)

    const onSubmit = () => {
      //
    }
    return {
      payloadModel,
      onSubmit,
      resetForm,

      refFormObserver,
      getValidationState
    }
  },
  computed: {
    compColumnRepeat: {
      get() {
        console.log('this.dataColumnRepeat::', this.dataColumnRepeat)
        console.log('this.dataColumnRepeat::', this.columnRepeat)
        if (this.dataColumnRepeat.length === 0) {
          this.dataColumnRepeat = this.columnRepeat
          return this.compColumnRepeat
        }
        return this.dataColumnRepeat
      },
      set(data) {
        this.dataColumnRepeat = data
      }
    }
  },
  created() {
    // this.compColumnRepeat = this.columnRepeat
    if (this.modelId) {
      this.fetchDetail()
    }
  },
  methods: {
    moment,
    formatDateTime(value, format = 'D MMMM YYYY - hh:mm A') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    onFocusJetty() {
      if (this.jetty_positions.length === 0) {
        this.fetchJetty()
      }
    },
    /* JWT PROCESSING | FETCH DATA */
    async fetchJetty() {
      // this.fetchLoading = true
      await useJwt.http.get('jetty_position').then((response) => {
        const data = response.data.jetty_positions.map((list) => ({
          ...list,
          text: list.position,
          value: list.position
        }))
        this.jetty_positions = data
        this.jetty_positions.unshift({
          value: 'null',
          text: 'Pilih Posisi Jetty'
        })
        // this.fetchLoading = false
      })
    },
    onSubmitClick() {
      this.loadingOK = true
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            /* DTO */
            useJwt.http
              .put(`${this.uri}/${this.modelId}/${this.model}`, {
                realisasi_posisi_kapal: this.dataColumnRepeat
              })
              .then((response) => {
                if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
                this.dataResetDebounce = setTimeout(() => {
                  this.$swal({
                    title: 'Good job!',
                    html: 'Berhasil merealisasikan posisi kapal',
                    icon: 'success',
                    confirmButtonText: 'Lanjutkan Ubah Data',
                    cancelButtonText: 'Kembali',
                    showCancelButton: true,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-outline-warning ml-1'
                    },
                    buttonsStyling: false
                  }).then(async (result) => {
                    if (result.value) {
                      this.fetchDetail()
                      this.$emit('alreadyRealisasi', '')
                    } else {
                      if (this.timeoutDebounce)
                        clearTimeout(this.timeoutDebounce)
                      this.timeoutDebounce = setTimeout(
                        () => this.$router.go(-1),
                        300
                      )
                    }
                  })
                  this.loadingOK = false
                }, 300)

                const len = this.dataColumnRepeat.length - 1
                const time = this.dataColumnRepeat[len].waktu_pindah
                const reason = this.dataColumnRepeat[len].reason
                const pos1 = this.dataColumnRepeat[len].posisi_skrg
                const pos2 = this.dataColumnRepeat[len].posisi_skrg_baris

                if (this.dataColumnRepeat.length > 1) {
                  this.$emit('sentNotification', {
                    action: `Pindah Jetty (${reason})`,
                    timeIfPindah: time,
                    positionIfPindah: `#POSISI_JETTY_${pos1} #${pos2}`
                  })
                } else {
                  console.log('kapal sandar:::')
                  this.$emit('sentNotification', {
                    action: 'Telah Sandar',
                    timeIfPindah: this.sandarTime,
                    positionIfPindah: `#POSISI_JETTY_${pos1} #${pos2}`
                  })
                }
              })
              .catch((error) => {
                if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
                this.dataResetDebounce = setTimeout(() => {
                  this.loadingOK = false
                  // this.$emit('closeModal', true, error)
                }, 300)
              })
          } else {
            if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
            this.dataResetDebounce = setTimeout(() => {
              this.loadingOK = false
            }, 300)
          }
        })
        .catch((error) => {
          console.log(error)
          if (this.dataResetDebounce) clearTimeout(this.dataResetDebounce)
          this.dataResetDebounce = setTimeout(() => {
            this.loadingOK = false
          }, 300)
        })
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    dateHumanReadAble(value) {
      const format = moment(value).format('D MMMM YYYY - hh:mm A')
      if (format === 'Invalid date') {
        return 'Belum Diisi ❗️'
      }
      return format
    },
    fetchDetail() {
      this.loadingFetch = true
      useJwt.http
        .get(`${this.uri}/${this.modelId}/${this.model}`)
        .then((res) => {
          const data = this.resolveFetch(res)
          this.dataColumnRepeat = data
          this.loadingFetch = false
          this.$emit('alreadyFetch', data)
        })
        .catch((e) => {
          console.log('error', e)
          this.$alert({
            title: `Error`,
            text: `Something wrong ${e}`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
          this.loadingFetch = false
        })
    },
    repeatAgainColumn() {
      const len = this.dataColumnRepeat.length - 1
      this.dataColumnRepeat.push({
        fe_id: (this.repeatFlagNumber += this.repeatFlagNumber),
        posisi_awal: this.dataColumnRepeat[len]?.posisi_skrg || null,
        posisi_awal_baris: this.dataColumnRepeat[len]?.posisi_skrg_baris || null
      })
    },
    removeItemColumnRepeat(index) {
      if (this.$store.state.app.roleName === 'viewer') {
        this.$swal({
          position: 'top-end',
          icon: 'warning',
          title: 'You dont have permission',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        return
      }
      this.dataColumnRepeat.splice(index, 1)
    }
  }
}
</script>
<style>
table#table-transition .flip-list-move {
  transition: transform 0.4s;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>
