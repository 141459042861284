var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [!_vm.loadingFetch ? _c('b-card-text', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_vm._l(_vm.compColumnRepeat, function (item, index) {
          return _c('b-row', {
            key: item.fe_id,
            ref: "rowColumnRepeat",
            refInFor: true,
            staticClass: "repeater-form ml-0 mr-0 mb-2",
            attrs: {
              "id": item.fe_id
            }
          }, [_c('b-col', {
            attrs: {
              "cols": "1"
            }
          }, [_c('feather-icon', {
            staticClass: "text-info",
            style: {
              cursor: 'pointer'
            },
            attrs: {
              "icon": "FlagIcon",
              "size": "40",
              "badge": " ".concat(index + 1),
              "badge-classes": "badge-info badge-glow"
            }
          })], 1), _c('b-col', {
            attrs: {
              "cols": "10"
            }
          }, [index === 0 ? _c('div', {
            staticClass: "divider my-2"
          }, [_c('div', {
            staticClass: "divider-text"
          }, [_c('h4', [_vm._v("Awal Sandar")])])]) : index === 1 ? _c('div', {
            staticClass: "divider my-2"
          }, [_c('div', {
            staticClass: "divider-text"
          }, [_c('h4', [_vm._v("Kapal Pindah Jetty")])])]) : _c('div', {
            staticClass: "divider my-2"
          }, [_c('div', {
            staticClass: "divider-text"
          }, [_c('h4', [_vm._v("Kapal Pindah Jetty")])])])]), _c('b-col', {
            attrs: {
              "cols": "1"
            }
          }, [!_vm.modeSummary ? _c('feather-icon', {
            staticClass: "text-danger",
            style: {
              cursor: 'pointer'
            },
            attrs: {
              "icon": "XCircleIcon",
              "size": "40",
              "badge": "",
              "badge-classes": "badge-danger badge-glow"
            },
            on: {
              "click": function click($event) {
                return _vm.removeItemColumnRepeat(index);
              }
            }
          }) : _c('feather-icon', {
            staticClass: "text-info d-none d-sm-block",
            style: {
              cursor: 'pointer'
            },
            attrs: {
              "icon": "FlagIcon",
              "size": "40",
              "badge": index + 1,
              "badge-classes": "badge-info badge-glow d-none d-sm-block"
            }
          })], 1), index !== 0 && !_vm.modeSummary ? _c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Posisi Awal *",
              "vid": "Posisi Awal *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Posisi Awal *",
                    "label-for": "Posisi Awal *"
                  }
                }, [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "Posisi Awal *",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "options": _vm.jetty_positions,
                    "reduce": function reduce(option) {
                      return option.value;
                    },
                    "selectable": function selectable(option) {
                      return !option.value.includes('null');
                    },
                    "state": _vm.getValidationState(validationContext),
                    "placeholder": "Posisi Awal *",
                    "label": "text",
                    "item-value": "value",
                    "item-text": "text"
                  },
                  on: {
                    "search:focus": function searchFocus($event) {
                      return _vm.onFocusJetty();
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref2) {
                      var attributes = _ref2.attributes,
                        events = _ref2.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !item.posisi_awal
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: item.posisi_awal,
                    callback: function callback($$v) {
                      _vm.$set(item, "posisi_awal", $$v);
                    },
                    expression: "item.posisi_awal"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])]), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), index !== 0 && !_vm.modeSummary ? _c('b-col', {
            attrs: {
              "md": "3"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Posisi Awal Baris *",
              "vid": "Posisi Awal Baris *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Posisi Awal Baris *",
                    "label-for": "Posisi Awal Baris *"
                  }
                }, [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "Posisi Awal Baris *",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "options": _vm.jetty_baris_positions,
                    "placeholder": "Posisi Awal Baris *",
                    "reduce": function reduce(option) {
                      return option.value;
                    },
                    "selectable": function selectable(option) {
                      return !option.value.includes('null');
                    },
                    "state": _vm.getValidationState(validationContext),
                    "label": "text",
                    "item-value": "value",
                    "item-text": "text"
                  },
                  on: {
                    "search": function search(keyword, loading) {
                      return _vm.fetchOptions(keyword, loading);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref3) {
                      var attributes = _ref3.attributes,
                        events = _ref3.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !item.posisi_awal_baris
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: item.posisi_awal_baris,
                    callback: function callback($$v) {
                      _vm.$set(item, "posisi_awal_baris", $$v);
                    },
                    expression: "item.posisi_awal_baris"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])]), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), !_vm.modeSummary ? _c('b-col', {
            attrs: {
              "md": index === 0 ? '6' : '3'
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Posisi Sekarang *",
              "vid": "Posisi Sekarang *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Posisi Sekarang *",
                    "label-for": "Posisi Sekarang *"
                  }
                }, [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "Posisi Sekarang *",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "options": _vm.jetty_positions,
                    "reduce": function reduce(option) {
                      return option.value;
                    },
                    "selectable": function selectable(option) {
                      return !option.value.includes('null');
                    },
                    "state": _vm.getValidationState(validationContext),
                    "placeholder": "Posisi Sekarang *",
                    "label": "text",
                    "item-value": "value",
                    "item-text": "text"
                  },
                  on: {
                    "search:focus": function searchFocus($event) {
                      return _vm.onFocusJetty();
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref4) {
                      var attributes = _ref4.attributes,
                        events = _ref4.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !item.posisi_skrg
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: item.posisi_skrg,
                    callback: function callback($$v) {
                      _vm.$set(item, "posisi_skrg", $$v);
                    },
                    expression: "item.posisi_skrg"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])]), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), !_vm.modeSummary ? _c('b-col', {
            attrs: {
              "md": index === 0 ? '6' : '3'
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Posisi Sekarang Baris *",
              "vid": "Posisi Sekarang Baris *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Posisi Sekarang Baris *",
                    "label-for": "Posisi Sekarang Baris *"
                  }
                }, [_c('v-select', {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    "id": "Posisi Sekarang Baris *",
                    "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                    "options": [{
                      value: 'null',
                      text: 'Posisi Baris'
                    }, {
                      value: 'Baris 1',
                      text: 'Baris 1'
                    }, {
                      value: 'Baris 2',
                      text: 'Baris 2'
                    }, {
                      value: 'Baris 3',
                      text: 'Baris 3'
                    }],
                    "placeholder": "Posisi Sekarang Baris *",
                    "reduce": function reduce(option) {
                      return option.value;
                    },
                    "selectable": function selectable(option) {
                      return !option.value.includes('null');
                    },
                    "state": _vm.getValidationState(validationContext),
                    "label": "text",
                    "item-value": "value",
                    "item-text": "text"
                  },
                  on: {
                    "search": function search(keyword, loading) {
                      return _vm.fetchOptions(keyword, loading);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "search",
                    fn: function fn(_ref5) {
                      var attributes = _ref5.attributes,
                        events = _ref5.events;
                      return [_c('input', _vm._g(_vm._b({
                        staticClass: "vs__search",
                        attrs: {
                          "required": !item.posisi_skrg_baris
                        }
                      }, 'input', attributes, false), events))];
                    }
                  }], null, true),
                  model: {
                    value: item.posisi_skrg_baris,
                    callback: function callback($$v) {
                      _vm.$set(item, "posisi_skrg_baris", $$v);
                    },
                    expression: "item.posisi_skrg_baris"
                  }
                }, [_c('span', {
                  attrs: {
                    "slot": "no-options"
                  },
                  slot: "no-options"
                }, [_vm._v(_vm._s(_vm.$t('no_data')))])]), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), index !== 0 && !_vm.modeSummary ? _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Alasan *",
              "vid": "Alasan *",
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Alasan Pindah *",
                    "label-for": "Alasan *"
                  }
                }, [_c('b-form-input', {
                  staticClass: "form-control",
                  attrs: {
                    "id": "Alasan *",
                    "placeholder": "Alasan *",
                    "state": _vm.getValidationState(validationContext),
                    "trim": ""
                  },
                  model: {
                    value: item.reason,
                    callback: function callback($$v) {
                      _vm.$set(item, "reason", $$v);
                    },
                    expression: "item.reason"
                  }
                }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), index !== 0 && !_vm.modeSummary ? _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Waktu Pindah *",
              "vid": "Waktu Pindah *",
              "rules": {
                required: true
              }
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  attrs: {
                    "label": "Waktu Pindah *",
                    "label-for": "Waktu Pindah *"
                  }
                }, [_c('flat-pickr', {
                  staticClass: "form-control",
                  attrs: {
                    "id": "Waktu Pindah *",
                    "placeholder": "Waktu Pindah *",
                    "static": "true",
                    "config": _vm.dpconfig,
                    "state": _vm.getValidationState(validationContext)
                  },
                  model: {
                    value: item.waktu_pindah,
                    callback: function callback($$v) {
                      _vm.$set(item, "waktu_pindah", $$v);
                    },
                    expression: "item.waktu_pindah"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
              }
            }], null, true)
          })], 1) : _vm._e(), _c('div', {
            staticClass: "p-0 col col-md-4 col-sm-12 col-xl-3 col-xs-12 d-none d-sm-block d-sm-none d-md-block"
          }, [index !== 0 && _vm.modeSummary ? _c('span', [_c('table', {
            staticClass: "mt-2 mt-xl-0 w-100"
          }, [_c('tr', [_c('th', {
            staticClass: "pb-50"
          }, [_c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "GitPullRequestIcon"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Awal')) + " ")])], 1), _c('td', {
            staticClass: "pb-50"
          }, [_vm._v(": " + _vm._s(item.posisi_awal))])]), _c('tr', [_c('th', {
            staticClass: "pb-50"
          }, [_c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "GitPullRequestIcon"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Awal Baris')) + " ")])], 1), _c('td', {
            staticClass: "pb-50"
          }, [_vm._v(": " + _vm._s(item.posisi_awal_baris))])])])]) : _vm._e()]), _c('div', {
            staticClass: "p-0 col col-md-4 d-none d-sm-block d-sm-none d-md-block"
          }, [_vm.modeSummary ? _c('span', {
            staticStyle: {
              "width": "100%"
            }
          }, [_c('table', {
            staticClass: "mt-2 mt-xl-0 w-100"
          }, [_c('tr', [_c('th', {
            staticClass: "pb-50"
          }, [_c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "GitCommitIcon"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Sekarang')) + " ")])], 1), _c('td', {
            staticClass: "pb-50"
          }, [_vm._v(": " + _vm._s(item.posisi_skrg))])]), _c('tr', [_c('th', {
            staticClass: "pb-50"
          }, [_c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "GitCommitIcon"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Sekarang Baris')) + " ")])], 1), _c('td', {
            staticClass: "pb-50"
          }, [_vm._v(": " + _vm._s(item.posisi_skrg_baris))])])])]) : _vm._e()]), _c('div', {
            staticClass: "p-0 col col-md-4 d-none d-sm-block d-sm-none d-md-block"
          }, [index !== 0 && _vm.modeSummary ? _c('span', [_c('table', {
            staticClass: "mt-2 mt-xl-0 w-100"
          }, [_c('tr', [_c('th', {
            staticClass: "pb-50"
          }, [_c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "TwitterIcon"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(_vm.$t('Alasan Pindah')) + " ")])], 1), _c('td', {
            staticClass: "pb-50"
          }, [_vm._v(": " + _vm._s(item.reason))])]), _c('tr', [_c('th', {
            staticClass: "pb-50"
          }, [_c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "ClockIcon"
            }
          }), _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Pindah')) + " ")])], 1), _c('td', {
            staticClass: "pb-50"
          }, [_vm._v(" : " + _vm._s(_vm.formatDateTime(item.waktu_pindah)) + " ")])])])]) : _vm._e()]), _c('div', {
            staticClass: "col-md-4 d-block d-sm-none .d-none d-sm-block d-md-none"
          }, [index !== 0 && _vm.modeSummary ? _c('span', [_c('strong', [_vm._v("Posisi Awal")]), _vm._v(" : " + _vm._s(['jetty'].includes(item.posisi_awal) ? item.posisi_awal : "Jetty ".concat(item.posisi_awal)) + " " + _vm._s(item.posisi_awal_baris) + " ")]) : _vm._e(), _vm.modeSummary ? _c('span', [_c('hr'), _c('feather-icon', {
            staticClass: "mr-75",
            attrs: {
              "icon": "GitCommitIcon"
            }
          }), _c('strong', [_vm._v("Posisi Sekarang")]), _vm._v(" : " + _vm._s(['jetty'].includes(item.posisi_skrg) ? item.posisi_skrg : "Jetty ".concat(item.posisi_skrg)) + " " + _vm._s(item.posisi_skrg_baris) + " ")], 1) : _vm._e()])], 1);
        }), _c('b-row', [!_vm.modeSummary ? _c('b-col', {
          attrs: {
            "lg": "12",
            "md": "12",
            "cols": "12"
          }
        }, [_vm.$store.state.app.roleName !== 'viewer' ? _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "size": "md",
            "variant": "outline-primary",
            "block": "",
            "disabled": _vm.$store.state.app.roleName === 'viewer'
          },
          on: {
            "click": _vm.repeatAgainColumn
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _c('span', [_vm._v("Tambah Realisasi Pindah Kapal")])], 1) : _vm._e()], 1) : _vm._e()], 1), _vm.$store.state.app.roleName !== 'viewer' ? _c('div', {
          staticClass: "text-right"
        }, [!_vm.modeSummary ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "type": "submit",
            "variant": "primary",
            "disabled": !_vm.disableAction && !_vm.$store.state.app.roleName === 'jetty man'
          },
          on: {
            "click": _vm.onSubmitClick
          }
        }, [!_vm.loadingOK ? _c('feather-icon', {
          attrs: {
            "icon": "SaveIcon"
          }
        }) : _vm._e(), _vm.loadingOK ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.buttonOkTitle)
          }
        })], 1) : _vm._e(), _c('p'), !_vm.modeSummary ? _c('span', [!_vm.disableAction ? _c('b-alert', {
          staticClass: "mb-0",
          attrs: {
            "variant": "warning",
            "show": ""
          }
        }, [_c('div', {
          staticClass: "alert-body"
        }, [_vm._v(" " + _vm._s(_vm.disableAction) + "Belum mengisi waktu sandar ")])]) : _vm._e(), _vm.disableAction ? _c('b-alert', {
          staticClass: "mb-0",
          attrs: {
            "variant": "primary",
            "show": ""
          }
        }, [_vm.compColumnRepeat.length <= 1 ? _c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_vm._v("Meyimpan")]), _vm._v(", Akan memberikan informasi "), _c('strong', [_vm._v("Kapal sandar")])]) : _c('div', {
          staticClass: "alert-body"
        }, [_c('strong', [_vm._v("Meyimpan")]), _vm._v(", Akan memberikan informasi "), _c('strong', [_vm._v("Kapal pindah jetty")])])]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 2)];
      }
    }], null, false, 20972371)
  })], 1) : _c('b-card-text', [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading. . . ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }