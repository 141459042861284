<template>
  <YoTable
    ref="TableMhInner"
    ref-specify="TableMhInner"
    :api-endpoint="apiEndpoint"
    sub-title-tbl="KEGIATAN JALUR SUNGAI HULU MIGAS"
    :fields="fields"
    :new-data-template="newDataTemplate"
    :resolve-fetch-response="resolveFetchResponse"
    :resolve-fetch-url="resolveFetchUrl"
    :bottom-label="
      this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~'
    "
    :forceDisableCrud="disableCrudX"
    :specifyCrud="true"
    bottom-icon="GitMergeIcon"
    info-page-ability="Manage ( All & SAL ) only"
    placeholder-search="Kegiatan Name"
    @render-component="renderComponent"
    :fhm-i-d="fhmID"
    :form-chain-hm-i-d="formChainHmID"
    :is-export-mode="true"
    :data-high-light="dataHighLight"
  />
</template>

<script>
import moment from 'moment'
import YoTable from '@/views/pages/components/CompLightTable.vue'

export default {
  components: {
    YoTable
  },
  props: {
    kegiatanSelectData: {
      type: String,
      default: ['Bongkar', 'Muat']
    },
    fhmID: {
      type: Number,
      required: false,
      default: null
    },
    formChainHmID: {
      type: Number,
      required: false,
      default: null
    },
    disableCrud: {
      type: String,
      required: false,
      default: null
    },
    dataHighLight: {
      type: [Object, Array],
      required: false,
      default: null
    }
  },
  data() {
    return {
      apiEndpoint: `v1/fhm_keg_manh/${this.fhmID}`,
      fields: [],
      disableCrudMutation: this.disableCrud
    }
  },
  computed: {
    newDataTemplate() {
      return {
        mh_rencana_keg: null,
        mh_rencana_keg_start: null,
        mh_rencana_keg_end: null,
        kegiatan_hm_id: null,
        kegiatan_hm: {
          keg_name: null
        }
      }
    },
    disableCrudX() {
      if (
        // this.disableCrudMutation === 'KAPAL BERANGKAT' ||
        this.disableCrudMutation === 'KEGIATAN DIBATALKAN' ||
        this.$store.state.app.roleName === 'jetty man' ||
        this.$store.state.app.roleName === 'viewer'
      ) {
        // if (this.$can('manage', 'all')) {
        //   return false
        // } else {
        //   return true
        // }
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    console.log('kegiatan mounted', this.kegiatanSelectData)
    this.init()
  },
  methods: {
    moment,
    init() {
      console.log('init')
      this.initFields(this.kegiatanSelectData)
    },
    renderComponent() {
      console.log('re render')
      this.$emit('render-component', 'TableMhInner')
    },
    initFields(dataku = null) {
      if (dataku) {
        this.fields = [
          {
            key: 'mh_action',
            label: 'Action',
            width: '10em',
            minWidth: '10em'
          },
          // {
          //   searchModel: 'kegiatan_hm.keg_name',
          //   search: {
          //     type: 'text'
          //   },
          //   key: 'kegiatan_hm.keg_name',
          //   columnKey: 'kegiatan_hm_id',
          //   label: 'Kegiatan (*)',
          //   placeholder: 'Pilih Kegiatan',
          //   sortable: true,
          //   input: {
          //     type: 'select',
          //     requestAPI: 'allow',
          //     optionsApiEndpoint: 'kegiatan_hm',
          //     autoLoad: true,
          //     resolveOptionsResponse(response) {
          //       return response.data.kegiatan_hms
          //         .filter(
          //           (data) =>
          //             // Object.values(dataku.split(',')).includes(data.keg_name)
          //             !['TAMBAT'].includes(data.keg_name)
          //         )
          //         .map((value) => ({
          //           label: `${value.keg_name}`,
          //           value: value.id
          //         }))
          //     },
          //     reduce({ value }) {
          //       return value // as ID DATA
          //     }
          //   },
          //   width: '15em',
          //   minWidth: '15em'
          // },
          {
            sortable: true,
            key: 'kegiatan_hm.keg_name',
            columnKey: 'kegiatan_hm_id',
            label: 'Kegiatan (*)',
            placeholder: 'Pilih Kegiatan',
            input: {
              type: 'select',
              options: [
                {
                  label: 'BONGKAR',
                  value: 1
                },
                {
                  label: 'MUAT',
                  value: 2
                }
              ],
              reduce({ value }) {
                return value
              }
            },
            searchModel: 'type_vehicle',
            search: {
              type: 'text'
            },
            width: '15em',
            minWidth: '15em'
          },
          // {
          //   key: 'mh_no_doc',
          //   label: 'No Dokumen (*)',
          //   sortable: true,
          //   placeholder: 'Nomor Dokumen',
          //   input: { type: 'input-noarea' },
          //   searchModel: 'mh_no_doc',
          //   search: {
          //     type: 'text'
          //   },
          //   width: '14em',
          //   minWidth: '14em'
          // },
          {
            key: 'mh_name_barang',
            label: 'Barang (*)',
            sortable: true,
            placeholder: 'Barang',
            input: { type: 'input-noarea' },
            searchModel: 'mh_name_barang',
            search: {
              type: 'text'
            },
            width: '14em',
            minWidth: '14em'
          },
          {
            key: 'mh_volume',
            label: 'Volume (*)',
            placeholder: 'Volume (Hanya Angka)',
            sortable: true,
            input: { type: 'number-cleave', append: '' },
            searchModel: 'mh_volume',
            search: {
              type: 'text'
            },
            width: '10em',
            minWidth: '10em'
          },
          {
            key: 'mh_satuan',
            label: 'Satuan (*)',
            sortable: true,
            placeholder: 'Satuan',
            input: {
              type: 'select',
              options: [
                {
                  label: 'M3',
                  value: 'M3'
                },
                {
                  label: 'TON',
                  value: 'TON'
                }
                // {
                //   label: 'KL',
                //   value: 'KL'
                // }
              ],
              reduce({ value }) {
                return value
              }
            },
            searchModel: 'mh_satuan',
            search: {
              type: 'text'
            },
            width: '12em',
            minWidth: '12em'
          },
          {
            key: 'mh_address',
            label: 'Tujuan/Asal (*)',
            sortable: true,
            placeholder: 'address',
            input: { type: 'input-noarea' },
            searchModel: 'mh_address',
            search: {
              type: 'text'
            },
            width: '12em',
            minWidth: '12em'
          },
          {
            key: 'mh_pemilik_brg',
            label: 'Segment (*)',
            sortable: true,
            placeholder: 'Segment',
            input: {
              type: 'select',
              options: [
                {
                  label: 'LMP',
                  value: 'LMP'
                },
                {
                  label: 'CEMENTING',
                  value: 'CEMENTING'
                },
                {
                  label: 'COIL TUBIN',
                  value: 'COIL TUBIN'
                },
                {
                  label: 'WIRELINE',
                  value: 'WIRELINE'
                },
                {
                  label: 'STIMULATION',
                  value: 'STIMULATION'
                },
                {
                  label: 'OTHERS',
                  value: 'OTHERS'
                }
              ],
              reduce({ value }) {
                return value
              }
            },
            searchModel: 'mh_pemilik_brg',
            search: {
              type: 'text'
            },
            width: '14em',
            minWidth: '14em'
          },
          {
            key: 'mh_rencana_keg',
            label: 'Rencana (*)',
            placeholder: 'Rencana',
            sortable: true,
            input: {
              type: 'time',
              format: 'D MMMM YYYY - HH:mm'
            },
            searchModel: 'mh_rencana_keg',
            search: {
              type: 'date'
            },
            width: '16em',
            minWidth: '16em'
          },
          // {
          //   key: 'mh_rencana_keg_start',
          //   label: 'Kegiatan Mulai',
          //   placeholder: 'Kegiatan Mulai',
          //   sortable: true,
          //   input: {
          //     type: 'time',
          //     format: 'D MMMM YYYY - hh:mm A'
          //   },
          //   searchModel: 'mh_rencana_keg_start',
          //   search: {
          //     type: 'date'
          //   },
          //   width: '17em',
          //   minWidth: '17em'
          // },
          // {
          //   key: 'mh_rencana_keg_end',
          //   label: 'Kegiatan Selesai',
          //   placeholder: 'Kegiatan Selesai',
          //   sortable: true,
          //   input: {
          //     type: 'time',
          //     format: 'D MMMM YYYY - hh:mm A'
          //   },
          //   searchModel: 'mh_rencana_keg_end',
          //   search: {
          //     type: 'date'
          //   },
          //   width: '17em',
          //   minWidth: '17em'
          // },

          {
            key: 'mh_signatured',
            label: 'Signatured',
            sortable: true,
            input: {
              type: 'input-noarea',
              disabled: true
            },
            search: {
              type: 'text'
            },
            width: '16em',
            minWidth: '16em'
          }
          // {
          //   key: 'created_at',
          //   label: 'Created AT',
          //   sortable: true,
          //   placeholder: 'Otomatis terisi',
          //   input: {
          //     type: 'time',
          //     format: 'D MMMM - hh:mm A',
          //     disabled: true
          //   },
          //   searchModel: 'created_at',
          //   search: {
          //     type: 'time'
          //   },
          //   width: '17em',
          //   minWidth: '17em'
          // },
          // {
          //   key: 'updated_at',
          //   label: 'Updated AT',
          //   sortable: true,
          //   placeholder: 'Otomatis terisi',
          //   input: {
          //     type: 'time',
          //     format: 'D MMMM - hh:mm A',
          //     disabled: true
          //   },
          //   searchModel: 'updated_at',
          //   search: {
          //     type: 'time'
          //   },
          //   width: '17em',
          //   minWidth: '17em'
          // }
        ]
      }
    },
    resolveFetchResponse(response) {
      return response.data.fhm_keg_manhs
    },
    resolveFetchUrl({ pagination }) {
      return {
        url: this.apiEndpoint,
        query: {
          page: pagination.currentPage - 1,
          size: pagination.selectedAmount,
          sort: 'DESC'
        }
      }
    }
  }
}
</script>
