var render = function () {
  var _vm$detailData, _vm$detailData2, _vm$detailData3, _vm$dateHumanReadAble, _vm$detailData4, _vm$detailData5, _vm$detailData5$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-login",
      "cancel-variant": "outline-secondary",
      "cancel-title": "Close",
      "centered": "",
      "size": "xl",
      "hide-footer": true,
      "title": _vm.title
    },
    model: {
      value: _vm.modalShow,
      callback: function callback($$v) {
        _vm.modalShow = $$v;
      },
      expression: "modalShow"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('small', [_vm._v("Segment " + _vm._s((_vm$detailData = _vm.detailData) === null || _vm$detailData === void 0 ? void 0 : _vm$detailData.mh_pemilik_brg))]), _c('h3', [_vm._v(" " + _vm._s((_vm$detailData2 = _vm.detailData) === null || _vm$detailData2 === void 0 ? void 0 : _vm$detailData2.kegiatan_hm.keg_name) + " " + _vm._s((_vm$detailData3 = _vm.detailData) === null || _vm$detailData3 === void 0 ? void 0 : _vm$detailData3.mh_name_barang) + " ")]), _c('div', [_vm._v(" Rencana Kegiatan: " + _vm._s((_vm$dateHumanReadAble = _vm.dateHumanReadAble((_vm$detailData4 = _vm.detailData) === null || _vm$detailData4 === void 0 ? void 0 : _vm$detailData4.mh_rencana_keg)) !== null && _vm$dateHumanReadAble !== void 0 ? _vm$dateHumanReadAble : '') + " "), _c('br'), _c('strong', [_vm._v("Signatured: " + _vm._s((_vm$detailData5 = _vm.detailData) === null || _vm$detailData5 === void 0 ? void 0 : (_vm$detailData5$user = _vm$detailData5.user) === null || _vm$detailData5$user === void 0 ? void 0 : _vm$detailData5$user.full_name))])])])]), _c('YoTable', {
    attrs: {
      "api-endpoint": _vm.uriSecond,
      "sub-title-tbl": "KEGIATAN JALUR SUNGAI HULU MIGAS",
      "fields": _vm.fields,
      "new-data-template": _vm.newDataTemplate,
      "resolve-fetch-response": _vm.resolveFetchResponse,
      "resolve-fetch-url": _vm.resolveFetchUrl,
      "bottom-label": this.$t('KeteranganTable') + '~' + this.$t('Form Land Vehicle') + '~',
      "forceDisableCrud": _vm.forceDisableCrud,
      "bottom-icon": "GitMergeIcon",
      "info-page-ability": "Manage ( All & SAL ) only",
      "placeholder-search": "Kegiatan Name"
    },
    on: {
      "render-component": _vm.renderComponent
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }